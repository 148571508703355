import { evenlyDistributedFilter, formatDate } from '../../../utilities/helperFunctions';

import { HistoryItemDate, HistoryItemType } from '../History.types';

export function calculateHistoryDifference(history: HistoryItemType[]) {
  const newest = new Date(history[0].datum).getTime();
  const oldest = new Date(history[history.length - 1].datum).getTime();
  const millisecondsInADay = 86400000;

  return Math.floor((newest - oldest) / millisecondsInADay);
}

export function calculateHistorySliderValue({
  difference,
  historyItemDate,
}: {
  difference: number;
  historyItemDate: HistoryItemDate;
}) {
  const date = new Date(historyItemDate);

  if (difference <= 730) {
    if (difference > 62) return formatDate(date, 'month yyyy');

    if (difference > 2) return formatDate(date, 'dd month yyyy');

    return formatDate(date, 'dd/mm/yyyy,hh:00');
  }

  return formatDate(date, 'yyyy');
}

export function mutateHistorySliderValues(sliderValues: string[]) {
  sliderValues = [...new Set(sliderValues)].reverse();
  sliderValues = evenlyDistributedFilter(sliderValues, 14);

  return sliderValues.map((label, index) => ({ label, value: index }));
}
