import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Divider } from '@mui/material';

import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { useUpdateAssignToProjectMutation } from 'src/pages/AssignToProject/AssignToProject.service';
import { jobApi } from 'src/pages/Job/Job.service';
import { BreadCrumbs } from '../../components/BreadCrumbs';
import { WaveTooltip } from '../../components/WaveTooltip';
import { JobsTable } from '../../features/JobsTable';
import { ActionBar } from '../../features/JobsTable/components/ActionBar';
import { Filters } from '../../features/JobsTable/components/Filters';
import { useJobsTable } from '../../features/JobsTable/utilities/hooks';
import { openWaveSnack } from '../../store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from '../../utilities/hooks';

export function AssignToProject() {
  const dispatch = useAppDispatch();
  const {
    areActiveFiltersVisible,
    columns,
    deadlines,
    getJobs,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleClickRowRadio,
    handleOpenConfirmationDialog,
    handleToggleSelectAllRows,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    loadingJobs,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobsTable();

  const navigate = useNavigate();
  const { jobId, jobType } = useRouteParams();

  const [updateAssignToProject, { isLoading: isUpdateAssignToProjectLoading }] =
    useUpdateAssignToProjectMutation();
  const { t } = useTranslation();

  function handleCancel() {
    navigate(`/jobs-job-${jobType}/${jobId}/job`);
  }

  async function handleConfirm() {
    updateAssignToProject({
      assignToJobId: selectedRows[0],
      assignToJobType: 'pro',
      jobId,
      jobType,
    })
      .unwrap()
      .then((message) => {
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );
        dispatch(jobFormApi.util.invalidateTags(['Actions', 'RelatedJobs']));
        dispatch(jobApi.util.invalidateTags(['Job']));
        navigate(`/jobs-job-${jobType}/${jobId}/job`);
      })
      .catch(() => {
        setSelectedRows([]);
      });
  }

  return (
    <>
      <BreadCrumbs />

      <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'flex-end', m: 1 }}>
        <Button color="warning" onClick={handleCancel}>
          {t('lib.cancel')}
        </Button>

        <WaveTooltip
          body={selectedRows.length === 0 ? 'Please select a project.' : ''}
          component={
            <LoadingButton
              disabled={!selectedRows.length}
              loading={isUpdateAssignToProjectLoading}
              onClick={handleConfirm}
              variant="contained"
            >
              {t('lib.confirm')}
            </LoadingButton>
          }
          placement="top"
          type="simple"
        />
      </Box>

      <Divider />

      <Container maxWidth={false} sx={{ py: 0.5 }}>
        <ActionBar
          areActiveFiltersVisible={areActiveFiltersVisible}
          onChangePageSearch={handleChangePageSearch}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
          transferSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          getJobs={getJobs}
          onSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        areActionsDisabled
        columns={columns}
        deadlines={deadlines}
        loadingJobs={loadingJobs}
        onSetPageHistory={setPageHistory}
        onSetSelectedRows={setSelectedRows}
        rows={rows}
        selectedRows={selectedRows}
        totalRowCount={totalRowCount}
        transferClickRowCheckbox={handleClickRowCheckbox}
        transferClickRowRadio={handleClickRowRadio}
        transferOpenConfirmationDialog={handleOpenConfirmationDialog}
        transferToggleSelectAllRows={handleToggleSelectAllRows}
      />
    </>
  );
}
