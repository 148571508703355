import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Dialog as MuiDialog, DialogActions, DialogContent, useTheme } from '@mui/material';

import { Button } from 'src/components/Button';
import { UploadButton } from 'src/components/RHF/UploadButton';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { EditableAvatar } from 'src/features/EditableAvatar';
import {
  useCreateAvatarMutation,
  useDeleteAvatarMutation,
} from 'src/features/EditableAvatar/EditableAvatar.service';
import { getUserDetails } from 'src/store/userSlice';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useAppSelector } from 'src/utilities/hooks';

type DialogProps = {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
};

export function Dialog({ isDialogOpen, onCloseDialog }: DialogProps) {
  const { t } = useTranslation();
  const [createAvatar] = useCreateAvatarMutation();
  const [deleteAvatar] = useDeleteAvatarMutation();
  const dispatch = useAppDispatch();
  const [shouldDeleteAvatar, setShouldDeleteAvatar] = useState(false);
  const userId = useAppSelector((state) => state.user.details.id);
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: { avatar: '' },
    mode: 'onChange',
  });
  const {
    palette: {
      error: { contrastText, main: error },
    },
  } = useTheme();

  const avatar = watch('avatar');

  function handleClickCancel() {
    onCloseDialog();
    reset();
    setShouldDeleteAvatar(false);
  }

  function handleClickDeleteIcon(isAvatarSaved: boolean) {
    if (isAvatarSaved) setShouldDeleteAvatar(true);

    reset();
  }

  async function onSubmit({ avatar }: { avatar: string }) {
    if (typeof avatar === 'object') {
      const formData = new FormData();

      formData.append('file', avatar);

      await createAvatar({ formData, id: userId })
        .unwrap()
        .then(async (message) => {
          await dispatch(getUserDetails());
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          onCloseDialog();
          reset();
          setShouldDeleteAvatar(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (shouldDeleteAvatar) {
      await deleteAvatar({ id: userId })
        .unwrap()
        .then(async (message) => {
          await dispatch(getUserDetails());
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          onCloseDialog();
          reset();
          setShouldDeleteAvatar(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    if (avatar) setShouldDeleteAvatar(false);
  }, [avatar]);

  return (
    <MuiDialog
      open={isDialogOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <WaveDialogTitle title={t('edit_avatar_title', 'Edit Avatar')} />

      <Box bgcolor="white" component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          alignItems="center"
          component={DialogContent}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <EditableAvatar
            backgroundColor={error}
            iconCode="user-profile-avatar-dialog-delete"
            iconColor={contrastText}
            isSubmitting={isSubmitting}
            onClick={handleClickDeleteIcon}
            shouldDeleteAvatar={shouldDeleteAvatar}
            {...(typeof avatar === 'object' && { newAvatar: URL.createObjectURL(avatar) })}
          />

          <UploadButton
            control={control}
            disabled={isSubmitting}
            fileType="image/*"
            label={t('edit_avatar_upload_button', 'Select image') as string}
            name="avatar"
          />
        </Box>

        <Box component={DialogActions} display="flex" gap={2} justifyContent="space-between">
          <Button
            color="warning"
            onClick={handleClickCancel}
            sx={{ bgcolor: `${isSubmitting ? 'warning.light' : ''}` }}
          >
            {isSubmitting ? 'lib.close' : 'lib.cancel'}
          </Button>

          <WaveTooltip
            component={
              <LoadingButton
                disabled={!isDirty && !shouldDeleteAvatar}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                <Trans i18nKey="lib.confirm">Confirm</Trans>
              </LoadingButton>
            }
            type="simple"
            {...(!isDirty && !shouldDeleteAvatar && { body: 'Nothing to update.' })}
          />
        </Box>
      </Box>
    </MuiDialog>
  );
}
