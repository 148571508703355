import { useEffect, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { usePreference } from '../../utilities/hooks';
import { GlobalStyles } from '@mui/material';
import { extendAugmentColor } from './utils';

const installationModules = import.meta.glob([
  '/src/installations/**/*.js',
  '/src/installations/**/*.png',
  '/src/installations/**/*.jpg',
]);

const BUTTON_STYLES = {
  styleOverrides: {
    root: { borderRadius: 10, textTransform: 'none' },
  },
  defaultProps: {
    disableElevation: true,
    size: 'medium',
    variant: 'contained',
  },
};

export let waveTheme = createTheme();
const extendedAugmentColor = extendAugmentColor(waveTheme.palette.augmentColor);

export function WaveTheme({ children }) {
  const [clientColors, setClientColors] = useState('');
  const [clientIDPreference] = usePreference('sys.mid', undefined);
  const [installationColors, setInstallationColors] = useState('');
  const [installationLogo, setInstallationLogo] = useState('');
  const [theme, setTheme] = useState(waveTheme);

  function createWaveTheme(colors) {
    return createTheme({
      installationLogo: installationLogo,
      clientColors: clientColors,
      components: {
        MuiButton: BUTTON_STYLES,
        MuiLoadingButton: BUTTON_STYLES,
      },
      palette: {
        adm: extendedAugmentColor({ color: { main: '#ed7860' } }),
        all: extendedAugmentColor({ color: { main: '#c3d600' } }),
        art: extendedAugmentColor({ color: { main: '#459def' } }),
        com: extendedAugmentColor({ color: { main: '#d53a68' } }),
        fax: extendedAugmentColor({ color: { main: '#1351c9' } }),
        ffu: extendedAugmentColor({ color: { main: '#8b23a6' } }),
        filler: extendedAugmentColor({ color: { main: '#ECEEF1' } }),
        info: extendedAugmentColor({ color: { main: '#808080' } }),
        mas: extendedAugmentColor({ color: { main: '#00b3b3' } }),
        mis: extendedAugmentColor({ color: { main: '#008b74' } }),
        oddRow: extendedAugmentColor({ color: { main: '#f7f7f7' } }),
        primary: { main: colors.primary },
        pro: extendedAugmentColor({ color: { main: '#f5a507' } }),
        rep: extendedAugmentColor({ color: { main: '#006ca8' } }),
        sec: extendedAugmentColor({ color: { main: '#8b6892' } }),
        secondary: { main: colors.secondary },
        spc: extendedAugmentColor({ color: { main: '#0087a5' } }),
        text: {
          primary: colors.primary,
        },
        tra: extendedAugmentColor({ color: { main: '#2fa148' } }),
      },
    });
  }

  function setCSSColorVariables(themeColors) {
    Object.keys(themeColors).forEach((colorName) => {
      const colorValue = themeColors[colorName];
      document.documentElement.style.setProperty(`--${colorName}`, colorValue);
    });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      (async () => {
        const coloursPath = `/src/installations/${import.meta.env.VITE_INSTALLATION}/colors.js`;
        const { colors: installationColorsResponse } = await installationModules[coloursPath]();

        installationColorsResponse.success = waveTheme.palette.success.main;
        installationColorsResponse.warning = waveTheme.palette.warning.main;
        installationColorsResponse.error = waveTheme.palette.error.main;
        installationColorsResponse.info = 'gray';

        setInstallationColors(installationColorsResponse);
        const logoPath = `/src/installations/${
          import.meta.env.VITE_INSTALLATION
        }/images/installationLogo.png`;
        const { default: installationLogoResponse } = await installationModules[logoPath]();
        setInstallationLogo(installationLogoResponse);
        const logoBackground = `/src/installations/${
          import.meta.env.VITE_INSTALLATION
        }/images/installationPublicBackground.jpg`;
        const { default: installationPublicBackground } = await installationModules[
          logoBackground
        ]();

        document.documentElement.style.setProperty(
          '--installationPublicBackground',
          `url(${installationPublicBackground})`,
        );
      })();
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && clientColors) {
      setCSSColorVariables(clientColors);
      setTheme((waveTheme = createWaveTheme(clientColors)));
    }
    return () => (isMounted = false);
  }, [clientColors]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && clientIDPreference.value) {
      (async () => {
        try {
          const clientColours = `/src/installations/${import.meta.env.VITE_INSTALLATION}/client_${
            clientIDPreference.value
          }/colors.js`;
          const response = await installationModules[clientColours]();
          setClientColors({
            ...response.colors,
          });
        } catch (error) {
          if (clientColors !== installationColors) {
            setClientColors(installationColors);
          }
        }
      })();
    }

    return () => (isMounted = false);
  }, [clientIDPreference.value]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && installationColors && installationLogo) {
      setCSSColorVariables(installationColors);

      setTheme(createWaveTheme(installationColors));
    }
    return () => (isMounted = false);
  }, [installationColors, installationLogo]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '.pswp__top-bar': {
            backgroundColor: waveTheme.palette.primary.main,
          },
          '.selection-area': {
            backgroundColor: '#4f90f22d',
            border: '1px solid #4f90f2',
            userSelect: 'none',
          },
        }}
      />
      {children}
    </ThemeProvider>
  );
}
