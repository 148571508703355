import { forwardRef, useEffect, useState } from 'react';

import { Icon } from '@mui/material';

import { CustomIcon } from './components/CustomIcon';

const customIcons = {
  'main-menu-additional-tabs-roadrunner': (
    <CustomIcon
      path="m555 215-6 1c-2 1-5 0-6-2l-3-1-9 1-12 3-34 8c-61 13-123 20-184 29-17 2-33-1-49-7-29-13-60-21-92-23-20-2-31-16-40-32v-4l3-6a42 42 0 0 1 2-3l2-1h1l2-1 1-4 2-1 1-2v-3a3792 3792 0 0 1 6-5c2 0 2-3 1-3l-2-1 2-1h4c2-1 1-3 0-4a552 552 0 0 0-27-8h-5l-8 2a37 37 0 0 0-17 7l-7 5c-7 5-13 11-18 19-8 12-18 18-32 20-11 2-22 5-30 14-2 2-1 3 1 3l15-2a1242 1242 0 0 1 37 0l30 22c8 5 11 12 13 21l1 7c4 31 22 49 50 61 10 4 18 10 28 16 2 1 2 3 1 5l-15 15-39 33c-8 7-17 9-28 7l-10-1h-8l-5 1v2h3l5 2c1 1 0 2-2 3l-4 2-8 1-6 4-1 4h1c2-2 5-2 8-3l8-1 24-4c13-3 24-2 36 3 5 2 11 3 17 3l8 3 8 2 1-1a139 139 0 0 1-3-4l-2-1-2-2-2-1-4-3c-6-7-15-8-24-8-2 1-4 0-5-1l3-3 34-25 33-23 3-3c9-5 40 2 47 10l16 20 18 17 14 18c4 7 4 7 14 7l5 1 7 3c3 1 5 1 8-1v-1l-1-1a1185 1185 0 0 0-11-4l-28-27c-1-1-2-4 0-6l8-12 5-7 6-5 7 1 1-1v-1l-1-1-1-1-4-1h-7c-13 0-17 11-22 21 0 2-3 3-5 2-8-4-14-12-30-37-2-2-1-4 1-6 9-7 18-15 28-20 10-6 22-8 33-12 3 0 3-2 1-4l-1-1v-6c13-14 32-13 49-15 19-1 36-6 54-12l23-6 62-11 8-1 36-6c3-1 9-3 9-7l-1-3-2-2h2l2-1 11-4c2-1 5-2 6-4 1-7-10-4-13-4z"
      viewBox="0 0 567.9 567.9"
    />
  ),
};

const icons = {
  account_circle: ['account-circle', 'job-approvals-action-dialog-account-circle'],
  account_tree: ['job-form-action-bar-related-jobs'],
  add: [
    'add',
    'job-create-copy-newjobs-buttons', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-create-copy-new-job',
    'job-phrase-action-bar-add-content',
    'jobs-action-bar-new-job',
    'sub-jobs-action-bar-new-job',
  ],
  add_box: [
    'add-box',
    'job-history-approval-user-added',
    'job-phrase-action-templates-list-add',
    'sub-jobs-action-bar-new-job-sub-menu',
  ],
  add_circle: [
    'job-files-action-bar-compare-other-files',
    'job-files-compare-other-files', // TODO: Remove after refactoring job form to use RHF
  ],
  add_task: [
    'job-form-action-bar-actions-new-webcenter-project',
    'job-form-actions-new-webcenter-project', // TODO: Remove after refactoring job form to use RHF
  ],
  archive: ['main-menu-archive', 'job-archive'],
  arrow_back: ['step-change-dialog-add-revisor-back', 'not-found-go-back', 'arrow-back'],
  arrow_drop_down: ['arrow-drop-down'],
  arrow_right: ['arrow-right'],
  arrow_right_alt: ['arrow-right-alt', 'step-change-dialog-title-arrow'],
  assignment_turned_in: [
    'job-header-task-confirmed',
    'job-header-tasks-confirmed', // TODO: Remove after refactoring job form to use RHF
    'job-tasks-task',
    'job-tasks-confirmed',
  ],
  assignment: [
    'job-header-task-active',
    'job-header-tasks-active', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-task',
    'job-tasks-active',
  ],
  attach_file: ['job-form-actions-send-deep-link', 'file'],
  bar_chart: ['main-menu-rep'],
  bookmark: ['bookmark'],
  bookmark_add: [
    'job-form-action-bar-actions-add-bookmark',
    'job-form-actions-add-bookmark', // TODO: Remove after refactoring job form to use RHF
  ],
  bookmark_remove: [
    'job-form-action-bar-actions-remove-bookmark',
    'job-form-actions-remove-bookmark', // TODO: Remove after refactoring job form to use RHF
  ],
  brightness_1: ['input-field-empty'],
  brightness_low: [
    'job-create-copy', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-create-copy-copy-to-job-type',
    'job-form-action-bar-create-copy-new-job-job-type',
    'job-form-action-bar-create-copy-copy-in-project-job-type',
  ],
  business: ['business'],
  cancel: [
    'cancel',
    'input-field-invalid',
    'job-flags-cancel',
    'job-form-action-bar-cancel',
    'job-form-action-bar-flags-flag-button-cancel',
    'job-form-action-bar-flags-flag-dialog-cancel',
    'job-header-flag-cancel',
    'job-header-flags-cancel', // TODO: Remove after refactoring job form to use RHF
    'job-history-approval-user-deleted',
    'user-profile-action-bar-cancel',
  ],
  check: ['check'],
  check_box: [
    'job-files-action-bar-compare-selected-files',
    'job-files-compare-selected-files', // TODO: Remove after refactoring job form to use RHF
    'check-box',
  ],
  check_box_outline_blank: [
    'job-history-approval-approve',
    'job-history-approval-amendment',
    'job-history-approval-conditional',
    'job-history-flag',
  ],
  check_circle: ['check-circle', 'input-field-valid'],
  check_circle_outline: [
    'job-flags-revive',
    'job-form-action-bar-flags-flag-button-revive',
    'job-form-action-bar-flags-flag-dialog-revive',
    'job-history-checklist-ok',
    'job-history-checklist-reject',
  ],
  cloud_download: [
    'general-files-action-bar-files-download',
    'job-files-action-bar-actions-download',
    'job-files-action-bar-download',
    'job-files-action-bar-actions-download-with-annotations',
    'job-files-download', // TODO: Remove after refactoring job form to use RHF
    'job-files-download-with-annotations', // TODO: Remove after refactoring job form to use RHF
    'cloud-download',
  ],
  cloud_upload: ['cloud-upload', 'general-files-action-bar-file-upload', 'job-files-upload-file'],
  close: ['close'],
  comment: ['job-history-action-bar-comment'],
  compare: [
    'job-approvals-file-actions-compare',
    'job-files-action-bar-compare-files',
    'job-files-compare-files', // TODO: Remove after refactoring job form to use RHF
    'job-phrase-actions-compare',
  ],
  content_copy: ['copy', 'translate-copy'],
  control_camera: ['job-form-actions-import-reference-job'],
  create_new_folder: ['general-files-folder-create', 'create-new-folder'],
  dashboard: ['main-menu-hom'],
  date_range: [
    'date-range',
    'job-form-action-bar-actions-get-project-timings',
    'job-form-actions-get-project-timings', // TODO: Remove after refactoring job form to use RHF
  ],
  description: [
    'job-form-action-bar-steps-additional-actions-file',
    'job-steps-file', // TODO: Remove after refactoring job form to use RHF
    'main-menu-cms-menu',
  ],
  delete: [
    'delete',
    'general-files-action-bar-files-delete',
    'general-files-file-delete',
    'general-files-folder-delete',
    'job-approvals-action-dialog-delete',
    'user-profile-avatar-dialog-delete',
  ],
  disabled_by_default: ['disabled-by-default'],
  difference: ['job-form-clipboard'],
  done: ['done'],
  download: ['download'],
  edit: [
    'edit',
    'general-files-folder-rename',
    'job-history-job-edit',
    'job-phrase-action-bar-edit-content',
    'job-phrase-category-language-needed',
    'jobs-action-bar-bulk',
    'user-profile-avatar-edit',
  ],
  email: [
    'email',
    'job-apl-email',
    'job-files-action-bar-actions-send-link-email',
    'job-files-send-link-email', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-actions-email',
    'job-form-actions-email', // TODO: Remove after refactoring job form to use RHF
    'job-history-email',
    'job-phrase-actions-email',
  ],
  exit_to_app: ['exit-app'],
  expand_less: [
    'expand-less',
    'general-files-folder-expand-less',
    'job-files-action-bar-expand-less',
    'job-history-action-bar-expand-less',
  ],
  expand_more: [
    'expand-more',
    'expand-all',
    'general-files-folder-expand-more',
    'job-history-action-bar-expand-more',
    'job-history-item-expand-more',
    'job-files-action-bar-expand-more',
  ],
  folder: ['general-files-folder', 'main-menu-job-pro'],
  folder_open: [
    'compare-files-folder-open',
    'job-files-dalim',
    'job-files-pdf',
    'job-files-doc',
    'job-files-wec',
    'job-files-dms',
    'job-files-cloudflow',
    'job-files-gv',
    'job-files-pixelboxx',
  ],
  folder_copy: ['job-form-action-bar-create-copy-copy-in-project'],
  format_color_fill: ['color-dropdown'],
  format_quote: ['job-history-question-answered', 'job-history-phrase-edit'],
  forward: ['job-approvals-actions-forward'],
  file_copy: [
    'job-copy',
    'job-create-copy-copy-buttons', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-create-copy-copy-to',
  ],
  filter_list: [
    'dashboard-action-bar-filters',
    'filter-list',
    'jobs-action-bar-filters',
    'sub-jobs-action-bar-filters',
  ],
  first_page: ['first-page'],
  flag: ['job-actions-flags', 'job-history-flag-activate', 'job-history-flag-confirm'],
  help: ['help'],
  history: ['history'],
  import_export: [
    'dashboard-action-bar-export',
    'job-phrase-actions-export',
    'jobs-action-bar-export',
    'sub-jobs-action-bar-export',
  ],
  indeterminate_check_box: ['job-approvals-revisor-status-unactioned'],
  info: ['input-field-information', 'info'],
  insert_drive_file: ['job-history-job-reuse'],
  invert_colors: ['job-flags-color-approve'],
  keyboard_arrow_right: ['keyboard-arrow-right'],
  keyboard_arrow_left: ['keyboard-arrow-left'],
  keyboard_arrow_up: [
    'job-phrase-action-bar-expand-all',
    'jobs-action-bar-keyboard-arrow-up',
    'jobs-row-keyboard-arrow-up',
    'keyboard-arrow-up',
  ],
  keyboard_arrow_down: [
    'job-phrase-action-bar-collapse-all',
    'jobs-action-bar-keyboard-arrow-down',
    'jobs-row-keyboard-arrow-down',
    'keyboard-arrow-down',
  ],
  language: ['job-form-language', 'job-phrase-action-bar-translations'],
  last_page: ['last-page'],
  library_books: ['library-books'],
  linear_scale: ['main-menu-job', 'main-menu-additional-tabs'],
  link_off: ['job-phrase-actions-remove'],
  lock: ['user-profile-action-bar-change-password'],
  mode_comment: ['job-history-comment'],
  more: [
    'job-form-action-bar-steps-additional-actions-more',
    'job-steps-more', // TODO: Remove after refactoring job form to use RHF
  ],
  more_vert: [
    'dashboard-action-bar-actions',
    'jobs-action-bar-actions',
    'job-actions-formActions', // TODO: Remove after refactoring job form to use RHF
    'job-approvals-action-bar-more-vert',
    'job-files-action-bar-actions',
    'job-form-action-bar-actions',
    'job-phrase-action-bar-actions',
    'more-vert', // TODO: Remove after refactoring job form to use RHF
    'sub-jobs-action-bar-actions',
  ],
  navigate_next: ['job-form-action-bar-steps-additional-actions-next', 'navigate-next'],
  notifications: ['notifications'],
  open_in_new: [
    'job-form-action-bar-actions-assign-to-project',
    'job-form-action-bar-actions-reassign-project',
    'job-form-actions-assign-to-project', // TODO: Remove after refactoring job form to use RHF
    'job-form-actions-reassign-project', // TODO: Remove after refactoring job form to use RHF
  ],
  open_with: ['job-approvals-actions-expand', 'job-files-open-viewer'],
  pageview: ['job-form-actions-webcenter-view'],
  pause_circle_outline: [
    'job-flags-onhold',
    'job-form-action-bar-flags-flag-button-onhold',
    'job-form-action-bar-flags-flag-dialog-onhold',
    'job-header-flag-onhold',
    'job-header-flags-onhold', // TODO: Remove after refactoring job form to use RHF
  ],
  people: [
    'group',
    'job-form-steps-dialog-email-notifications-group',
    'job-form-steps-dialog-revisor-group',
  ],
  perm_media: ['job-form-actions-build-pdf'],
  person_add: ['job-approvals-action-bar-add'],
  picture_as_pdf: ['job-flags-pdf-approve'],
  play_circle_outline: [
    'job-flags-resume',
    'job-form-action-bar-flags-flag-button-resume',
    'job-form-action-bar-flags-flag-dialog-resume',
  ],
  post_add: ['job-history-file-upload', 'job-history-file-delete'],
  preview: ['jobs-view-search-preview'],
  print: [
    'job-print',
    'job-form-action-bar-actions-print-job',
    'job-form-actions-print-job', // TODO: Remove after refactoring job form to use RHF
    'job-phrase-actions-print',
  ],
  queue: [
    'job-actions-createCopy', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-create-copy',
  ],
  question_answer: ['job-form-actions-create-questions'],
  refresh: [
    'job-history-status',
    'job-history-approval-backtogroup',
    'job-history-webcenter-comment',
  ],
  remove: ['remove'],
  repeat: [
    'job-actions-stepChange', // TODO: Remove after refactoring job form to use RHF
    'job-form-action-bar-steps',
  ],
  search: [
    'dashboard-action-bar-saved-searches',
    'jobs-action-bar-saved-searches',
    'main-menu-job-ser',
    'sub-jobs-action-bar-saved-searches',
    'search',
  ],
  send: ['send'],
  select_all: [
    'job-files-action-bar-select-all',
    'job-phrase-action-bar-select-all',
    'select-all', // TODO: Remove after refactoring job form to use RHF
  ],
  settings: ['settings'],
  save: [
    'job-form-action-bar-save',
    'job-phrase-action-templates-list-save',
    'save',
    'user-profile-action-bar-save',
  ],
  shopping_basket: ['shopping-basket'],
  shopping_cart: [
    'job-files-action-bar-add-to-cart',
    'job-files-add-to-cart', // TODO: Remove after refactoring job form to use RHF
  ],
  start: ['nav-bar-menu'],
  subscriptions: ['job-form-actions-add-subscriptions'],
  tab_unselected: [
    'job-files-action-bar-tab-unselected',
    'job-phrase-action-bar-deselect-all',
    'tab-unselected', // TODO: Remove after refactoring job form to use RHF
  ],
  thumb_down: [
    'job-approvals-action-dialog-amendment',
    'job-approvals-actions-amendment',
    'job-approvals-revisor-status-amendment',
    'job-approvals-revisor-summary-table-amendment',
    'job-form-action-bar-task-dialog-flag-amendment',
    'job-form-action-bar-task-flag-amendment',
    'job-start-apl-amendment',
    'job-tasks-flag-amendment', // TODO: Remove after refactoring job form to use RHF
  ],
  thumb_up: [
    'approval',
    'job-approvals-action-dialog-approval',
    'job-approvals-actions-approval',
    'job-approvals-revisor-status-approval',
    'job-approvals-revisor-summary-table-approval',
    'job-form-action-bar-task-dialog-flag-approve',
    'job-form-action-bar-task-flag-approve',
    'job-phrase-category-language-approved',
    'job-start-apl-approval',
    'job-tasks-flag-approve', // TODO: Remove after refactoring job form to use RHF
  ],
  thumbs_up_down: [
    'conditional',
    'job-approvals-action-dialog-conditional',
    'job-approvals-actions-conditional',
    'job-approvals-revisor-status-conditional',
    'job-approvals-revisor-summary-table-conditional',
    'job-form-action-bar-task-dialog-flag-conditional',
    'job-form-action-bar-task-flag-conditional',
    'job-phrase-category-language-pending',
    'job-start-apl-conditional',
    'job-tasks-flag-conditional', // TODO: Remove after refactoring job form to use RHF
  ],
  tune: ['job-phrase-search-results-options'],
  view_comfy: ['view-compact'],
  view_list: [
    'dashboard-action-bar-columns',
    'jobs-action-bar-columns',
    'sub-jobs-action-bar-columns',
    'view-list',
  ],
  view_module: ['jobs-action-bar-list-view-options', 'view-module'],
  visibility: [
    'dashboard-action-bar-saved-views',
    'jobs-action-bar-saved-views',
    'sub-jobs-action-bar-saved-views',
    'visibility',
  ],
  visibility_off: ['visibility_off'],
  zoom_out_map: ['files_zoom_out_map'],
  photo_library: ['photo-library'],
};

function WaveIcon(props, ref) {
  let { code } = props;

  const [icon, setIcon] = useState('');

  if (code.includes('main-menu')) {
    // If only single job type is used for client then obtain job icon
    const codes = ['main-menu-job-pro', 'main-menu-job-ser'];
    if (code.includes('main-menu-job-') && !codes.includes(code)) {
      code = 'main-menu-job';
    }
    // If only single archive job type is used for client then obtain archive icon
    if (code.includes('main-menu-arc-')) {
      code = 'main-menu-archive';
    }
  } else if (code.includes('job-tasks')) {
    // If any task parent button
    if (code.includes('job-tasks-task-')) {
      code = 'job-tasks-task';
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (customIcons[code]) setIcon(customIcons[code]);
      else {
        Object.entries(icons).some((icon) => {
          if (icon[1].includes(code)) {
            setIcon(icon[0]);
            return true;
          } else return false;
        });
      }
    }

    return () => (isMounted = false);
  }, [code]);

  return (
    <Icon {...props} ref={ref}>
      {icon}
    </Icon>
  );
}

export const WaveIconWithRef = forwardRef(WaveIcon);
