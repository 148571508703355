import { useIsSubJobs } from '../../features/JobsTable/utilities/hooks';
import { BasicAge } from './useRouteParams';

export function usePreferencePrefix(parameters?: { forcedAge?: BasicAge; forcedJobType?: string }) {
  const { age, isSubJobs, jobId, jobType, tab } = useIsSubJobs({
    forcedAge: parameters?.forcedAge,
    forcedJobType: parameters?.forcedJobType,
  });

  return {
    age,
    isSubJobs,
    jobId,
    jobType,
    preferencePrefix: isSubJobs
      ? `${age}-${jobType}-sub`
      : `${jobType === 'dash' ? 'hom' : age}-${jobType}`,
    tab,
  };
}
