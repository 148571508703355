import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import dayjs from 'dayjs';

import { Box, Grid, Radio, TableCell, TableRow, Typography, useTheme } from '@mui/material';

import { DateCell } from 'src/features/JobsTable/components/DateCell';
import { StyledLink } from 'src/features/JobsTable/components/Row/styled';
import { useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { isString } from 'src/utilities/hooks/usePreference';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';
import { logout } from '../../../../store/userSlice';
import { openWaveSnack } from '../../../../store/waveSnackSlice';
import { API } from '../../../../utilities/api';
import {
  useAppDispatch,
  useAppSelector,
  usePreference,
  usePreferencePrefix,
} from '../../../../utilities/hooks';
import { FieldMapper } from '../../../FieldMapper';
import { CreateCopyButton } from '../../../JobForm/JobForm.service';
import { convertPagePreferenceToPage } from '../../utilities/helperFunctions';
import {
  ClickRowCheckboxParameters,
  Columns,
  Deadline,
  Deadlines,
  IdsOfExpandedSubJobs,
  jobIdsWithSubJobs,
  OpenConfirmationDialogParameters,
  Rows,
  RowTS,
  SelectedRows,
  TotalRowCount,
} from '../../utilities/hooks/useJobsTable';
import { APLCell } from '../APLCell';
import { ButtonCell } from '../ButtonCell';
import { CopyJobDialog } from '../CopyJobDialog';
import { FlagCell } from '../FlagCell';
import { PreviewCell } from '../PreviewCell';
import { StatusCell } from '../StatusCell';
import { SubTable } from '../SubTable';
import { TasksCell } from '../TasksCell';
import { TextCell } from '../TextCell';

type RowProps = {
  areActionsDisabled: boolean;
  columns: Columns;
  deadline?: Deadline;
  idsOfExpandedSubJobs?: IdsOfExpandedSubJobs;
  isDense: boolean;
  isSubRow?: boolean;
  jobIdsWithSubJobs?: jobIdsWithSubJobs;
  onClickRowCheckbox: ({ e, rowId }: ClickRowCheckboxParameters) => void;
  onClickRowRadio: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onOpenConfirmationDialog: ({ jobId, jobType }: OpenConfirmationDialogParameters) => void;
  row: RowTS;
  rowId: number;
  selectedRows: SelectedRows;
  setIdsOfExpandedSubJobs?: Dispatch<SetStateAction<IdsOfExpandedSubJobs>>;
  totalRowCount: TotalRowCount;
};

export function Row({
  areActionsDisabled = false,
  columns,
  deadline = {},
  idsOfExpandedSubJobs,
  isDense = true,
  isSubRow = false,
  jobIdsWithSubJobs = [],
  onClickRowCheckbox,
  onClickRowRadio,
  onOpenConfirmationDialog,
  row,
  rowId,
  selectedRows = [],
  setIdsOfExpandedSubJobs,
  totalRowCount,
}: RowProps) {
  const { age, isSubJobs, jobType, preferencePrefix, tab } = usePreferencePrefix({
    forcedAge: row.webstatus === 200 ? 'arc' : 'job',
    forcedJobType: 'pro',
  });
  const isAgeBasic = age === 'arc' || age === 'job';
  const [languageCodePreference] = usePreference('sys.lang', 'uk');
  const languageCode = isString(languageCodePreference.value) ? languageCodePreference.value : 'uk';
  const [areSubJobsLoading, setAreSubJobsLoading] = useState(true);
  const [copyToJobOptions, setCopyToJobOptions] = useState<CreateCopyButton[]>([]);
  const deleteRights = useAppSelector((state) => state.user.rights.delete);
  const dispatch = useAppDispatch();
  const [isCopyJobConfirmationDialogOpen, setIsCopyJobConfirmationDialogOpen] = useState(false);
  const navigate = useNavigate();
  const {
    palette: {
      error: { main: error },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filler,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      oddRow: { main: oddRow },
      warning: { main: warning },
    },
  } = useTheme();
  const [pagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');
  const [rowsPerPagePreference] = usePreference(`${preferencePrefix}.rowsPerPage`, '25');
  const [subColumns, setSubColumns] = useState<Columns>([]);
  const [subDeadlines, setSubDeadlines] = useState({});
  const [subRows, setSubRows] = useState<Rows>([]);
  const rowJobType = row.src;
  const hasInsertRights = useAppSelector((state) =>
    state.user.rights.insert.some((right) => right === `job-${rowJobType}`),
  );

  const { data: workflow } = useGetWorkflowQuery(
    {
      age: age as BasicAge,
      jobType: rowJobType,
    },
    { skip: !isAgeBasic },
  );

  const cellPaddingY = isDense ? 0 : 0.5;
  const areCopyToJobOptionsLoading = !copyToJobOptions.length;
  const isDeleteVisible =
    !!workflow?.[row.webstatus]?.flags.canDelete && deleteRights.includes(`job-${rowJobType}`);
  const isTabAssignToProject = tab === 'assign';
  const isRowExpanded = idsOfExpandedSubJobs?.includes(row.jobid);
  const deadlineValue = Object.values(deadline)[0];
  const deadlineStyle = deadlineValue
    ? { borderLeft: 10, color: deadlineValue === 'late' ? error : warning, pl: 1, pr: 2 }
    : {};
  const rowsPerPage = parseInt(rowsPerPagePreference.value as string);
  const page = convertPagePreferenceToPage({
    pagePreferenceValue: pagePreference.value,
    rowsPerPage,
    totalRowCount,
  });
  const link = `/jobs-${age}-${rowJobType}/${row.jobid}`;

  function handleClickCheckbox(e: ChangeEvent<HTMLInputElement>) {
    onClickRowCheckbox({ e, rowId: row.jobid });
  }

  function handleClickExpandCollapse(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    if (!setIdsOfExpandedSubJobs || !idsOfExpandedSubJobs) return;

    if (!isRowExpanded) {
      setIdsOfExpandedSubJobs([...idsOfExpandedSubJobs, row.jobid]);
    } else {
      setIdsOfExpandedSubJobs(idsOfExpandedSubJobs.filter((jobId) => jobId !== row.jobid));
    }
  }

  function handleClickRow(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    navigate(`/jobs-${age}-${rowJobType}/${row.jobid}`);
  }

  function handleDeleteJob(e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    onOpenConfirmationDialog({ jobId: row.jobid, jobType: rowJobType });
  }

  async function handleOpenCopyJobDialog(
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) {
    e.preventDefault();
    e.stopPropagation();
    setIsCopyJobConfirmationDialogOpen(true);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (isCopyJobConfirmationDialogOpen && areCopyToJobOptionsLoading) {
      (async (cancelToken) => {
        await API.get('/jobs/actions/new-copy', {
          cancelToken: cancelToken,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onError: (error) => {
            dispatch(
              openWaveSnack({
                message: error.message,
                type: 'error',
              }),
            );
          },
          onSuccess: setCopyToJobOptions,
          onUnauthorized: (forcedLogOutMessage: string) => {
            dispatch(logout(forcedLogOutMessage));
          },
          params: {
            age,
            jobid: row.jobid,
            src: rowJobType,
          },
        });
      })(source.token);
    }

    return () => source.cancel();
  }, [isCopyJobConfirmationDialogOpen]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (isRowExpanded && !subRows.length) {
      (async (cancelToken) => {
        await API.get(`jobs/${row.jobid}/subjobs`, {
          cancelToken: cancelToken,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onError: (error) => {
            dispatch(
              openWaveSnack({
                message: error.message,
                type: 'error',
              }),
            );
          },
          onSuccess: ({
            columns,
            deadlines,
            rows,
          }: {
            columns: Columns;
            deadlines: Deadlines;
            rows: Rows;
          }) => {
            setSubColumns(columns);
            setSubDeadlines(deadlines);
            setSubRows(rows);
          },
          onUnauthorized: (forcedLogOutMessage: string) => {
            dispatch(logout(forcedLogOutMessage));
          },
          params: {
            age,
            full: true,
            src: rowJobType,
          },
        });
        setAreSubJobsLoading(false);
      })(source.token);
    }

    return () => source.cancel();
  }, [isRowExpanded]);

  return (
    <>
      <Box
        bgcolor={rowId % 2 !== 0 ? oddRow : 'white'}
        component={TableRow}
        hover
        onClick={handleClickRow}
        sx={{ cursor: 'pointer' }}
      >
        <Box bgcolor={filler.main} component={TableCell} {...deadlineStyle}>
          <StyledLink href={link}>
            <Typography color={filler.contrastText} variant="body2">
              {rowsPerPage * page + (rowId + 1)}
            </Typography>
          </StyledLink>
        </Box>

        {jobIdsWithSubJobs.length ? (
          <>
            {jobIdsWithSubJobs.includes(row.jobid) ? (
              <ButtonCell
                code={isRowExpanded ? 'jobs-row-keyboard-arrow-up' : 'jobs-row-keyboard-arrow-down'}
                onClick={handleClickExpandCollapse}
                paddingY={cellPaddingY}
              />
            ) : (
              <TableCell />
            )}
          </>
        ) : null}

        <TableCell sx={{ paddingY: cellPaddingY, ...(isSubRow && { bgcolor: filler.main }) }}>
          <Grid alignItems="center" container wrap="nowrap">
            {jobType !== 'dash' && !isSubRow && !isSubJobs && !isTabAssignToProject && (
              <Grid item>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <FieldMapper
                    transferChangeField={handleClickCheckbox}
                    type="boolean"
                    value={selectedRows.indexOf(row.jobid) !== -1}
                  />
                }
              </Grid>
            )}

            {isTabAssignToProject && (
              <Grid item>
                <Radio
                  checked={selectedRows[0] === row.jobid}
                  onClick={onClickRowRadio}
                  value={row.jobid}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>

        {!isSubRow && jobType !== 'dash' && !areActionsDisabled && (
          <>
            {hasInsertRights && (
              <ButtonCell
                code="job-copy"
                colSpan={isDeleteVisible ? 1 : 2}
                onClick={handleOpenCopyJobDialog}
                paddingY={cellPaddingY}
                waveIconClassName="primary-txt"
              />
            )}

            {isDeleteVisible && (
              <ButtonCell
                code="delete"
                onClick={handleDeleteJob}
                paddingY={cellPaddingY}
                waveIconClassName="error-txt"
              />
            )}
          </>
        )}

        {columns.map(({ field: alias }) => {
          const value = row[alias];
          const isDate = dayjs(value, 'YYYY-MM-DD HH:mm:ss', true).isValid();

          if (alias === 'apl')
            return (
              <APLCell
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
                rowJobId={row.jobid}
                rowJobType={rowJobType}
                rowWebStatus={row.webstatus}
                value={row[alias]}
              />
            );
          else if (alias === 'flags')
            return (
              <FlagCell
                flags={Object.values(row[alias])}
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
              />
            );
          else if (alias === 'preview')
            return (
              <PreviewCell
                isDense={isDense}
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
                preview={row.preview}
                rowJobID={row.jobid}
              />
            );
          else if (alias === 'tasks')
            return (
              <TasksCell
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
                tasks={Object.values(row[alias])}
              />
            );
          else if (alias === 'webstatus')
            return (
              <StatusCell
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
                rowJobType={rowJobType}
                value={row[alias]}
              />
            );
          else if (isDate)
            return (
              <DateCell
                key={alias}
                languageCode={languageCode}
                paddingY={cellPaddingY}
                value={row[alias]}
              />
            );
          else
            return (
              <TextCell
                deadlineValue={deadline[alias]}
                isSubRow={isSubRow}
                key={alias}
                link={link}
                paddingY={cellPaddingY}
                value={row[alias]}
              />
            );
        })}
      </Box>

      {isRowExpanded && (
        <SubTable
          areJobsLoading={areSubJobsLoading}
          columnsLength={columns.length}
          deadlines={subDeadlines}
          open={isRowExpanded}
          rowJobType={rowJobType}
          rows={subRows}
          subColumns={subColumns}
        />
      )}

      {isCopyJobConfirmationDialogOpen && (
        <CopyJobDialog
          areOptionsLoading={areCopyToJobOptionsLoading}
          copyToJobOptions={copyToJobOptions}
          jobId={row.jobid}
          jobType={rowJobType}
          onClose={() => setIsCopyJobConfirmationDialogOpen(false)}
        />
      )}
    </>
  );
}
