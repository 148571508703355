import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useColorDialog } from 'src/features/JobForm/components/ColorDialog/useColorDialog';
import { useGetBooksQuery } from 'src/features/JobForm/JobForm.service';
import { LoadingHeaderAndBody } from 'src/features/JobsTable/components/LoadingHeaderAndBody';
import { PaginationActions } from 'src/features/JobsTable/components/PaginationActions';
import { convertPagePreferenceToPage } from 'src/features/JobsTable/utilities/helperFunctions';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreference } from 'src/utilities/hooks';

type ColorDialogProps = {
  isColorDialogOpen: boolean;
  onHandleChange: (event: any) => void;
  onSetIsColorDialogOpen: (arg: boolean) => void;
};

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export function ColorDialog({
  isColorDialogOpen,
  onHandleChange,
  onSetIsColorDialogOpen,
}: ColorDialogProps) {
  const {
    columns,
    debouncedValue,
    isFetchingGetColor,
    rows,
    searchColor,
    setSearchColor,
    totalRowCount,
  } = useColorDialog();
  const { t } = useTranslation();

  const { data: books } = useGetBooksQuery();
  const [bookPreference, setBookPreference] = usePreference('colorbook.book', '');
  const [pagePreference, setPagePreference] = usePreference('colorbook.listPage', '0');
  const [rowsPerPagePreference, setRowsPerPagePreference] = usePreference(
    'colorbook.rowsPerPage',
    '25',
  );
  const [selectedRow, setSelectedRow] = useState('');
  const [searchPreference, setSearchPreference] = usePreference('colorbook.search', '');
  const [shouldTriggerRequest, setShouldTriggerRequest] = useState(true);

  const rowsPerPage = rowsPerPagePreference.value
    ? parseInt(rowsPerPagePreference.value as string)
    : 25;

  const page = convertPagePreferenceToPage({
    pagePreferenceValue: pagePreference.value,
    rowsPerPage,
    totalRowCount,
  });

  function handleChangeBook(event: ChangeEvent<HTMLInputElement>, value: string | null) {
    setBookPreference({ ...bookPreference, value: value ?? '' });
    setPagePreference({ ...pagePreference, value: '0' });
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    setPagePreference({ ...pagePreference, value: page.toString() });
  }

  function handleChangeRowPerPage(e: ChangeEvent<HTMLInputElement>) {
    const rowsPerPage = e.target.value;

    setRowsPerPagePreference({
      ...rowsPerPagePreference,
      value: rowsPerPage.toString(),
    });
    setPagePreference({ ...pagePreference, value: '0' });
  }

  function handleClickRadio(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();
    setSelectedRow((e.target as HTMLButtonElement).value);
  }

  function handleCloseDialog() {
    onSetIsColorDialogOpen(false);
  }

  function handleConfirm() {
    onHandleChange(selectedRow);
    handleCloseDialog();
  }

  function handleSearchColor(event: ChangeEvent<HTMLInputElement>) {
    setShouldTriggerRequest(false);
    setSearchColor(event.target.value);
  }

  function labelDisplayedRows() {
    return null;
  }

  useEffect(() => {
    if (shouldTriggerRequest) {
      setPagePreference({ ...pagePreference, value: '0' });
      setSearchPreference({ ...searchPreference, value: searchColor });
    } else {
      setShouldTriggerRequest(true);
    }
  }, [debouncedValue, searchColor]);

  return (
    <Dialog fullWidth maxWidth={'md'} onClose={handleCloseDialog} open={isColorDialogOpen}>
      <WaveDialogTitle title={t('add_color_template', 'Add Color Template')} />

      <DialogContent>
        <Grid2 container marginBottom={1} marginTop={1} spacing={1}>
          <Grid2 xs>
            <Autocomplete
              disablePortal
              onChange={(event, value) =>
                handleChangeBook(event as ChangeEvent<HTMLInputElement>, value)
              }
              options={books || []}
              renderInput={(params) => <TextField {...params} label="Books" />}
              size="small"
              value={bookPreference.value as string}
            />
          </Grid2>

          <Grid2 xs>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WaveIcon code="search" />
                  </InputAdornment>
                ),
              }}
              label={t('search_colors', 'Search Colors')}
              onChange={handleSearchColor}
              size="small"
              value={searchColor}
            />
          </Grid2>
        </Grid2>

        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            {isFetchingGetColor ? (
              <LoadingHeaderAndBody totalColumn={3} totalRow={rowsPerPage} />
            ) : (
              <>
                <TableHead sx={{ backgroundColor: 'primary.main' }}>
                  <TableRow>
                    {columns?.map((column) => (
                      <StyledTableCell key={column.field}>{column.title}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows?.map((data) => (
                    <TableRow key={data.name}>
                      <TableCell>
                        <Radio
                          checked={selectedRow === data.name}
                          onClick={handleClickRadio}
                          size="small"
                          sx={{ marginRight: 1, padding: 0 }}
                          value={data.name}
                        />

                        {data.name}
                      </TableCell>

                      <TableCell>{data.book}</TableCell>

                      <TableCell>{data.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          ActionsComponent={PaginationActions}
          component="div"
          count={totalRowCount || 0}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={t('file-ser.rows_per_page', 'Rows per page')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
          sx={{ background: grey[100], marginTop: 1 }}
        />
      </DialogContent>

      <Box component={DialogActions} justifyContent="space-between">
        <Button color="warning" onClick={handleCloseDialog}>
          {t('close_button', 'Close')}
        </Button>

        <Button color="primary" onClick={handleConfirm}>
          {t('confirm_button', 'Confirm')}
        </Button>
      </Box>
    </Dialog>
  );
}
