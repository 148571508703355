import { CSSProperties } from 'react';
import { useFormContext } from 'react-hook-form';

import { ListItem, ListItemButton } from '@mui/material';

import { FieldMapper2 } from 'src/features/JobForm/components/FieldMapper2';
import { SelectedRows } from 'src/features/JobsTable/utilities/hooks/useJobsTable';

type JobIdProps = {
  data: { selectedRows: SelectedRows };
  index: number;
  style: CSSProperties;
};

export function JobId({ data: { selectedRows }, index, style }: JobIdProps) {
  const { formState } = useFormContext();

  const selectedRow = selectedRows[index].toString();

  return (
    <ListItem className="list" dense disablePadding style={style}>
      <ListItemButton disabled={formState.isSubmitting}>
        <FieldMapper2
          alias={`jobId${selectedRow}`}
          isDisabled={formState.isSubmitting}
          name={selectedRow}
          type="boolean"
        />
      </ListItemButton>
    </ListItem>
  );
}
