import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import {
  ClickRowCheckboxParameters,
  Columns,
  Deadlines,
  IdsOfExpandedSubJobs,
  jobIdsWithSubJobs,
  OpenConfirmationDialogParameters,
  Rows,
  SelectedRows,
  TotalRowCount,
} from 'src/features/JobsTable/utilities/hooks/useJobsTable';
import { useAppSelector, usePreference, usePreferencePrefix } from '../../utilities/hooks';
import { Header } from './components/Header';
import { LoadingHeaderAndBody } from './components/LoadingHeaderAndBody';
import { PaginationActions } from './components/PaginationActions';
import { Row } from './components/Row';
import { convertPagePreferenceToPage } from './utilities/helperFunctions';

type JobsTableProps = {
  areActionsDisabled: boolean;
  columns: Columns;
  deadlines?: Deadlines;
  idsOfExpandedSubJobs?: IdsOfExpandedSubJobs;
  jobIdsWithSubJobs?: jobIdsWithSubJobs;
  // eslint-disable-next-line react/boolean-prop-naming
  loadingJobs: boolean;
  onSetPageHistory: (arg: number) => void;
  onSetSelectedRows: Dispatch<SetStateAction<SelectedRows>>;
  rows: Rows;
  selectedRows: SelectedRows;
  totalRowCount: TotalRowCount;
  transferClickRowRadio: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  transferClickRowCheckbox: ({ e, rowId }: ClickRowCheckboxParameters) => void;
  transferOpenConfirmationDialog: ({ jobId, jobType }: OpenConfirmationDialogParameters) => void;
  transferSetIDsOfExpandedSubJobs?: Dispatch<SetStateAction<IdsOfExpandedSubJobs>>;
  transferToggleSelectAllRows: any;
};

export function JobsTable({
  areActionsDisabled = false,
  columns,
  deadlines,
  idsOfExpandedSubJobs,
  jobIdsWithSubJobs,
  loadingJobs,
  onSetPageHistory,
  onSetSelectedRows,
  rows,
  selectedRows,
  totalRowCount,
  transferClickRowCheckbox,
  transferClickRowRadio,
  transferOpenConfirmationDialog,
  transferSetIDsOfExpandedSubJobs,
  transferToggleSelectAllRows,
}: JobsTableProps) {
  const { t } = useTranslation();
  const { preferencePrefix } = usePreferencePrefix({ forcedJobType: 'pro' });

  const [activeFiltersPreference] = usePreference(`${preferencePrefix}.search`, []);
  const [isDense, setIsDense] = useState(true);
  const [pagePreference, setPagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');
  const [rowsPerPagePreference, setRowsPerPagePreference] = usePreference(
    `${preferencePrefix}.rowsPerPage`,
    '25',
  );
  const rowsJobTypes = rows.map((row) => row.src);
  const userInsertRights = useAppSelector((state) => state.user.rights.insert);
  const shouldShowCopyColumn = rowsJobTypes.some((rowJobType) =>
    userInsertRights.includes(`job-${rowJobType}`),
  );

  const rowsPerPage = rowsPerPagePreference.value
    ? parseInt(rowsPerPagePreference.value as string)
    : 25;
  const page = convertPagePreferenceToPage({
    pagePreferenceValue: pagePreference.value,
    rowsPerPage,
    totalRowCount,
  });

  function handleChangeDense(e: ChangeEvent<HTMLInputElement>) {
    setIsDense(e.target.checked);
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    setPagePreference({ ...pagePreference, value: page.toString() });
    onSetSelectedRows([]);
    if (!activeFiltersPreference.value?.length) {
      onSetPageHistory(page);
    }
  }

  function handleChangeRowsPerPage(e: ChangeEvent<HTMLInputElement>) {
    const rowsPerPage = e.target.value;

    setRowsPerPagePreference({
      ...rowsPerPagePreference,
      value: rowsPerPage.toString(),
    });
    setPagePreference({ ...pagePreference, value: '0' });
    onSetSelectedRows([]);
    if (!activeFiltersPreference.value.length) {
      onSetPageHistory(0);
    }
  }

  function labelDisplayedRows() {
    return null;
  }

  return (
    <>
      {!loadingJobs && (
        <Grid container justifyContent="space-between">
          <Grid margin={1} pl={3}>
            <FormControlLabel
              control={<Switch checked={isDense} onChange={handleChangeDense} />}
              label={
                <Typography fontSize="0.875rem">
                  <Trans i18nKey="lib.dense_padding">Dense Padding</Trans>
                </Typography>
              }
            />
          </Grid>

          <Grid justifyContent="flex-end">
            <TablePagination
              ActionsComponent={PaginationActions}
              classes={{ root: 'min-height-52' }}
              component="div"
              count={totalRowCount}
              labelDisplayedRows={labelDisplayedRows}
              labelRowsPerPage={t('file-ser.rows_per_page', 'Rows per page')}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            />
          </Grid>
        </Grid>
      )}

      <TableContainer sx={{ flexShrink: 0, overflow: 'auto' }}>
        <Table size="small">
          {loadingJobs ? (
            <LoadingHeaderAndBody totalColumn={8} totalRow={10} />
          ) : (
            <>
              <Header
                areActionsDisabled={areActionsDisabled}
                columns={columns}
                jobIdsWithSubJobs={jobIdsWithSubJobs}
                onToggleSelectAllRows={transferToggleSelectAllRows}
                selectedRowsLength={selectedRows.length}
                shouldShowCopyColumn={shouldShowCopyColumn}
                totalRowCount={totalRowCount}
              />

              {!rows.length ? (
                <caption className="primary-txt text-align-center txt-inherit-family txt-inherit-size">
                  Nothing to Show
                </caption>
              ) : (
                <TableBody>
                  {rows.map((row, index) => (
                    <Row
                      areActionsDisabled={areActionsDisabled}
                      columns={columns}
                      deadline={deadlines?.[row.jobid]}
                      idsOfExpandedSubJobs={idsOfExpandedSubJobs}
                      isDense={isDense}
                      jobIdsWithSubJobs={jobIdsWithSubJobs}
                      key={row.jobid}
                      onClickRowCheckbox={transferClickRowCheckbox}
                      onClickRowRadio={transferClickRowRadio}
                      onOpenConfirmationDialog={transferOpenConfirmationDialog}
                      row={row}
                      rowId={index}
                      selectedRows={selectedRows}
                      setIdsOfExpandedSubJobs={transferSetIDsOfExpandedSubJobs}
                      totalRowCount={totalRowCount}
                    />
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
