import {
  ClipboardData,
  ClipboardSection,
} from 'src/features/JobForm/components/CopyCompareButton/type';
import { FieldsTransformed, JobValues } from 'src/pages/Job/Job.service';
import { Section } from 'src/pages/Job/JobContext';

export function addOrUpdateSection(
  sections: ClipboardData,
  newSection: ClipboardSection,
): ClipboardData {
  const sectionIndex = sections.findIndex((section) => section.sectionId === newSection.sectionId);

  if (sectionIndex !== -1) {
    const updatedSections = [...sections];

    updatedSections[sectionIndex] = newSection;

    return updatedSections;
  } else {
    return [...sections, newSection];
  }
}

export function createCopiedSectionData(
  fields: FieldsTransformed | undefined,
  section: Section,
  jobData: JobValues,
) {
  return [
    {
      fields: section.fields
        ?.filter((field) => !fields?.[field.alias].isHidden)
        .map((field) => ({
          fieldId: field.alias,
          value: jobData && jobData[field.alias],
        })),
      sectionId: section.code,
    },
  ];
}

export function updatedJobValue(
  dirtyFields: Record<string, string>,
  getValues: (arg: string) => void,
  values: JobValues,
) {
  const _dirtyFields: Record<string, string> = {};

  Object.keys(dirtyFields).forEach((alias) => {
    let fieldValue: any = getValues(alias);

    if (fieldValue?.value) {
      fieldValue = fieldValue.value;
    }

    _dirtyFields[alias] = fieldValue;
  });

  return { ...values, ..._dirtyFields };
}
