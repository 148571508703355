import { Typography } from '@mui/material';

import { getDateFormat } from 'src/components/RHF/DateField/utils';
import { DeadlineDateString } from 'src/features/Workflow/Workflow.service';
import { currentDate } from 'src/utilities/constants';
import { isString, usePreference } from 'src/utilities/hooks/usePreference';

type DeadlineProps = {
  isCurrentStatus: boolean;
  deadline: DeadlineDateString;
};

export function Deadline({ deadline, isCurrentStatus }: DeadlineProps) {
  const [languageCodePreference] = usePreference('sys.lang', 'uk');
  const languageCode = isString(languageCodePreference.value) ? languageCodePreference.value : 'uk';
  const deadlineDate = new Date(deadline);
  const deadlineDateFormatted = getDateFormat(deadlineDate, languageCode);
  const deadlineDifference = isCurrentStatus ? computeDeadlineDifference() : 0;
  const deadlineDifferenceColor =
    deadlineDifference !== 0 ? (deadlineDifference > 0 ? 'success.main' : 'error.main') : '';
  const deadlineDifferenceSymbol = deadlineDifference > 0 ? '+' : '';

  function computeDeadlineDifference() {
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    const millisecondsInASecond = 1000;
    const millisecondsInADay =
      millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay;

    return Math.floor((+deadlineDate - +currentDate) / millisecondsInADay);
  }

  return (
    <Typography sx={{ mt: 1 }} variant="caption">
      {deadlineDateFormatted}

      {isCurrentStatus ? (
        <Typography sx={{ color: deadlineDifferenceColor, ml: 0.5 }} variant="caption">
          {`(${deadlineDifferenceSymbol}${deadlineDifference})`}
        </Typography>
      ) : null}
    </Typography>
  );
}
