import { FieldsTransformed, FieldType, FieldValues } from 'src/pages/Job/Job.service';
import {
  Aliases,
  FieldLayout,
  FieldLayoutFields,
  FieldLayouts,
  Section,
  Sections,
  Table,
} from 'src/pages/Job/JobContext';

export function buildDefaultValues(
  fieldLayouts: FieldLayouts,
  rtkQueryFields: FieldsTransformed,
  values: FieldValues,
) {
  const defaultValues = {} as FieldValues;

  addDefaultValuesForFieldLayouts(fieldLayouts);

  function addDefaultValuesForFieldLayouts(fieldLayouts: FieldLayouts) {
    Object.values(fieldLayouts).forEach((fieldLayout) => {
      addDefaultValuesForAFieldLayout(fieldLayout);
    });
  }

  function addDefaultValuesForAFieldLayout(fieldLayout: FieldLayout) {
    addDefaultValuesForSections(fieldLayout.sections);
  }

  function addDefaultValuesForSections(sections: Sections) {
    sections.forEach((section) => addDefaultValuesForASection(section));
  }

  function addDefaultValuesForASection({ fields: fieldLayoutFields, sections, table }: Section) {
    if (table) addDefaultValuesForATable(table);

    if (fieldLayoutFields) addDefaultValuesForLayoutFields(fieldLayoutFields);

    if (sections) addDefaultValuesForSections(sections);
  }

  function addDefaultValuesForATable(table: Table) {
    table.rows.forEach(({ fixedFields, scrollableFields }) => {
      addDefaultValues(fixedFields);
      addDefaultValues(scrollableFields);
    });
  }

  function addDefaultValuesForLayoutFields(fieldLayoutFields: FieldLayoutFields) {
    fieldLayoutFields.forEach(({ alias }) => {
      addDefaultValue(alias);
    });
  }

  function addDefaultValues(aliases: Aliases) {
    aliases.forEach((alias) => {
      addDefaultValue(alias);
    });
  }

  function addDefaultValue(alias: string) {
    const type = rtkQueryFields?.[alias]?.type ?? 'string';
    const value = (values as FieldValues)[alias] ?? computeDefaultFieldValueByType(type);

    defaultValues[alias] = value;
  }

  return defaultValues;
}

export function computeDefaultFieldValueByType(fieldType: FieldType) {
  if (
    fieldType === 'ccomplete' ||
    fieldType === 'cselect' ||
    fieldType === 'gselect' ||
    fieldType === 'pick' ||
    fieldType === 'resselect' ||
    fieldType === 'select' ||
    fieldType === 'tselect' ||
    fieldType === 'uselect' ||
    fieldType === 'valselect' ||
    fieldType === 'date'
  ) {
    return null;
  }

  if (fieldType === 'boolean') return false;

  if (
    fieldType === 'bigint' ||
    fieldType === 'bigintnull' ||
    fieldType === 'int' ||
    fieldType === 'pbar'
  ) {
    return 0;
  }

  return '';
}
