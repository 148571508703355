import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { Box, DialogTitle, IconButton } from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';

type WaveDialogTitleProps = { title: string | ReactNode; onClose?: () => void };

export function WaveDialogTitle({ onClose, title }: WaveDialogTitleProps) {
  return (
    <Box
      alignItems="center"
      bgcolor="primary.main"
      color="primary.contrastText"
      component={DialogTitle}
      display="flex"
      justifyContent="space-between"
    >
      <Trans>{title}</Trans>

      {onClose && (
        <Box color="white">
          <IconButton color="inherit" onClick={onClose} size="small">
            <WaveIcon code="close" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
