import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";

import { Grid, Skeleton } from "@mui/material";

import { ChartTitle } from "../ChartTitle";
import { NothingToShow } from "../NothingToShow";
import { waveTheme } from "../../../../components/WaveTheme";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);

export function BarChart({ bars, hoverBarLabel, loading, title }) {
  let labels = [];
  let total = 0;
  let totals = [];

  bars.forEach((bar) => {
    labels = [...labels, bar.label];
    total += bar.total;
    totals = [...totals, bar.total];
  });

  if (loading) {
    return (
      <Grid>
        <Skeleton height={100} variant="rectangular" />
      </Grid>
    );
  }

  if (!bars.length) {
    return <NothingToShow title={title} />;
  }

  return (
    <Grid alignItems="center" container direction="column" item wrap="nowrap">
      <ChartTitle title={`${title}: ${total}`} />

      <Grid item>
        <Bar
          data={{
            labels,
            datasets: [
              {
                label: hoverBarLabel,
                backgroundColor: [waveTheme.palette.primary.main],
                data: totals,
              },
            ],
          }}
          plugins={[ChartDataLabels]}
          options={{
            plugins: {
              legend: false,
              datalabels: {
                color: "white",
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </Grid>
    </Grid>
  );
}
