import { useEffect, useState } from 'react';

import { Box, Skeleton, Unstable_Grid2 as Grid } from '@mui/material';

import { useAppSelector } from 'src/utilities/hooks';
import { useRouteParams } from '../../utilities/hooks';
import { ActionBar } from './components/ActionBar';
import { HistoryItem } from './components/HistoryItem';
import { Slider } from './components/Slider';
import { useGetEmailListQuery, useGetHistoryQuery } from './History.service';
import {
  calculateHistoryDifference,
  calculateHistorySliderValue,
  mutateHistorySliderValues,
} from './utilities/helperFunctions';

import type { Filter, HistoryItemId, HistoryItemType, SliderValue } from './History.types';

export function History() {
  const userID = useAppSelector((state) => state.user.details.id);
  const { age, jobId, jobType } = useRouteParams();
  const {
    data: historyData,
    isFetching,
    isSuccess: isHistoryFetchedSuccessfully,
  } = useGetHistoryQuery(
    {
      age,
      jobid: jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: emailListData } = useGetEmailListQuery({
    age,
    id: userID,
    jobid: jobId,
    src: jobType,
  });

  const [expandedHistoryItemIds, setExpandedHistoryItemIds] = useState<HistoryItemId[]>([]);
  const [activeHistoryFilters, setActiveHistoryFilters] = useState<Filter[]>([]);
  const [history, setHistory] = useState<HistoryItemType[]>([]);
  const [sliderValues, setSliderValues] = useState<SliderValue[]>([]);

  function handleClickFilter(activeFilters: Filter[]) {
    setActiveHistoryFilters(activeFilters);
    // The expandedHistoryItemIds must be reset when changing
    // filters so that items are not expanded that aren't even visible
    setExpandedHistoryItemIds([]);

    if (!activeFilters.length) {
      setHistory(historyData?.history || []);
      setSliderValues(historyData?.sliderValues || []);
    } else {
      const filteredHistory = (historyData?.history || []).filter((originalHistoryItem) =>
        activeFilters.map((filter) => filter.code).includes(originalHistoryItem.type.code),
      );
      const sliderValues: string[] = [];
      const difference = calculateHistoryDifference(filteredHistory);

      const finalHistory = filteredHistory.map((historyItem) => {
        const sliderValue = calculateHistorySliderValue({
          difference,
          historyItemDate: historyItem.datum,
        });

        sliderValues.push(sliderValue);

        return { ...historyItem, sliderValue };
      });

      setHistory(finalHistory);
      setSliderValues(mutateHistorySliderValues(sliderValues));
    }
  }

  function handleToggleExpand(historyItemId: HistoryItemId) {
    setExpandedHistoryItemIds(
      expandedHistoryItemIds.includes(historyItemId)
        ? expandedHistoryItemIds.filter((id) => id !== historyItemId)
        : [...expandedHistoryItemIds, historyItemId],
    );
  }

  function handleToggleExpandAll(areAllHistoryItemsExpanded: boolean) {
    setExpandedHistoryItemIds(areAllHistoryItemsExpanded ? history.map(({ id }) => id) : []);
  }

  useEffect(() => {
    if (isHistoryFetchedSuccessfully) {
      setHistory(historyData.history);
      setSliderValues(historyData.sliderValues);
    }
  }, [isHistoryFetchedSuccessfully, historyData]);

  return (
    <Box marginX={1} role="tabpanel">
      {history.length && !isFetching ? (
        <>
          <ActionBar
            activeHistoryFilters={activeHistoryFilters}
            expandedHistoryItemIds={expandedHistoryItemIds}
            history={history}
            historyFilters={historyData?.filters || []}
            onClickFilter={handleClickFilter}
            onToggleExpandAll={handleToggleExpandAll}
          />

          <Grid container margin={0} spacing={1}>
            <Grid xs>
              {history.map((historyItem, index) => (
                <HistoryItem
                  emailListData={emailListData?.filter((email) => email.his_id == historyItem.id)}
                  expandedHistoryItemIds={expandedHistoryItemIds}
                  hasEmailList={historyItem.email_list}
                  historyItem={historyItem}
                  index={index}
                  key={historyItem.id}
                  onToggleExpand={handleToggleExpand}
                />
              ))}
            </Grid>

            {history.length > 4 && <Slider sliderValues={sliderValues} />}
          </Grid>
        </>
      ) : (
        <>
          <Box display="flex" gap={1} justifyContent="end" marginY={1}>
            {[...Array(3)].map((_, index) => {
              return <Skeleton height="40px" key={index} width="85px" />;
            })}
          </Box>

          <Grid container margin={0} spacing={1}>
            <Grid xs>
              {[...Array(4)].map((_, index) => (
                <Skeleton height="80px" key={index} />
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
