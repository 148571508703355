import { MouseEvent, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { Badge, Box, IconButton } from '@mui/material';

import { waveTheme } from 'src/components/WaveTheme';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from '../../../../store/waveSnackSlice';
import { API } from '../../../../utilities/api';
import { useAppDispatch, useAppSelector, usePreference } from '../../../../utilities/hooks';

export function RecentJobs() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [clientIdPreference] = usePreference('sys.mid', '');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const rights = useAppSelector((state: any) => state.user.rights);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const path = location.pathname.split('/');
  const age = path[1].split('-')[1];
  const jobType = path[1].split('-')[2];
  const jobId = path[2];
  const tab = path[3];

  function handleClickRecentJobs(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
  }

  function handleCloseRecentJobs() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  async function loadRecentJobs() {
    await API.get('/jobs/recent', {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onError: ({ message }: { message: string }) => {
        dispatch(
          openWaveSnack({
            message: message,
            type: 'error',
          }),
        );
      },
      onSuccess: setItems,
    });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) loadRecentJobs();

    return () => {
      isMounted = false;
    };
  }, [clientIdPreference.value]);

  useEffect(() => {
    let isMounted = true;

    if (
      isMounted &&
      (age === 'job' || age === 'arc') &&
      jobId &&
      jobId !== 'new' &&
      rights.read.includes(`job-${jobType}`) &&
      tab !== 'assign'
    ) {
      (async () => {
        await API.post(
          '/jobs/recent',
          {
            age,
            jobid: jobId,
            src: jobType,
          },
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onError: ({ message }: { message: string }) => {
              dispatch(
                openWaveSnack({
                  message: message,
                  type: 'error',
                }),
              );
            },
            onSuccess: () => loadRecentJobs(),
          },
        );
      })();
    }

    return () => {
      isMounted = false;
    };
  }, [jobId]);

  return (
    <>
      <Box
        bgcolor={isSubMenuOpen ? waveTheme.palette.secondary.main : ''}
        color={isSubMenuOpen ? waveTheme.palette.primary.main : waveTheme.palette.secondary.main}
        component={IconButton}
        onClick={handleClickRecentJobs}
        size="large"
      >
        <Badge badgeContent={17} color="info">
          <WaveIcon code="history" />
        </Badge>
      </Box>

      {isSubMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          className="mt-10 filler-bg"
          items={items}
          onClose={handleCloseRecentJobs}
          open={isSubMenuOpen}
        />
      )}
    </>
  );
}
