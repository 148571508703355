import { Trans } from 'react-i18next';

import { IconButton, InputAdornment, TextField } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';

type RecipientProps = {
  index: number;
  onAddRecipient: () => void;
  onRemoveRecipient: () => void;
};

export function Recipient({ index, onAddRecipient, onRemoveRecipient }: RecipientProps) {
  return (
    <TextField
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={index === 0 ? onAddRecipient : onRemoveRecipient}>
              <WaveIcon code={index === 0 ? 'add' : 'remove'} fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={<Trans>lib.to</Trans>}
      size="small"
      variant="outlined"
    />
  );
}
