import { Box, Grid, TableCell, TableHead, TableRow, useTheme } from '@mui/material';

import { FieldMapper } from '../../../FieldMapper';
import { HeaderCell } from '../HeaderCell';

import { convertPagePreferenceToPage } from '../../utilities/helperFunctions';
import { usePreference, usePreferencePrefix } from '../../../../utilities/hooks';

export function Header({
  areActionsDisabled = false,
  columns,
  jobIdsWithSubJobs = [],
  onToggleSelectAllRows,
  selectedRowsLength,
  subHeader = null,
  totalRowCount,
  shouldShowCopyColumn,
}) {
  const { isSubJobs, jobType, tab, preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });

  const [pagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');
  const {
    palette: {
      primary: { main: primary },
    },
  } = useTheme();
  const [rowsPerPagePreference] = usePreference(`${preferencePrefix}.rowsPerPage`, '25');

  const rowsPerPage = parseInt(rowsPerPagePreference.value);
  const lastPage = Math.floor(totalRowCount / rowsPerPage);
  const rowsOnLastPage = totalRowCount % rowsPerPage;
  const indeterminate = selectedRowsLength
    ? selectedRowsLength === rowsPerPage ||
      (convertPagePreferenceToPage({
        pagePreferenceValue: pagePreference.value,
        totalRowCount,
        rowsPerPage,
      }) === lastPage &&
        rowsOnLastPage === selectedRowsLength)
      ? false
      : true
    : false;
  const checked = !indeterminate && selectedRowsLength;

  return (
    <TableHead>
      <TableRow>
        <Box bgcolor={subHeader ? primary : ''} component={TableCell} />

        {jobIdsWithSubJobs.length > 0 && <TableCell />}

        <Box component={TableCell} bgcolor={subHeader ? primary : ''} padding="checkbox">
          <Grid container>
            {jobType !== 'dash' && !subHeader && !isSubJobs && tab !== 'assign' && (
              <Grid item>
                <FieldMapper
                  indeterminate={indeterminate}
                  transferChangeField={onToggleSelectAllRows}
                  type="boolean"
                  value={checked}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {
          <>
            {!subHeader && jobType !== 'dash' && !areActionsDisabled && shouldShowCopyColumn && (
              <TableCell colSpan={2} />
            )}

            {columns.map((column) => (
              <HeaderCell
                alias={column.field}
                key={column.field}
                subHeader={subHeader}
                title={column.title}
              />
            ))}
          </>
        }
      </TableRow>
    </TableHead>
  );
}
