import { AccordionSummary as MIUAccordionSummary, AccordionSummaryProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { WaveIcon } from '../../features/WaveIcon';
type StyledAccordionSummaryProps = { iconColor?: string; shouldRenderIcon?: boolean };

export const StyledAccordionSummary = styled(
  ({
    iconColor,
    shouldRenderIcon = true,
    ...rest
  }: AccordionSummaryProps & StyledAccordionSummaryProps) => (
    <MIUAccordionSummary
      disabled={!shouldRenderIcon}
      expandIcon={
        shouldRenderIcon && (
          <WaveIcon code="expand-more" sx={{ color: iconColor || 'primary.contrastText' }} />
        )
      }
      {...rest}
    />
  ),
)(({ theme }) => ({
  '&.Mui-expanded': {
    minHeight: 32,
  },
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px 4px 0 0',
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightBold,
  height: 32,
  minHeight: 32,
}));
