import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type Categories = Record<CategoryIdValue, Category>;
type Category = { id: CategoryIdValue; value_en: string };
type CategoryIdValue = number;
export type CategoriesTransformed = { label: string; value: CategoryIdValue }[];

export const uploadFilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getCategories: build.query<CategoriesTransformed, undefined>({
      providesTags: ['Categories'],
      query() {
        return {
          method: 'GET',
          url: '/jobs/files/categories',
        };
      },
      transformResponse(categories: Categories) {
        const categoriesTransformed = Object.values(categories).map(
          ({ id: value, value_en: label }) => ({
            label,
            value,
          }),
        );

        return categoriesTransformed;
      },
    }),
  }),
  reducerPath: 'uploadFilesApi',
  tagTypes: ['Categories'],
});

export const { useGetCategoriesQuery } = uploadFilesApi;
