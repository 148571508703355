import { Box, Chip, TableCell, Typography } from '@mui/material';

import { StyledLink } from 'src/features/JobsTable/components/Row/styled';
import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';

type TextCellProps = {
  deadlineValue?: string;
  link: string;
  isSubRow: boolean;
  value: string;
} & Pick<StatusCellProps, 'paddingY'>;
export function TextCell({ deadlineValue, isSubRow, link, paddingY, value }: TextCellProps) {
  return (
    <TableCell
      className={`${isSubRow ? 'b-left-off-white b-right-off-white ' : ''}no-wrap`}
      sx={{ paddingY }}
    >
      <StyledLink href={link}>
        {deadlineValue ? (
          <Chip
            className={
              deadlineValue === 'late'
                ? 'error white-txt'
                : deadlineValue === 'due'
                ? 'warning white-txt'
                : 'info txt-line-through white-txt'
            }
            label={value}
          />
        ) : value ? (
          <Typography padding={1} variant="body2">
            {Array.isArray(value) && value.length > 1 ? value.join(' • ') : value}
          </Typography>
        ) : (
          <Box height="40px" />
        )}
      </StyledLink>
    </TableCell>
  );
}
