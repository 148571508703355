import { TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { WaveIcon } from '../../../WaveIcon';
import { WaveTooltip } from '../../../../components/WaveTooltip';
import { StyledLink } from '../Row/styled';

export function TasksCell({ paddingY, tasks, link, isSubRow }) {
  const { t } = useTranslation();
  return (
    <TableCell className={isSubRow ? 'b-left-off-white b-right-off-white' : ''} sx={{ paddingY }}>
      <StyledLink href={link}>
        {tasks.length > 0 &&
          tasks.map((task) => (
            <WaveTooltip
              body={
                <Typography color="primary" variant="caption">
                  <strong> {t('lib.status')} </strong>
                  {task.value.charAt(0).toUpperCase() + task.value.slice(1)}
                </Typography>
              }
              component={<WaveIcon code={`job-tasks-${task.value}`} />}
              header={task.name}
              key={task.id}
              placement="top"
              type="custom"
            />
          ))}
      </StyledLink>
    </TableCell>
  );
}
