import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Dialog as MuiDialog, DialogActions, DialogContent } from '@mui/material';

import { Button } from 'src/components/Button';
import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { getAUserRight } from 'src/store/userSlice';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';
import { useCreateFolderMutation, useRenameFolderMutation } from '../../FileExplorer.service';

type BasicProps = {
  expandedFolders: Record<string, boolean>;
  folderCode: string;
  onToggleExpandFolder: (folderCode: string) => void;
  open: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedFolder: Dispatch<SetStateAction<string>>;
};
type Create = {
  previousFoldername?: never;
  variant: 'create';
};
type DialogProps = BasicProps & (Create | Rename);
type FormData = { foldername: string };
type Rename = {
  previousFoldername: string;
  variant: 'rename';
};

export function Dialog({
  expandedFolders,
  folderCode,
  onToggleExpandFolder,
  open,
  previousFoldername,
  setIsDialogOpen,
  setSelectedFolder,
  variant,
}: DialogProps) {
  const [createFolder] = useCreateFolderMutation();
  const dispatch = useAppDispatch();
  const [renameFolders] = useRenameFolderMutation();
  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { foldername: previousFoldername || '' },
    mode: 'onChange',
  });

  const foldernameFieldLabel = 'Foldername';
  const { t } = useTranslation();

  function handleCloseDialog() {
    if (isSubmitting) return;

    setIsDialogOpen(false);
    reset();
  }

  async function onSubmit({ foldername }: FormData) {
    const payload = {
      name: foldername,
      path: folderCode,
    };

    const folderPath =
      variant === 'rename'
        ? folderCode.replace(previousFoldername, foldername)
        : `${folderCode}/${foldername}`;
    const response = variant === 'rename' ? renameFolders(payload) : createFolder(payload);

    await response.unwrap().then((message) => {
      if (variant === 'create' && !expandedFolders[folderCode]) {
        onToggleExpandFolder(folderCode);
      }

      if (variant === 'rename' && !expandedFolders[folderPath]) {
        onToggleExpandFolder(folderPath);
      }

      dispatch(getAUserRight(folderPath));
      dispatch(
        openWaveSnack({
          message,
          type: 'success',
        }),
      );
      handleCloseDialog();
      setSelectedFolder(folderPath);
    });
  }

  return (
    <MuiDialog
      fullWidth
      onClick={(e) => e.stopPropagation()}
      onClose={handleCloseDialog}
      open={open}
    >
      <WaveDialogTitle title={variant === 'rename' ? 'Rename a Folder' : 'Create a Folder'} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            autoFocus
            control={control}
            disabled={isSubmitting}
            label={foldernameFieldLabel}
            name="foldername"
            rules={{
              maxLength: { message: `${t('validate.string.length.max')}`, value: 255 },
              minLength: { message: `${t('validate.string.length.min')}`, value: 3 },
              required: { message: `${t('lib.required')}`, value: true },
              validate: (value) =>
                value === previousFoldername
                  ? 'Must be a a different name.'
                  : (value && !/[/\\]/g.test(value)) ||
                    'Must not contain a forward or backwards slash "/, or \\".',
            }}
          />
        </DialogContent>

        <Box component={DialogActions} justifyContent="space-between">
          <Button disabled={isSubmitting} onClick={handleCloseDialog}>
            lib.cancel
          </Button>

          <WaveTooltip
            body={
              !isDirty
                ? 'Nothing to update.'
                : !isValid
                ? `${foldernameFieldLabel} is invalid.`
                : ''
            }
            component={
              <LoadingButton
                disabled={!isDirty || !isValid}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                {t('lib.confirm')}
              </LoadingButton>
            }
            type="simple"
          />
        </Box>
      </form>
    </MuiDialog>
  );
}
