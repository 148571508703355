import { Button } from 'src/components/Button';

import { WaveIcon } from '../../../WaveIcon';

export function FiltersButton({
  areActiveFiltersVisible,
  code,
  onSetAreActiveFiltersVisible,
  text,
}) {
  function handleClick() {
    onSetAreActiveFiltersVisible(!areActiveFiltersVisible);
  }

  return (
    <Button onClick={handleClick} startIcon={<WaveIcon code={code} />} color="info">
      {text}
    </Button>
  );
}
