import { useEffect, useState } from 'react';

type Items = Record<string | number, boolean>;

export function useExpand(initialItems: Items) {
  const [expandedItems, setExpandedItems] = useState<Items>({});
  const [areAllItemsExpanded, setAreAllItemsExpanded] = useState(false);

  function toggleExpandAll(expanded?: boolean) {
    const updatedItems = {} as Items;

    Object.keys(expandedItems).forEach((key) => {
      updatedItems[key] = expanded ?? !areAllItemsExpanded;
    });

    setExpandedItems(updatedItems);
    setAreAllItemsExpanded(expanded ?? !areAllItemsExpanded);
  }

  function toggleExpandItem(itemId: number | string) {
    const updatedItems = {
      ...expandedItems,
      [itemId]: !expandedItems[itemId],
    };

    setExpandedItems(updatedItems);

    if (Object.values(updatedItems).every((expandedState) => expandedState)) {
      setAreAllItemsExpanded(true);
    }

    if (Object.values(updatedItems).every((expandedState) => !expandedState)) {
      setAreAllItemsExpanded(false);
    }
  }

  useEffect(() => {
    setExpandedItems(initialItems);
  }, [Object.keys(initialItems).length]);

  return {
    areAllItemsExpanded,
    expandedItems,
    toggleExpandAll,
    toggleExpandItem,
  };
}
