import { Trans } from 'react-i18next';

import { Button, Divider, Grid } from '@mui/material';

import { WaveIcon } from '../WaveIcon';
import { ActiveFilter } from './components/ActiveFilter';

type ActiveFiltersProps = {
  filterLabel?: string;
  activeFilters: Array<Record<string, string>>;
  onDeleteAllActiveFilters: () => void;
  transferDeleteActiveFilter: (filter: Record<string, string>) => void;
};

export function ActiveFilters({
  activeFilters,
  filterLabel,
  onDeleteAllActiveFilters,
  transferDeleteActiveFilter,
}: ActiveFiltersProps) {
  return (
    <>
      <Grid alignItems="center" className="py-10" container justifyContent="flex-start">
        {activeFilters.length > 1 && (
          <Grid item>
            <Button onClick={onDeleteAllActiveFilters} startIcon={<WaveIcon code="close" />}>
              <Trans i18nKey="remove_all_button">Remove all</Trans>
            </Button>
          </Grid>
        )}

        <Grid item>
          {activeFilters.map((filter) => {
            const filterKey = Object.keys(filter)[0];
            const label =
              filterKey === 'code'
                ? (filterLabel ? `${filterLabel}: ` : '') + `${filter.text}`
                : `${filter.label}: ${filter.textValue || filter[filterKey]}`;

            return (
              <ActiveFilter
                filter={filter}
                key={label}
                label={label}
                onDeleteActiveFilter={transferDeleteActiveFilter}
              />
            );
          })}
        </Grid>
      </Grid>

      <Divider />
    </>
  );
}
