// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';
import { API } from '../utilities/api';
import { openWaveSnack } from './waveSnackSlice';

export const addBookmark = createAsyncThunk<
  void,
  {
    age?: ExpandedAges;
    jobId?: string | number;
    jobType?: string;
    keyword?: string;
  }
>('bookmarks/addBookmark', async ({ age, jobId, jobType, keyword }, thunkAPI) => {
  await API.post(
    '/bookmarks/set',
    {
      age,
      jobid: jobId,
      keyword,
      src: jobType,
    },
    {
      onError: (error) => {
        thunkAPI.dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      },
      onSuccess: (response) => {
        thunkAPI.dispatch(openWaveSnack({ message: response, type: 'success' }));
      },
    },
  );

  return {
    code: `${age}-${jobType}`,
    jobid: jobId,
    link: `/jobs-${age}-${jobType}/${jobId}`,
    text: `${jobId} ${keyword || ''}`,
  };
});

export const deleteBookmark = createAsyncThunk<void, { jobId?: string | number }>(
  'bookmarks/deleteBookmark',
  async ({ jobId }, thunkAPI) => {
    await API.delete('/bookmarks/delete', {
      onError: (error) => {
        thunkAPI.dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      },
      onSuccess: (response) => {
        thunkAPI.dispatch(openWaveSnack({ message: response, type: 'success' }));
      },
      params: { jobid: jobId },
    });

    return jobId;
  },
);

export const getBookmarks = createAsyncThunk('bookmarks/getBookmarks', async (params, thunkAPI) => {
  const response = await API.get('/bookmarks/get', {
    onError: (error) => {
      thunkAPI.dispatch(
        openWaveSnack({
          message: error.message,
          type: 'error',
        }),
      );
    },
  });

  return response.data.data;
});

export const bookmarksSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(addBookmark.fulfilled, (state, { payload }) => {
      return [...state, payload];
    });
    builder.addCase(deleteBookmark.fulfilled, (state, { payload }) => {
      return state.filter((bookmark) => bookmark.jobid !== payload);
    });
    builder.addCase(getBookmarks.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
  initialState: [],
  name: 'bookmarks',
});

// eslint-disable-next-line import/no-default-export
export default bookmarksSlice.reducer;
