import { useJobContext } from 'src/pages/Job/JobContext';
import { toBool } from 'src/utilities/helperFunctions';
import { usePreference } from 'src/utilities/hooks';

type UseAccordionProps = { code: string };

export function useAccordion({ code }: UseAccordionProps) {
  const {
    fields,
    handleChangeLogicBuilderField,
    handleChangeSteeredField,
    isJobFetching,
    jobType,
  } = useJobContext();

  const [sectionExpandedPreference, setSectionExpandedPreference] = usePreference(
    `job-${jobType}.form-section.${code}`,
    'true',
  );
  const isSectionExpanded = toBool(sectionExpandedPreference.value);

  function handleToggleExpand() {
    setSectionExpandedPreference({
      ...sectionExpandedPreference,
      value: sectionExpandedPreference.value === 'true' ? 'false' : 'true',
    });
  }

  return {
    fields,
    handleChangeLogicBuilderField,
    handleChangeSteeredField,
    handleToggleExpand,
    isJobFetching,
    isSectionExpanded,
  };
}
