import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/Button';

import { FieldSelector } from '../FieldSelector';
import { WaveIcon } from '../../../WaveIcon';

import { useFieldSelectorFields } from '../../utilities/hooks';
import { usePreference, usePreferencePrefix } from '../../../../utilities/hooks';
export function ColumnsButton({ code, text }) {
  const { t } = useTranslation();
  const { preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });

  const [columnsPreference, setColumnsPreference] = usePreference(
    `${preferencePrefix}.columns`,
    '',
  );
  const [fieldSelectorOpen, setFieldSelectorOpen] = useState(false);
  const [
    savedAvailableFields,
    setSavedAvailableFields,
    savedSelectedFields,
    setSavedSelectedFields,
    transferGetFieldSelectorFields,
  ] = useFieldSelectorFields({
    fieldPreference: columnsPreference,
    filter: 'column',
  });

  async function handleApplyFieldSelector(selectedFields) {
    // Extract all of the selectedFields field ID's and convert it to a string to be passed to setColumnsPreference

    setColumnsPreference({
      ...columnsPreference,
      value: selectedFields.map((field) => field.id).join(),
    });
  }

  function handleOpenFieldSelector() {
    setFieldSelectorOpen(true);
  }

  return (
    <>
      <Button onClick={handleOpenFieldSelector} startIcon={<WaveIcon code={code} />}>
        {text}
      </Button>

      {fieldSelectorOpen && (
        <FieldSelector
          fieldPreference={columnsPreference}
          onApplyFieldSelector={handleApplyFieldSelector}
          onGetFieldSelectorFields={transferGetFieldSelectorFields}
          onSetSavedAvailableFields={setSavedAvailableFields}
          onSetSavedSelectedFields={setSavedSelectedFields}
          onSetIsFieldSelectorOpen={setFieldSelectorOpen}
          savedAvailableFields={savedAvailableFields}
          savedSelectedFields={savedSelectedFields}
          type={t('lib.columns', 'Columns')}
        />
      )}
    </>
  );
}
