import { useEffect, useState } from 'react';

import axios from 'axios';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  List,
  TextField,
  Typography,
} from '@mui/material';

import { useTranslation, Trans } from 'react-i18next';

import { FieldSelectorFieldWell } from '../FieldSelectorFieldWell';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { WaveIcon } from '../../../WaveIcon';

import {
  changeArrayItemPosition,
  sortArrayOfObjectsByKey,
} from '../../../../utilities/helperFunctions';
import { useFirstRender } from '../../../../utilities/hooks';

export function FieldSelector({
  fieldPreference,
  onApplyFieldSelector,
  onGetFieldSelectorFields,
  onSetIsFieldSelectorOpen,
  onSetSavedAvailableFields,
  onSetSavedSelectedFields,
  savedAvailableFields,
  savedSelectedFields,
  type,
}) {
  const [availableFields, setAvailableFields] = useState([]);
  const [checkedAvailableFields, setCheckedAvailableFields] = useState([]);
  const firstRender = useFirstRender();
  const [indexesOfCheckedSelectedFields, setIndexesOfCheckedSelectedFields] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);

  const noAvailableFieldsChecked = checkedAvailableFields.length === 0;
  const noSelectedFieldsChecked = indexesOfCheckedSelectedFields.length === 0;
  const selectedFieldsChanged =
    savedSelectedFields.length !== selectedFields.length ||
    savedSelectedFields.some(
      (savedField, index) => savedField.alias !== selectedFields?.[index]?.alias,
    );
  const { t } = useTranslation();
  function changeCheckedSelectedFieldsPositions(change) {
    let _indexesOfCheckedSelectedFields = [];
    let _selectedFields = [...selectedFields];

    indexesOfCheckedSelectedFields.forEach((index) => {
      const newPosition = change === 'down' ? index + 1 : index - 1;

      _indexesOfCheckedSelectedFields.push(newPosition);
      changeArrayItemPosition({
        array: _selectedFields,
        newPosition,
        oldPosition: index,
      });
    });

    setIndexesOfCheckedSelectedFields(_indexesOfCheckedSelectedFields);
    setSelectedFields(_selectedFields);
  }

  function handleChangeSearchField({ target: { value: searchValue } }) {
    // Perform the search on the unaltered array, savedAvailableFields, for true data source
    // Filter out selectedFields and fields that do not match searchValue
    setAvailableFields(
      savedAvailableFields.filter(
        (field) =>
          !selectedFields.includes(field) &&
          field.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
    setSearchValue(searchValue);
  }

  function handleClickAdd() {
    // Remove checkedAvailableFields from availableFields and add to selectedFields while remaining checked
    setAvailableFields(availableFields.filter((field) => !checkedAvailableFields.includes(field)));
    setCheckedAvailableFields([]);
    setIndexesOfCheckedSelectedFields(
      checkedAvailableFields.map((checkedAvailableField, index) => selectedFields.length + index),
    );
    setSelectedFields([...selectedFields, ...checkedAvailableFields]);
  }

  function handleClickApply() {
    onApplyFieldSelector(selectedFields);
  }

  function handleClickDown() {
    changeCheckedSelectedFieldsPositions('down');
  }

  function handleClickRemove() {
    // Remove checkedSelectedFields from selectedFields and add to  availableFields
    const checkedSelectedFields = indexesOfCheckedSelectedFields.map(
      (index) => selectedFields[index],
    );

    onSetSavedAvailableFields(
      sortArrayOfObjectsByKey({
        array: [...availableFields, ...checkedSelectedFields],
        key: 'name',
      }),
    ); // Set savedAvailableFields so the fields removed show up in search
    setIndexesOfCheckedSelectedFields([]);
    setSelectedFields(selectedFields.filter((field) => !checkedSelectedFields.includes(field)));
  }

  function handleClickUp() {
    changeCheckedSelectedFieldsPositions('up');
  }

  function handleOnClose() {
    if (!fieldPreference.loading) onSetIsFieldSelectorOpen(false);
  }

  function handleResetDialog() {
    setAvailableFields(
      sortArrayOfObjectsByKey({ array: [...availableFields, ...selectedFields], key: 'name' }),
    );
    setCheckedAvailableFields([]);
    setIndexesOfCheckedSelectedFields([]);
    setSearchValue('');
    setSelectedFields([]);
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!savedAvailableFields.length > 0) onGetFieldSelectorFields(source.token);

    return () => source.cancel();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !firstRender && !fieldPreference.loading) {
      onSetIsFieldSelectorOpen(false);
      onSetSavedAvailableFields(
        savedAvailableFields.filter((field) => !selectedFields.includes(field)),
      );
      onSetSavedSelectedFields([...selectedFields]);
    }

    return () => (isMounted = false);
  }, [fieldPreference.value]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (!availableFields.length > 0) {
        // setAvailableFields and setSelectedFields for the first time
        setAvailableFields([...savedAvailableFields]);
        setSelectedFields([...savedSelectedFields]);
      } else setAvailableFields([...savedAvailableFields]); // If a field was removed from selectedFields and added to savedAvailableFields
    }

    return () => (isMounted = false);
  }, [savedAvailableFields, savedSelectedFields]);

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={handleOnClose} open>
      <WaveDialogTitle title={`${type} ${t('lib.field_selector', 'Field Selector')}`} />

      <DialogContent>
        <Grid
          alignItems="center"
          className="mt-0"
          container
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              disabled={fieldPreference.loading}
              label={t('search_fields_field', 'Search Fields')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WaveIcon code="search" />
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeSearchField}
              size="small"
              type="search"
              value={searchValue}
              variant="outlined"
            />
          </Grid>

          <Grid item justifySelf="flex-start" xs={5}>
            <Typography fontWeight="bold" variant="subtitle2">
              {t('lib.available_fields_selected', {
                defaultValue: 'Available fields: {{ count }} selected',
                count: `${checkedAvailableFields.length}/${availableFields.length}`,
              })}
            </Typography>
          </Grid>

          <Grid item justifySelf="flex-end" xs={5}>
            <Typography fontWeight="bold" variant="subtitle2">
              {t('lib.available_fields_selected', {
                defaultValue: 'Selected fields: {{ count }} selected',
                count: `${indexesOfCheckedSelectedFields.length}/${selectedFields.length}`,
              })}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <FieldSelectorFieldWell
              checkedFields={checkedAvailableFields}
              fieldPreference={fieldPreference}
              fields={availableFields}
              transferSetCheckedFields={setCheckedAvailableFields}
            />
          </Grid>

          <Grid
            container
            direction="column"
            height="200px"
            item
            justifyContent="space-between"
            xs={1}
          >
            <Grid item>
              <Button
                disabled={noAvailableFieldsChecked || fieldPreference.loading}
                onClick={handleClickAdd}
              >
                <WaveIcon code="keyboard-arrow-right" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={noSelectedFieldsChecked || fieldPreference.loading}
                onClick={handleClickRemove}
              >
                <WaveIcon code="keyboard-arrow-left" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={
                  noSelectedFieldsChecked ||
                  fieldPreference.loading ||
                  indexesOfCheckedSelectedFields.includes(0)
                }
                onClick={handleClickUp}
              >
                <WaveIcon code="keyboard-arrow-up" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={
                  noSelectedFieldsChecked ||
                  fieldPreference.loading ||
                  indexesOfCheckedSelectedFields.includes(selectedFields.length - 1)
                }
                onClick={handleClickDown}
              >
                <WaveIcon code="keyboard-arrow-down" />
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <List component="nav" disablePadding>
              <FieldSelectorFieldWell
                checkedFields={indexesOfCheckedSelectedFields}
                fieldPreference={fieldPreference}
                fields={selectedFields}
                transferSetCheckedFields={setIndexesOfCheckedSelectedFields}
                type="selected"
              />
            </List>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="space-between">
        <Button color="warning" disabled={fieldPreference.loading} onClick={handleResetDialog}>
          {t('lib.reset')}
        </Button>

        <LoadingButton
          disabled={fieldPreference.loading || !selectedFieldsChanged}
          loading={fieldPreference.loading}
          onClick={handleClickApply}
          variant="contained"
        >
          <Trans i18next="apply_button">Apply</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
