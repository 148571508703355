import { ReactElement, useEffect } from 'react';

import { initializeLanguage } from 'src/utilities/language/utils';
import { useI18Initialized } from '../hooks';

type LanguageInitializer = {
  children: ReactElement;
};

export function LanguageInitializer({ children }: LanguageInitializer) {
  const isInitialized = useI18Initialized();

  useEffect(() => {
    if (!isInitialized) {
      initializeLanguage();
    }
  }, [isInitialized]);

  return <>{children}</>;
}
