import { useTranslation } from 'react-i18next';

import { Switch } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { toBool } from 'src/utilities/helperFunctions';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';

export function HeaderSwitch() {
  const { age, jobId, jobType, preferencePrefix, tab } = usePreferencePrefix();
  const { t } = useTranslation();
  const body = t('breadcrumb_toggle', 'Toggle the workflow display On/Off');
  const isAssignedProjectPage = tab === 'assign';
  const isComparePage = tab === 'compare';
  const pathName = location.pathname.substring(1);
  const isJobHeader = age && jobType;
  const headerPreference = isJobHeader ? `${preferencePrefix}.header` : `${pathName}.header`;
  const [displayHeaderPreference, setDisplayHeaderPreference] = usePreference(
    headerPreference,
    'true',
  );

  const shouldDisplayHeader = toBool(displayHeaderPreference.value);

  function handleToggleHeader() {
    setDisplayHeaderPreference({
      ...displayHeaderPreference,
      value: displayHeaderPreference.value === 'true' ? 'false' : 'true',
    });
  }

  function shouldShowHeaderSwitch(): boolean {
    const isNewJob = jobId === 'new';
    const isJobTypeAllOrSearch = jobType === 'all' || jobType === 'search';
    const isInFilesPath = location.pathname.includes('files');
    const isUserSettingsOrProfilePath =
      location.pathname === '/user-settings' || location.pathname === '/user-profile';
    const isFileSearchPath = location.pathname === '/file-search';

    return (
      !isAssignedProjectPage &&
      !isComparePage &&
      !isNewJob &&
      !isJobTypeAllOrSearch &&
      !isInFilesPath &&
      !isUserSettingsOrProfilePath &&
      !isFileSearchPath
    );
  }

  if (!shouldShowHeaderSwitch()) return null;

  return (
    <WaveTooltip
      body={body}
      component={
        <Switch checked={shouldDisplayHeader} color="primary" onChange={handleToggleHeader} />
      }
      placement="right"
      type="simple"
    />
  );
}
