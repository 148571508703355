import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import axios from 'axios';

import { Container, Grid } from '@mui/material';

import { ActionBar } from '../../features/JobsTable/components/ActionBar';
import { Backup } from '../../components/Backup';
import { Filters } from '../../features/JobsTable/components/Filters';
import { JobsTable } from '../../features/JobsTable';
import { Tasks } from '../../features/Tasks';

import { API } from '../../utilities/api';
import { usePreference } from '../../utilities/hooks';
import { useJobsTable } from '../../features/JobsTable/utilities/hooks';
import { openWaveSnack } from '../../store/waveSnackSlice';

export function Dashboard() {
  const [clientIDPreference] = usePreference('sys.mid', undefined);
  const dispatch = useDispatch();
  const {
    approvalTasks,
    areActiveFiltersVisible,
    columns,
    deadlines,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleToggleSelectAllRows,
    jobTasks,
    loadingJobs,
    myBackupUserActive,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setMyBackupUserActive,
    setPageHistory,
    setSelectedRows,
    setTeammateID,
    setUserIBackupActive,
    statusChangeTasks,
    teammateID,
    totalRowCount,
    userIBackupActive,
  } = useJobsTable();
  const location = useLocation();
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [users, setUsers] = useState({});
  const userID = useSelector((state) => state.user.details.id);

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async (cancelToken) => {
      await API.get('/membership/getteammembers', {
        cancelToken: cancelToken,
        onError: (error) => {
          dispatch(
            openWaveSnack({
              type: 'error',
              message: error.message,
            }),
          );
          setIsLoadingUsers(false);
        },

        onSuccess: (response) => {
          setIsLoadingUsers(false);
          setUsers(response);
        },
      });
    })(source.token);

    return () => source.cancel();
  }, [clientIDPreference.value]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && location.state?.missingRights) {
      dispatch(
        openWaveSnack({
          type: 'error',
          message: `You do not have rights to ${location.state.missingRights}.`,
        }),
      );
    }
    return () => (isMounted = false);
  }, [location]);

  return (
    <>
      <Container classes={{ root: 'p-0' }} maxWidth={false}>
        <Grid container className="p-10" spacing={2}>
          <Grid item xs={9}>
            <Tasks
              approvalTasks={approvalTasks}
              isLoadingUsers={isLoadingUsers}
              jobTasks={jobTasks}
              loading={loadingJobs}
              onSetTeammateID={setTeammateID}
              statusChangeTasks={statusChangeTasks}
              teammateID={teammateID}
              users={users}
            />
          </Grid>

          <Grid item xs={3}>
            <Backup
              myBackupUserActive={myBackupUserActive}
              onSetMyBackupUserActive={setMyBackupUserActive}
              onSetUserIBackupActive={setUserIBackupActive}
              userIBackupActive={userIBackupActive}
              users={users}
            />
          </Grid>
        </Grid>
      </Container>

      <Container className="py-5" maxWidth={false}>
        <ActionBar
          areActiveFiltersVisible={areActiveFiltersVisible}
          onChangePageSearch={handleChangePageSearch}
          searchTerm={searchTerm}
          teammateID={teammateID}
          transferSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          onSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        columns={columns}
        deadlines={deadlines}
        loadingJobs={loadingJobs}
        onSetPageHistory={setPageHistory}
        onSetSelectedRows={setSelectedRows}
        rows={rows}
        selectedRows={selectedRows}
        totalRowCount={totalRowCount}
        transferClickRowCheckbox={handleClickRowCheckbox}
        transferToggleSelectAllRows={handleToggleSelectAllRows}
      />
    </>
  );
}
