import { useEffect, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';

import dayjs, { Dayjs } from 'dayjs';

import { DatePickerProps } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { getFormattedTextFieldValue, getIsDayInRange } from 'src/components/RHF/DateField/utils';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreference } from 'src/utilities/hooks';
import { isString } from 'src/utilities/hooks/usePreference';

type DateFieldOwnProps = {
  description?: string;
  error?: never;
  isError?: boolean;
  isRawText?: boolean;
  isRange?: boolean;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  required?: never;
  shouldShowHelperText?: boolean;
};
type DateFieldProps<T> = DatePickerProps<T> & DateFieldOwnProps;

export function DateField<T extends FieldValues>({
  control,
  description,
  disabled,
  isError = false,
  isRange = false,
  isRawText = false,
  name,
  onChange,
  onChangeLogicBuilderField,
  rules,
  shouldShowHelperText = true,
  ...rest
}: DateFieldProps<T>) {
  const {
    field: { onChange: onFieldChange, ref, value: reactHookFormValue, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
  });
  const value = reactHookFormValue ? reactHookFormValue : '0000-00-00';

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(value[0]),
    dayjs(value[1]),
  ]);
  const [date, setDate] = useState<Dayjs | null>(value === '0000-00-00' ? null : dayjs(value));
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltipsPreference] = usePreference('job.feldtips', 'Y');
  const [languageCodePreference] = usePreference('sys.lang', 'uk');
  const languageCode = isString(languageCodePreference.value) ? languageCodePreference.value : 'uk';

  const [textFieldValue, setTextFieldValue] = useState('');

  function handleCloseCalendarOverlay() {
    setIsOpen(false);
  }

  function handleDateChange(date: Dayjs | null) {
    if (isRange) {
      if (dateRange[0] && !dateRange[1]) {
        if (date?.isAfter(dateRange[0])) {
          setDateRange([dateRange[0], date]);
          setIsOpen(false); // Close the picker once the range is complete
        } else {
          setDateRange([date, null]);
        }
      } else {
        setDateRange([date, null]);
      }
    } else {
      setDate(date);
      setIsOpen(false); // Close the picker once a single date is picked
    }

    const dateToDateType = date?.toDate();
    const dateRangeToDateType = [dateRange[0]?.toDate(), dateRange[1]?.toDate()];
    const returnDate = isRange ? dateRangeToDateType : dateToDateType;

    // The RHF onChange must come first
    // so that the value is available when running other onChange functions
    onFieldChange(returnDate || null);
    onChange?.(returnDate || null);
    if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);
  }

  function handleOpenCalendarOverlay() {
    setIsOpen(true);
  }

  useEffect(() => {
    setDate(value === '0000-00-00' ? null : dayjs(value));
  }, [value]);

  useEffect(() => {
    const formattedTextFieldValue = getFormattedTextFieldValue(
      isRange,
      date,
      dateRange,
      languageCode,
    );

    setTextFieldValue(value === '0000-00-00' ? '' : formattedTextFieldValue);
  }, [date, dateRange, isRange, value]);

  if (isRawText) {
    return <Typography>{textFieldValue || ''}</Typography>;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          closeOnSelect={false}
          disabled={disabled}
          inputRef={ref}
          onChange={handleDateChange}
          onClose={handleCloseCalendarOverlay}
          onOpen={handleOpenCalendarOverlay}
          open={isOpen}
          renderDay={(day, _value, { selected, ...dayProps }: PickersDayProps<Dayjs>) => {
            const isDayInRange = getIsDayInRange(day, dateRange[0], dateRange[1]);

            return <PickersDay {...dayProps} selected={isRange ? isDayInRange : selected} />;
          }}
          renderInput={({ InputProps, inputRef, label }) => {
            return (
              <TextField
                disabled={disabled}
                error={!!error || isError}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={InputProps}
                label={label}
                onClick={handleOpenCalendarOverlay}
                ref={inputRef}
                required={Boolean(rules?.required)}
                size="small"
                sx={{
                  svg: { color: 'primary.main' },
                }}
                value={textFieldValue}
                // https://mui.com/material-ui/react-text-field/#helper-text
                {...(shouldShowHelperText && {
                  helperText: (error?.message && error.message) ?? ' ',
                })}
              />
            );
          }}
          value={isRange ? dateRange : date}
          {...field}
          {...rest}
        />
      </LocalizationProvider>

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </>
  );
}
