import { ChangeEvent, MouseEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { Button, ButtonProps } from '@mui/material';

type UploadButtonOwnProps = {
  fileType?: string;
  isMultiple?: boolean;
  label?: string;
};
type UploadButtonProps = Omit<ButtonProps, 'defaultValue'> & UploadButtonOwnProps;

export function UploadButton<T extends FieldValues>({
  control,
  disabled,
  fileType = '*',
  isMultiple = false,
  label = 'upload',
  name,
  rules,
  size = 'small',
  variant = 'outlined',
}: UploadButtonProps & Omit<UseControllerProps<T>, 'defaultValue'>) {
  const {
    field: { onChange, ref },
  } = useController({
    control,
    name,
    rules,
  });

  function handleChangeFile({ target: { files } }: ChangeEvent<HTMLInputElement>) {
    const value = isMultiple ? files : files?.[0];

    if (value) onChange(value);
  }

  function handleResetTargetValue(e: MouseEvent<HTMLInputElement>) {
    // This will reset the input's value and trigger
    // the onChange event even if the same file is selected
    e.currentTarget.value = null as any;
  }

  return (
    <Button component="label" disabled={disabled} fullWidth size={size} variant={variant}>
      {label}

      <input
        accept={fileType}
        hidden
        multiple={isMultiple}
        onChange={handleChangeFile}
        onClick={handleResetTargetValue}
        ref={ref}
        type="file"
      />
    </Button>
  );
}
