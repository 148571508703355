import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { Badge, IconButton } from '@mui/material';

import { deleteBookmark, getBookmarks } from '../../../../store/bookmarksSlice';
import { useAppDispatch, useAppSelector, usePreference } from '../../../../utilities/hooks';
import { SubMenu, SubMenuItemType } from '../../../SubMenu';
import { WaveIcon } from '../../../WaveIcon';

export function Bookmarks() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const bookmarks = useAppSelector((state) => state.bookmarks);
  const items = useMemo<SubMenuItemType[]>(() => {
    return bookmarks.map((bookmark) => {
      return {
        code: bookmark['code'],
        delete: true,
        link: bookmark['link'],
        onClick: () => {
          dispatch(
            deleteBookmark({
              jobId: bookmark['jobid'],
            }),
          );
        },
        text: bookmark['text'],
      };
    });
  }, [bookmarks.length]);

  const [clientIdPreference] = usePreference('sys.mid', '');
  const dispatch = useAppDispatch();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  function handleClickBookmarksIcon(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);

    // Delay before opening submenu to allow the width to be populated
    setTimeout(function () {
      return setIsSubMenuOpen(true);
    }, 200);
  }

  function handleCloseBookmarks() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && bookmarks.length === 0) {
      setAnchorEl(null);
      setIsSubMenuOpen(false);
    }

    return () => {
      isMounted = false;
    };
  }, [bookmarks.length]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(getBookmarks());
    }

    return () => {
      isMounted = false;
    };
  }, [clientIdPreference.value]);

  return bookmarks.length > 0 ? (
    <>
      <IconButton
        className={isSubMenuOpen ? 'secondary-bg' : ''}
        color={isSubMenuOpen ? 'primary' : 'secondary'}
        onClick={handleClickBookmarksIcon}
        size="large"
      >
        <Badge badgeContent={bookmarks.length} color="info">
          <WaveIcon code="bookmark" />
        </Badge>
      </IconButton>

      {isSubMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          className="filler-bg mt-10"
          items={items}
          onClose={handleCloseBookmarks}
          open={isSubMenuOpen}
        />
      )}
    </>
  ) : null;
}
