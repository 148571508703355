import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';

type BreadCrumbItemProps = {
  name: string;
  isLink: boolean;
  link?: string;
  code?: string;
};

export function BreadCrumbItem({ code, isLink, link, name }: BreadCrumbItemProps) {
  const { t } = useTranslation();

  const translation = code ? t(code, name) : name;

  return (
    <>
      {isLink && link ? (
        <NavLink to={link}>{translation}</NavLink>
      ) : (
        <Typography color="textPrimary">{translation}</Typography>
      )}
    </>
  );
}
