import { Container } from '@mui/material';

import { Filters } from '../../features/JobsTable/components/Filters';
import { ActionBar } from '../../features/JobsTable/components/ActionBar';
import { JobsTable } from '../../features/JobsTable';

import { useJobsTable } from '../../features/JobsTable/utilities/hooks';

export function SubJobs() {
  const {
    areActiveFiltersVisible,
    columns,
    confirmationDialogOpen,
    deadlines,
    deleteJob,
    deleteJobID,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleCloseConfirmationDialog,
    handleOpenConfirmationDialog,
    handleToggleSelectAllRows,
    idsOfExpandedSubJobs,
    jobIdsWithSubJobs,
    loadingJobs,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setIdsOfExpandedSubJobs,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobsTable();
  return (
    <>
      <Container className="py-5" maxWidth={false}>
        <ActionBar
          areActiveFiltersVisible={areActiveFiltersVisible}
          onChangePageSearch={handleChangePageSearch}
          searchTerm={searchTerm}
          transferSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          onSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <JobsTable
        columns={columns}
        deadlines={deadlines}
        idsOfExpandedSubJobs={idsOfExpandedSubJobs}
        jobIdsWithSubJobs={jobIdsWithSubJobs}
        loadingJobs={loadingJobs}
        onSetPageHistory={setPageHistory}
        onSetSelectedRows={setSelectedRows}
        rows={rows}
        selectedRows={selectedRows}
        totalRowCount={totalRowCount}
        transferClickRowCheckbox={handleClickRowCheckbox}
        transferOpenConfirmationDialog={handleOpenConfirmationDialog}
        transferSetIDsOfExpandedSubJobs={setIdsOfExpandedSubJobs}
        transferToggleSelectAllRows={handleToggleSelectAllRows}
      />
    </>
  );
}
