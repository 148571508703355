import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { Box, LinearProgress, LinearProgressProps } from '@mui/material';

import { Typography } from 'src/components/Typography';

type ProgressBarProps = Omit<LinearProgressProps, 'defaultValue'>;

export function ProgressBar<T extends FieldValues>({
  control,
  name,
  rules,
}: ProgressBarProps & Omit<UseControllerProps<T>, 'defaultValue'>) {
  const {
    field: { value = 0 },
  } = useController({
    control,
    name,
    rules,
  });

  const roundedValue = Math.round(value);

  return (
    <Box alignItems="center" display="flex" gap={1} width="100%">
      <LinearProgress
        color="primary"
        sx={{ borderRadius: 2.5, height: 8, width: '100%' }}
        value={roundedValue}
        variant="determinate"
      />

      <Typography
        color="text.primary"
        minWidth={35}
        variant="body2"
      >{`${roundedValue}%`}</Typography>
    </Box>
  );
}
