import { useTranslation } from 'react-i18next';

import { FileIds } from 'src/features/JobFiles/JobFiles';
import {
  FolderCode,
  useGetActionsQuery,
  useLazyGetDownloadUrlQuery,
} from 'src/features/JobFiles/JobFiles.service';
import { useAddShoppingCartItemMutation } from 'src/features/ShoppingCart/ShoppingCart.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useAppSelector, useRouteParams } from 'src/utilities/hooks';

type useActionBarProps = {
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFileIds: FileIds;
  selectedFolderCode: FolderCode;
};

export function useActionBar({
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: useActionBarProps) {
  const { t } = useTranslation();
  const { age, jobId, jobType } = useRouteParams();
  const [addShoppingCartItem] = useAddShoppingCartItemMutation();

  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.details.id);

  const { data: actions, isFetching } = useGetActionsQuery(
    { age, folderCode: selectedFolderCode, jobId, jobType, userId },
    { skip: !selectedFolderCode },
  );

  const [downloadFiles] = useLazyGetDownloadUrlQuery();

  function handleActions(code: string) {
    if (code === 'download' || code === 'download_with_annotations') handleDownloadFiles();

    if (code === 'add-to-cart') handleAddShoppingCartItem();
  }

  function handleAddShoppingCartItem() {
    addShoppingCartItem({
      fileIds: selectedFileIds,
      type: selectedFolderCode,
    })
      .unwrap()
      .then(() => {
        const successMessage =
          `${selectedFileIds.length} ` +
          (selectedFileIds.length > 1 ? 'files were ' : 'file was ') +
          'successfully added to your shopping cart';

        onToggleSelectAll(false);

        dispatch(
          openWaveSnack({
            message: successMessage,
            type: 'success',
          }),
        );
      });
  }

  function handleDownloadFiles() {
    downloadFiles({
      fileIds: selectedFileIds,
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        onToggleSelectAll(false);
        dispatch(
          openWaveSnack({
            message: t('download_successful_content', 'Download was successful'),
            type: 'success',
          }),
        );
      });
  }

  return {
    actions,
    handleActions,
    isFetching,
  };
}
