import React, { useMemo } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  CircularProgress,
  TextField,
} from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { Option } from 'src/pages/Job/Job.service';
import { usePreference } from 'src/utilities/hooks';
import { ListboxComponent } from './VirtualList';

import { AutocompleteProps } from './types';

export function SimpleAutocomplete<
  T extends string | Option,
  P extends FieldValues,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  control,
  description,
  disabled,
  isError = false,
  isFetching = false,
  label,
  name,
  onChange,
  onChangeLogicBuilderField,
  onChangeSteeredField,
  options,
  rules,
  shouldShowHelperText,
  shouldVirtualize,
  ...rest
}: Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'defaultValue' | 'renderInput'
> &
  Omit<UseControllerProps<P>, 'defaultValue'> &
  AutocompleteProps<T>) {
  const [showTooltipsPreference] = usePreference('job.feldtips', 'Y');

  const {
    field: { onChange: onFieldChange, ref, value: reactHookFormValue, ...field },
    fieldState: { error },
  } = useController<P>({
    control,
    name,
    rules,
  });

  const memoizedOptions = useMemo(() => {
    if (
      reactHookFormValue !== null &&
      reactHookFormValue !== undefined &&
      reactHookFormValue !== 0 &&
      !options.some(
        (option) =>
          isOption(option) &&
          (option.value === (reactHookFormValue as T).toString() ||
            option.value === (reactHookFormValue as Option).value),
      )
    ) {
      return [
        {
          ...(!isOption(reactHookFormValue)
            ? {
                label: (reactHookFormValue as T).toString(),
                value: (reactHookFormValue as T).toString(),
              }
            : reactHookFormValue),
        },
        ...options,
      ].filter((option) => (option as Option).value !== '') as T[];
    }

    return (options || []).filter((option) => (option as Option).value !== '') as T[];
  }, [options]);

  const value = getValueMapper(reactHookFormValue);

  function getValueMapper(value: string | number | Option | string[] | null): Option | null {
    if (value === null || value === undefined || value === 0 || value === '') {
      return null;
    }

    if (isOption(value)) {
      return value;
    }

    return {
      label: getValueLabel(value.toString())?.label || value.toString(),
      value: value.toString(),
    };
  }

  function getValueLabel(value: string): Option | undefined {
    return memoizedOptions.find((option) => value.toString() === (option as Option).value) as
      | Option
      | undefined;
  }

  function isOption(param: unknown): param is Option {
    return Boolean((param as Option).value !== undefined && (param as Option).label !== undefined);
  }

  function getOptionLabel(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.label : option.toString();
  }

  function getOptionValue(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.value.toString() : option.toString();
  }

  return (
    <>
      <MuiAutocomplete
        disabled={disabled || isFetching}
        disableListWrap
        fullWidth
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
        onChange={(e, value, reason, details) => {
          onFieldChange(value);

          if (onChange) {
            onChange(e, value, reason, details);
          }

          if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);

          if (onChangeSteeredField) onChangeSteeredField(name);
        }}
        options={memoizedOptions}
        {...(shouldVirtualize && {
          ListboxComponent: ListboxComponent,
          renderOption: (props, option, state) => [props, option, state.index] as React.ReactNode,
        })}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error || isError}
            inputProps={{
              ...params.inputProps,
              required: !!rules?.required && (value as unknown as string)?.length === 0,
            }}
            inputRef={ref}
            label={label}
            required={!!rules?.required}
            // https://mui.com/material-ui/react-text-field/#helper-text
            {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
          />
        )}
        size="small"
        value={
          value as unknown as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | undefined
        }
        {...(isFetching && { popupIcon: <CircularProgress size={24} /> })}
        {...field}
        {...rest}
      />

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </>
  );
}
