import { css, Paper, styled } from '@mui/material';

import { StatusIndicatorSizeType } from 'src/components/StatusIndicator/StatusIndicator';
import { getPaletteColorForJobType } from 'src/components/WaveTheme/utils';

type StyledStatusIndicatorProps = {
  jobType?: string;
  size: StatusIndicatorSizeType;
  isActive?: boolean;
  isLighter?: boolean;
  hasElevation: boolean;
};

const extraSmallStyle = css`
  width: 20px;
  height: 20px;
`;

const smallStyle = css`
  width: 25px;
  height: 25px;
`;

const mediumStyle = css`
  width: 32px;
  height: 32px;
`;

const largeStyle = css`
  width: 40px;
  height: 40px;
`;

const noElevationStyle = css`
  box-shadow: none;
`;

const nonForwardedProps = ['jobType', 'isActive', 'isLighter', 'hasElevation'];

export const StyledStatusIndicator = styled(Paper, {
  shouldForwardProp: (prop) => typeof prop === 'string' && !nonForwardedProps.includes(prop),
})<StyledStatusIndicatorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ jobType, theme }) => getPaletteColorForJobType(theme, jobType).main};
  color: ${({ theme }) => theme.palette.common.white};
  overflow: hidden;

  ${({ hasElevation }) => !hasElevation && noElevationStyle};
  ${({ size }) => size === 'extraSmall' && extraSmallStyle}
  ${({ size }) => size === 'small' && smallStyle}
  ${({ size }) => size === 'medium' && mediumStyle}
  ${({ size }) => size === 'large' && largeStyle}
  ${({ isActive, isLighter, jobType, theme }) =>
    isActive !== undefined &&
    !isLighter &&
    css`
      background-color: ${getPaletteColorForJobType(theme, jobType)[isActive ? 'dark' : 'main']};
    `}
  ${({ isActive, isLighter, jobType, theme }) =>
    isActive !== undefined &&
    isLighter &&
    css`
      background-color: ${getPaletteColorForJobType(theme, jobType)[isActive ? 'main' : 'lighter']};
    `}
`;
