import { createApi } from '@reduxjs/toolkit/query/react';

import { ApprovalUsers } from 'src/features/Approvals/Approvals.service';
import {
  BaseRequest,
  BaseRequestAndJobId,
  FieldsTransformed,
  FieldValues,
  JobId,
  JobIdBackendRequired,
  JobIdRequired,
  JobIdValue,
  Options,
  transformFields,
} from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

type Actions = { code: string; text: string }[];
type AddBookmarkRequest = { keyword: string } & BaseRequestAndJobId;
export type AdditionActionTransformed = Omit<StepTransformed, 'status'> & { status: string };
export type ApprovalSection = {
  code?: string;
  name: string;
  templates: ApprovalTemplate[];
};
export type ApprovalTemplate = {
  checked: boolean;
  event_id: number;
  name: string;
  revisors: (ExternalUserRevisor | GroupRevisor | UserRevisor)[];
};
export type ApprovalTemplateActions = [
  { code: 'reset'; text: string },
  { code: 'add'; text: string },
];
type BaseGroupRevisor = {
  confirm: 'all' | 'one';
  group: string;
  group_id: number;
  users: ApprovalUsers;
} & BaseRevisor;
type BaseRevisor = {
  ad_hoc: boolean;
  checkbox: { checked: boolean };
  duration: number;
  email_attachments: string[];
  email_template_id: string;
  event_action_id: number;
  invited: boolean;
  position: number;
  send_email: boolean;
  task: string;
};
type BaseRequestWithoutAge = Omit<BaseRequestAndJobId, 'age'>;
type BaseUserRevisor = {
  user_id: number;
  username: string;
} & BaseRevisor;
type ChangeLogicBuilderFieldRequest = {
  action: string;
  changedFieldAlias: string;
  code: string;
  event: string;
  values: FieldValues;
} & BaseRequestAndJobId;
export type ChangeStatusRequest = {
  amendmentReasons?: string[];
  approval?: StartApprovalData;
  comment?: string;
  excludedActions?: number[];
  status: number;
  tasks?: StartTasks;
  closeTasks?: number[];
} & StepId &
  BaseRequestAndJobId;
type CloseTasks = {
  tasks: CloseTasksTransformed;
};
type CloseTasksTransformed = {
  code: number;
  name: string;
}[];

export type CreateCopyButton = CopyToButton | CopyInProjectButton | NewJobButton;

type CopyToButton = {
  code: 'copy-to';
  subItems: Record<string, string>;
  text: string;
  data: {
    origin_jobid: string | number;
    origin_src: string;
  };
};

export type CopyInProjectButton = {
  data: { jobid: string | number; src: string };
  subItems: Record<string, string>;
  text: string;
  code: 'copy-in-project';
};

type NewJobButton = {
  text: string;
  subItems: Record<string, string>;
  data: {};
  code: 'new-job';
};

type CreateJobRequest = {
  formValues: FieldValues;
  parentJobId?: JobIdValue;
  parentJobType?: string;
} & BaseRequest;
type DeleteBookmarkRequest = JobId;
type EmailNotifications = {
  emails: (Omit<EmailNotificationTransformed, 'eventActionId'> & { event_action_id: number })[];
};
export type EmailNotificationsTransformed = EmailNotificationTransformed[];
type EmailNotificationTransformed = {
  checkbox: { checked: boolean; disabled: boolean };
  eventActionId: number;
  group: string;
  role: string;
  type: 'email_gru' | 'email_gruasrole' | 'email_rol' | 'email_usr';
  username: string;
  users: Record<string, string>;
};
// TODO: More specific types e.x., group is only needed when type is 'email_gru' | 'email_gruasrole'
export type ExternalUserRevisor = { email: string; type: 'email_ext' } & BaseRevisor;
export type Flag = {
  code: FlagCode;
  domain: string;
  text: string;
};
type FlagCode = 'cancel' | 'onhold' | 'resume' | 'revive';
type Flags = Flag[];
type GetCloseTasksRequest = BaseRequest & StepId;
type GetOptionsRequest = Pick<Flag, 'domain'>;
type GetStartTasksDataRequest = BaseRequestWithoutAge & StepId;
type GroupOnlyRevisor = { type: 'email_gru' } & BaseGroupRevisor;
export type GroupRevisor = GroupOnlyRevisor | GroupRoleRevisor;
type GroupRoleRevisor = { role: string; type: 'email_gruasrole' } & BaseGroupRevisor;
type HelpTableOption = { id: number; value: string };
type RelatedJob = { code: string; text: string } & JobIdBackendRequired;
type RelatedJobsTransformed = (Omit<RelatedJob, 'jobid'> & JobId)[];
export type StartApprovalData = {
  actions: ApprovalTemplateActions;
  code: string;
  sections: ApprovalSection[];
  type: string;
};
export type StartTask = {
  event_id: number;
  comment?: string;
  name: string;
  revisors: (ExternalUserRevisor | GroupRevisor | UserRevisor)[];
};
export type StartTasks = StartTask[];
type StepType = 'step' | 'action' | 'link' | null;
type StepLink = {
  url: string;
  target: string;
};
type Step = {
  display: number;
  id: number;
  name: string;
  required_fields: string[];
  step_flags: StepFlags;
  tooltip: string;
  type: StepType;
  link: StepLink;
};
type StepFlags = (
  | 'amendment_request'
  | 'archive'
  | 'close_task'
  | 'email_notifications'
  | 'show_dialog'
  | 'skip_dialog'
  | 'start_approval'
  | 'start_task'
  | 'skip_comments'
)[];
type StepId = { stepId: number };

type Steps = {
  additional_actions?: (Omit<Step, 'display'> & { display: string })[];
  step_changes: Step[];
};
type StepsTransformed = {
  additionalActions: AdditionActionTransformed[];
  steps: StepTransformed[];
};
export type StepTransformed = Omit<Step, 'display' | 'required_fields' | 'step_flags'> & {
  requiredFields: string[];
  status: number;
  stepFlags: StepFlags;
};
type Task = {
  button_items: TaskAction[];
  code: string;
  text: string;
};
type TaskAction = {
  code: TaskActionCode;
  text: string;
  is_flag_comment: boolean;
};
export type TaskActionCode = 'flag-conditional' | 'flag-amendment' | 'flag-approve';
export type TaskActionTransformed = Omit<TaskAction, 'is_flag_comment'> & {
  isFlagComment: boolean;
};
type Tasks = Task[];
export type TaskTransformed = Omit<Task, 'button_items'> & { taskActions: TaskActionTransformed[] };
type TasksTransformed = TaskTransformed[];
type UpdateFlagRequest = BaseRequestWithoutAge & {
  comment?: string;
  flag: FlagCode;
  reason?: string[];
};
type UpdateJobRequest = BaseRequestAndJobId & {
  formValues: FieldValues;
  isBulkEdit?: boolean;
  webStatus?: string;
};
type UpdateJobResponse = JobIdBackendRequired;
export type UpdateJobTransformed = JobIdRequired;
type UpdateTaskRequest = {
  action: string;
  code: string;
  comment: string;
  jobStatus: string;
} & BaseRequestAndJobId;
type UserOnlyRevisor = { type: 'email_usr' } & BaseUserRevisor;
export type UserRevisor = UserOnlyRevisor | UserRoleRevisor;
type UserRoleRevisor = { role: 'string'; type: 'email_rol' } & BaseUserRevisor;
export type VisibilityOfSections = Record<string, 'hide' | 'show'>;
export type Preview = {
  file_id?: string | number | null;
  url: string;
};
export type CascadingSelectionRequest = {
  child: string;
  id?: number;
  parents: Array<{
    alias: string;
    value?: string | number | boolean | null | Preview | Tasks | Flags;
  }>;
};
export type ColorsColumn = {
  field: string;
  title: string;
};
type ColorsData = {
  columns: Array<ColorsColumn>;
  count: number;
  rows: Array<{ name: string; book: string; description: string }>;
};

export const jobFormApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addBookmark: build.mutation<{ message: string }, AddBookmarkRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Actions']),
      query({ age, jobId, jobType, keyword }) {
        return {
          data: {
            age,
            jobid: jobId,
            keyword,
            src: jobType,
          },
          method: 'POST',
          url: '/bookmarks/set',
        };
      },
    }),
    changeLogicBuilderField: build.mutation<
      { fields: FieldsTransformed; sections: VisibilityOfSections; values: FieldValues },
      ChangeLogicBuilderFieldRequest
    >({
      query({ action, age, changedFieldAlias, code, event, jobId, jobType, values }) {
        return {
          data: {
            action,
            age,
            code,
            event,
            field_changed: changedFieldAlias,
            jobid: jobId,
            src: jobType,
            values,
          },
          method: 'POST',
          url: `/logicbuilder/${jobId}/change`,
        };
      },
      transformResponse({ fields, sections, values }) {
        return { fields: transformFields(fields), sections, values };
      },
    }),
    changeStatus: build.mutation<{ message: string }, ChangeStatusRequest>({
      invalidatesTags: ['Steps', 'Tasks'],
      query({
        age,
        amendmentReasons,
        approval,
        closeTasks,
        comment,
        excludedActions,
        jobId,
        jobType,
        status,
        stepId,
        tasks,
      }) {
        return {
          data: {
            age,
            jobid: jobId,
            src: jobType,
            step_id: stepId,
            webstatus: status,
            ...(!!amendmentReasons && { amendment_reasons: amendmentReasons }),
            ...(!!approval && { approval }),
            ...(!!comment && { message: comment }),
            ...(!!tasks?.length && { tasks }),
            ...(!!closeTasks?.length && { close_tasks: closeTasks }),
            ...(!!excludedActions?.length && { excluded_actions: excludedActions }),
          },
          method: 'POST',
          url: '/stepchange/save',
        };
      },
    }),
    createJob: build.mutation<JobIdRequired, CreateJobRequest>({
      query({ age, formValues, jobType, parentJobId, parentJobType }) {
        return {
          data: {
            age,
            assign_jobid: parentJobId,
            assign_src: parentJobType,
            src: jobType,
            val: formValues,
          },
          method: 'POST',
          url: '/jobs/create',
        };
      },
    }),
    deleteBookmark: build.mutation<{ message: string }, DeleteBookmarkRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Actions']),
      query({ jobId }) {
        return {
          method: 'DELETE',
          params: {
            jobid: jobId,
          },
          url: '/bookmarks/delete',
        };
      },
    }),
    getActions: build.query<Actions, BaseRequestAndJobId>({
      providesTags: ['Actions'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/getactions',
        };
      },
    }),
    getBooks: build.query<Array<string>, void>({
      query() {
        return {
          method: 'GET',
          url: '/mdr/books',
        };
      },
      transformResponse(res: Record<string, string>) {
        return Object.values(res);
      },
    }),
    getCloseTasks: build.query<CloseTasksTransformed, GetCloseTasksRequest>({
      query({ age, jobType, stepId }) {
        return {
          method: 'GET',
          params: { age, src: jobType, step_id: stepId },
          url: 'stepchange/loadclosetask',
        };
      },
      transformResponse({ tasks }: CloseTasks) {
        return tasks;
      },
    }),
    getColors: build.query<ColorsData, void>({
      query() {
        return {
          method: 'GET',
          url: '/mdr/colors',
        };
      },
    }),
    getCreateCopyToItems: build.query<CreateCopyButton[], BaseRequestAndJobId>({
      providesTags: ['Create/Copy'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/actions/new-copy',
        };
      },
    }),
    getEmailNotifications: build.query<
      EmailNotificationsTransformed,
      BaseRequestWithoutAge & StepId
    >({
      query({ jobId, jobType, stepId }) {
        return {
          method: 'GET',
          params: { jobid: jobId, src: jobType, step_id: stepId },
          url: '/stepchange/emailnotification',
        };
      },
      transformResponse({ emails }: EmailNotifications) {
        return emails.map(
          ({ checkbox, event_action_id: eventActionId, group, role, type, username, users }) => ({
            checkbox,
            eventActionId,
            group,
            role,
            type,
            username,
            users,
          }),
        );
      },
    }),
    getFlags: build.query<Flags, BaseRequestAndJobId>({
      providesTags: ['Flags'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/getflagbuttons',
        };
      },
    }),
    getOptions: build.query<Options, GetOptionsRequest>({
      query({ domain }) {
        return {
          method: 'GET',
          params: { domain },
          url: '/helptable/items',
        };
      },
      transformResponse(response: HelpTableOption[]) {
        return response.map(({ id, value }) => ({ label: value, value: id }));
      },
    }),
    getRelatedJobs: build.query<RelatedJobsTransformed, BaseRequestAndJobId>({
      providesTags: ['RelatedJobs'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, src: jobType },
          url: `/jobs/${jobId}/related-jobs`,
        };
      },
      transformResponse(response: RelatedJob[]) {
        return response.map(({ jobid: jobId, ...rest }) => ({ jobId, ...rest }));
      },
    }),
    getStartApprovalData: build.query<StartApprovalData, BaseRequestAndJobId & StepId>({
      query({ age, jobId, jobType, stepId }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType, step_id: stepId },
          url: '/stepchange/startapprovalloopdialog',
        };
      },
    }),
    getStartTasksData: build.query<StartTasks, GetStartTasksDataRequest>({
      query({ jobId, jobType, stepId }) {
        return {
          method: 'GET',
          params: { jobid: jobId, src: jobType, step_id: stepId },
          url: '/stepchange/loadtaskdata',
        };
      },
      transformResponse({ tasks }: { tasks: StartTasks }) {
        return tasks;
      },
    }),
    getSteps: build.query<StepsTransformed, BaseRequestAndJobId>({
      providesTags: ['Steps'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, src: jobType },
          url: `/jobs/${jobId}/steps`,
        };
      },
      transformResponse({ additional_actions: additionalActions, step_changes: steps }: Steps) {
        return {
          additionalActions: additionalActions
            ? additionalActions.map(
                ({
                  display: status,
                  required_fields: requiredFields,
                  step_flags: stepFlags,
                  ...rest
                }) => ({
                  requiredFields,
                  status,
                  stepFlags,
                  ...rest,
                }),
              )
            : [],
          steps: steps.map(
            ({
              display: status,
              required_fields: requiredFields,
              step_flags: stepFlags,
              ...rest
            }) => ({
              requiredFields,
              status,
              stepFlags,
              ...rest,
            }),
          ),
        };
      },
    }),
    getTasks: build.query<TasksTransformed, BaseRequestAndJobId>({
      providesTags: ['Tasks'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/gettasks',
        };
      },
      transformResponse(tasks: Tasks) {
        return tasks.map(({ button_items: taskActions, ...rest }) => ({
          ...rest,
          taskActions: taskActions.map(({ is_flag_comment: isFlagComment, ...rest }) => ({
            ...rest,
            isFlagComment,
          })),
        }));
      },
    }),
    steerCascadingSelection: build.mutation<
      Array<{ label: string; value: string }>,
      CascadingSelectionRequest
    >({
      query(params) {
        return {
          method: 'POST',
          params,
          url: '/fields/steer/values',
        };
      },
    }),
    updateFlag: build.mutation<{ message: string }, UpdateFlagRequest>({
      query({ comment, flag, jobId, jobType, reason }) {
        return {
          data: {
            flag,
            jobid: jobId,
            src: jobType,
            ...(!!comment && { message: comment }),
            ...(!!reason && { subject: reason }),
          },
          method: 'POST',
          url: '/jobs/addflag',
        };
      },
    }),
    updateJob: build.mutation<UpdateJobTransformed, UpdateJobRequest>({
      query({ age, formValues, isBulkEdit, jobId, jobType, webStatus }) {
        return {
          data: {
            jobid: jobId,
            src: jobType,
            val: formValues,
            ...(isBulkEdit && { age: 'job', bulk_edit: 1, force_update: 1 }),
            ...(!isBulkEdit && { age, webstatus: webStatus }),
          },
          method: 'PUT',
          url: '/jobs',
        };
      },
      transformResponse({ jobid: jobId }: UpdateJobResponse) {
        return { jobId };
      },
    }),
    updateTask: build.mutation<{ message: string }, UpdateTaskRequest>({
      query({ action, age, code, comment, jobId, jobStatus, jobType }) {
        return {
          data: {
            age,
            comment,
            jobid: jobId,
            src: jobType,
            type: code,
            vote: action,
            webstatus: jobStatus,
          },
          method: 'POST',
          url: '/jobflagtask/update',
        };
      },
    }),
  }),
  reducerPath: 'jobFormApi',
  tagTypes: ['Actions', 'Create/Copy', 'Flags', 'Steps', 'RelatedJobs', 'Tasks'],
});

export const {
  useAddBookmarkMutation,
  useChangeLogicBuilderFieldMutation,
  useChangeStatusMutation,
  useCreateJobMutation,
  useDeleteBookmarkMutation,
  useGetActionsQuery,
  useGetBooksQuery,
  useGetCloseTasksQuery,
  useGetCreateCopyToItemsQuery,
  useGetEmailNotificationsQuery,
  useGetFlagsQuery,
  useGetOptionsQuery,
  useGetRelatedJobsQuery,
  useGetStartApprovalDataQuery,
  useGetStartTasksDataQuery,
  useGetStepsQuery,
  useGetTasksQuery,
  useLazyGetColorsQuery,
  useSteerCascadingSelectionMutation,
  useUpdateFlagMutation,
  useUpdateJobMutation,
  useUpdateTaskMutation,
} = jobFormApi;
