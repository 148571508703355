import { TableCell } from '@mui/material';

import { Thumbnail } from 'src/components/Thumbnail';

import { useViewer } from 'src/utilities/hooks';
import { StyledLink } from '../Row/styled';

export function PreviewCell({ paddingY, preview, isSubRow, isDense, link, rowJobID }) {
  const { isExternalLinkLoading, setIsExternalLinkLoading, thumbnailContainerClass } = useViewer({
    fileID: preview?.file_id,
    fileJobID: rowJobID,
  });

  function handleClickThumbnailContainer(e) {
    if (preview?.url !== undefined) {
      e.stopPropagation();
      setIsExternalLinkLoading(true);
    }
  }

  return (
    <TableCell
      className={`${
        isSubRow ? 'b-left-off-white b-right-off-white ' : ''
      }${thumbnailContainerClass} no-wrap p-relative text-align-center`}
      onClick={handleClickThumbnailContainer}
      sx={{ paddingY }}
    >
      <StyledLink href={link}>
        {preview?.url !== undefined ? (
          <Thumbnail
            alt={`latest artwork thumbnail for job ${rowJobID}`}
            hasExternalLink
            size={isDense ? 24 : 32}
            isExternalLinkLoading={isExternalLinkLoading}
            shouldRaiseThumbnailErrorOnLoad={false}
            url={preview?.url}
          />
        ) : null}
      </StyledLink>
    </TableCell>
  );
}
