import { BasicAge } from 'src/utilities/hooks/useRouteParams';
import { useRouteParams } from '../../../../utilities/hooks';

export function useIsSubJobs({
  forcedAge,
  forcedJobType,
}: {
  forcedAge?: BasicAge;
  forcedJobType?: string;
}) {
  const { age, jobId, jobType, tab } = useRouteParams({
    forcedAge,
    forcedJobType,
  });

  return { age, isSubJobs: tab === 'sub-jobs', jobId, jobType, tab };
}
