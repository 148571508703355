import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { approvalsApi } from 'src/features/Approvals/Approvals.service';
import { bulkEditJobFieldsApi } from 'src/features/BulkEditJobFields/BulkEditJobFields.service';
import { compareReplaceApi } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import { contentApi } from 'src/features/Content/content.service';
import { avatarApi } from 'src/features/EditableAvatar/EditableAvatar.service';
import { generalFilesApi } from 'src/features/FileExplorer/FileExplorer.service';
import { userMenuApi } from 'src/features/Frame/components/UserMenu/UserMenu.service';
import { historyApi } from 'src/features/History/History.service';
import { jobFilesApi } from 'src/features/JobFiles/JobFiles.service';
import { translationApi } from 'src/features/JobForm/components/TranslationDialog/Translation.service';
import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { jobsTableApi } from 'src/features/JobsTable/JobsTable.service';
import { shoppingCartApi } from 'src/features/ShoppingCart/ShoppingCart.service';
import { uploadFilesApi } from 'src/features/UploadFiles/components/Dialog/Dialog.service';
import { passwordApi } from 'src/features/UserDetails/components/ChangePasswordDialog/ChangePassword.service';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { assignToProjectApi } from 'src/pages/AssignToProject/AssignToProject.service';
import { compareFilesApi } from 'src/pages/CompareFiles/CompareFiles.service';
import { fileSearchApi } from 'src/pages/FileSearch/FileSearch.service';
import { iFrameApi } from 'src/pages/GenericIFrame/IFrame.service';
import { jobApi } from 'src/pages/Job/Job.service';
import { userProfileApi } from 'src/pages/UserProfile/UserProfile.service';
import { userSettingsApi } from 'src/pages/UserSettings/UserSettings.service';
import { viewerCallbackApi } from 'src/pages/ViewerCallback/ViewerCallback.service';
import { externalRevisorApi } from 'src/routes/ExternalRevisor.service';
import bookmarksReducer from 'src/store/bookmarksSlice';
import fileSearchSliceReducer from 'src/store/fileSearchSlice';
import userReducer from 'src/store/userSlice';
import waveSnackReducer, { openWaveSnack } from 'src/store/waveSnackSlice';

function rtkQueryErrorLogger({ dispatch }: MiddlewareAPI): ReturnType<Middleware> {
  return (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.meta.arg.type === 'query' && action.payload.code === 403) {
        return next(action);
      }

      dispatch(
        openWaveSnack({
          message: action.payload.message,
          type: 'error',
        }),
      );
    }

    return next(action);
  };
}

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      approvalsApi.middleware,
      assignToProjectApi.middleware,
      avatarApi.middleware,
      bulkEditJobFieldsApi.middleware,
      compareFilesApi.middleware,
      compareReplaceApi.middleware,
      contentApi.middleware,
      externalRevisorApi.middleware,
      fileSearchApi.middleware,
      generalFilesApi.middleware,
      historyApi.middleware,
      iFrameApi.middleware,
      jobApi.middleware,
      jobFilesApi.middleware,
      jobFormApi.middleware,
      jobsTableApi.middleware,
      passwordApi.middleware,
      shoppingCartApi.middleware,
      translationApi.middleware,
      uploadFilesApi.middleware,
      userMenuApi.middleware,
      userProfileApi.middleware,
      userSettingsApi.middleware,
      workflowApi.middleware,
      viewerCallbackApi.middleware,
    ]),
  reducer: {
    bookmarks: bookmarksReducer,
    fileSearch: fileSearchSliceReducer,
    user: userReducer,
    waveSnack: waveSnackReducer,
    [approvalsApi.reducerPath]: approvalsApi.reducer,
    [assignToProjectApi.reducerPath]: assignToProjectApi.reducer,
    [avatarApi.reducerPath]: avatarApi.reducer,
    [bulkEditJobFieldsApi.reducerPath]: bulkEditJobFieldsApi.reducer,
    [compareFilesApi.reducerPath]: compareFilesApi.reducer,
    [compareReplaceApi.reducerPath]: compareReplaceApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [externalRevisorApi.reducerPath]: externalRevisorApi.reducer,
    [fileSearchApi.reducerPath]: fileSearchApi.reducer,
    [generalFilesApi.reducerPath]: generalFilesApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [iFrameApi.reducerPath]: iFrameApi.reducer,
    [jobApi.reducerPath]: jobApi.reducer,
    [jobFilesApi.reducerPath]: jobFilesApi.reducer,
    [jobFormApi.reducerPath]: jobFormApi.reducer,
    [jobsTableApi.reducerPath]: jobsTableApi.reducer,
    [passwordApi.reducerPath]: passwordApi.reducer,
    [shoppingCartApi.reducerPath]: shoppingCartApi.reducer,
    [translationApi.reducerPath]: translationApi.reducer,
    [uploadFilesApi.reducerPath]: uploadFilesApi.reducer,
    [userMenuApi.reducerPath]: userMenuApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    [workflowApi.reducerPath]: workflowApi.reducer,
    [viewerCallbackApi.reducerPath]: viewerCallbackApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
