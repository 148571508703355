import { Fragment, useCallback, useState } from 'react';

import i18next from 'i18next';

import {
  Accordion as MUIAccordion,
  AccordionDetails,
  Box,
  Skeleton,
  TextField as MuiTextField,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { StyledAccordionSummary } from 'src/components/StyledAccordionSummary';
import { CompareReplaceDialog } from 'src/features/CompareReplaceDialog/CompareReplaceDialog';
import { useAccordion } from 'src/features/JobForm/components/Accordion/useAccordion';
import { CopyCompareButton } from 'src/features/JobForm/components/CopyCompareButton';
import { useJobForm } from 'src/features/JobForm/useJobForm';
import { Section } from 'src/pages/Job/JobContext';
import { FieldMapper2 } from '../FieldMapper2';

type AccordionProps = {
  section: Section;
};

export function Accordion({ section }: AccordionProps) {
  const { language } = i18next;
  const { code, fields: fieldLayoutFields, sections, table, title } = section;
  const [compareDialogOpenSection, setCompareDialogOpenSection] = useState('');
  const {
    fields,
    handleChangeLogicBuilderField,
    handleChangeSteeredField,
    handleToggleExpand,
    isJobFetching,
    isSectionExpanded,
  } = useAccordion({ code });
  const { fieldLayout } = useJobForm();

  function handleOnClose() {
    setCompareDialogOpenSection('');
  }

  function handleOnConfirm() {
    setCompareDialogOpenSection('');
  }

  const handleCompareDialogOpen = useCallback((section: string) => {
    setCompareDialogOpenSection(section);
  }, []);

  function renderField(alias: string) {
    const jobField = fields?.[alias];
    const translatedNameField = `name_${language}`;
    const translatedNameDescriptionField = `desc_${language}`;

    if (!jobField) {
      // The field is configured to be on the form but BE isn't returning it.
      return <MuiTextField disabled fullWidth name={alias} placeholder={alias} size="small" />;
    } else {
      const {
        attributes,
        fieldData,
        isDisabled,
        isError,
        isHidden,
        isLabelledText,
        isRawText,
        isRequired,
        param,
        steer,
        type,
        [translatedNameDescriptionField as any]: description,
        [translatedNameField as any]: name,
      } = jobField;

      if (!isHidden) {
        return (
          <FieldMapper2
            alias={alias}
            description={description}
            fieldData={fieldData}
            isDisabled={isDisabled}
            isError={isError}
            isLabelledText={isLabelledText}
            isRawText={isRawText}
            isRequired={isRequired}
            name={name}
            param={param}
            type={type}
            {...(attributes?.classes?.includes('bc-lb') && {
              onChangeLogicBuilderField: handleChangeLogicBuilderField,
            })}
            {...(steer.children && {
              onChangeSteeredField: handleChangeSteeredField,
            })}
          />
        );
      }

      return null;
    }
  }

  return (
    <MUIAccordion expanded={isSectionExpanded}>
      <StyledAccordionSummary onClick={handleToggleExpand}>{title}</StyledAccordionSummary>

      <AccordionDetails sx={{ px: 0 }}>
        <Grid2 container>
          {fieldLayoutFields?.map(({ alias, columns, leftSpan, rightSpan }) => (
            <Fragment key={alias}>
              {leftSpan ? <Grid2 sx={{ p: 1 }} xs={leftSpan} /> : null}

              <Grid2 sx={{ alignItems: 'center', display: 'flex', gap: 1, p: 1 }} xs={columns}>
                {!fields || isJobFetching ? (
                  <Skeleton height={40} variant="rounded" width="100%" />
                ) : (
                  renderField(alias)
                )}
              </Grid2>

              {rightSpan ? <Grid2 sx={{ p: 1 }} xs={rightSpan} /> : null}
            </Fragment>
          ))}

          {table && fields && !isJobFetching ? (
            <Grid2 container>
              <Grid2
                container
                flexDirection="column"
                justifyContent="space-around"
                paddingBottom="17px"
                xs="auto"
              >
                {table.rows.map((row) => (
                  <Grid2 container key={row.title}>
                    {row.fixedFields.map((fieldAlias) => {
                      const jobField = fields?.[fieldAlias];
                      const translatedNameField = `name_${language}`;
                      const translatedNameDescriptionField = `desc_${language}`;
                      const name = jobField?.[translatedNameField as any] || '';
                      const description = jobField?.[translatedNameDescriptionField as any] || '';

                      return (
                        <Grid2 key={fieldAlias} margin={1} width="223px">
                          <FieldMapper2
                            alias={fieldAlias}
                            description={description}
                            fieldData={jobField?.fieldData}
                            isDisabled={jobField?.isDisabled || false}
                            isError={jobField?.isError || false}
                            isRequired={jobField?.isRequired}
                            name={name}
                            type={jobField?.type || ''}
                          />
                        </Grid2>
                      );
                    })}
                  </Grid2>
                ))}
              </Grid2>

              <Grid2 className="scroll-column-shadow" container sx={{ overflow: 'auto' }} xs>
                {table.rows.map((row) => (
                  <Grid2 alignItems="center" container key={row.title} xs="auto">
                    {row.scrollableFields.map((fieldAlias) => {
                      const jobField = fields?.[fieldAlias];
                      const translatedNameField = `name_${language}`;
                      const translatedNameDescriptionField = `desc_${language}`;
                      const name = jobField?.[translatedNameField as any] || '';
                      const description = jobField?.[translatedNameDescriptionField as any] || '';

                      return (
                        <Grid2 key={fieldAlias} margin={1} width="223px">
                          <FieldMapper2
                            alias={fieldAlias}
                            description={description}
                            fieldData={jobField?.fieldData}
                            isDisabled={jobField?.isDisabled || false}
                            isError={jobField?.isError || false}
                            isRequired={jobField?.isRequired}
                            name={name}
                            type={jobField?.type || ''}
                          />
                        </Grid2>
                      );
                    })}
                  </Grid2>
                ))}
              </Grid2>
            </Grid2>
          ) : null}

          {sections?.map((section) => (
            <Accordion key={section.code} section={section} />
          ))}
        </Grid2>

        <Box display="flex" height="30px" justifyContent="end" m={1.5}>
          <CopyCompareButton onOpenCompareDialog={handleCompareDialogOpen} section={section} />
        </Box>

        <CompareReplaceDialog
          compareDialogOpenSection={compareDialogOpenSection}
          fieldLayout={fieldLayout}
          onClose={handleOnClose}
          onConfirm={handleOnConfirm}
        />
      </AccordionDetails>
    </MUIAccordion>
  );
}
