import { Checkbox, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import i18n from 'i18next';

export function FieldSelectorField({
  data: { checkedFields, fieldPreference, fields, onSetCheckedFields, type },
  index,
  style,
}) {
  const field = fields[index];
  const name = field[`name_${i18n.language}` || field.name];
  const indexBased = type === 'selected';

  const checked = checkedFields.some((checkedField) =>
    indexBased ? checkedField === index : checkedField.alias === field.alias,
  );

  function handleToggleField() {
    onSetCheckedFields(
      checkedFields.some((checkedField) =>
        indexBased ? checkedField === index : checkedField.alias === field.alias,
      )
        ? checkedFields.filter((checkedField) =>
            indexBased ? checkedField !== index : checkedField.alias !== field.alias,
          )
        : [...checkedFields, indexBased ? index : field],
    );
  }

  return (
    <ListItem button dense onClick={handleToggleField} style={style}>
      <ListItemIcon>
        <Checkbox checked={checked} color="primary" disabled={fieldPreference.loading} />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
}
