import { useEffect, useState } from 'react';

import axios from 'axios';

import { openWaveSnack } from '../../store/waveSnackSlice';
import { API } from '../api';
import { useAppDispatch, usePreference, useRouteParams } from '.';

type UseViewerProps = {
  fileID?: string | number | null;
  fileJobID?: number;
};

export function useViewer({ fileID, fileJobID }: UseViewerProps) {
  const { age, jobId, jobType } = useRouteParams({
    defaultJobId: fileJobID,
  });

  const dispatch = useAppDispatch();
  const [isExternalLinkLoading, setIsExternalLinkLoading] = useState(false);
  const [viewerPreference] = usePreference('pdf-viewer.open', '_blank');

  const thumbnailContainerClass = `${isExternalLinkLoading ? 'disabled ' : ''}container`; // TODO: replace class with sx

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (isExternalLinkLoading) {
      (async (cancelToken) => {
        await API.get('/jobs/files/view', {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          cancelToken: cancelToken, // @ts-ignore
          onError: ({ message }: { message: string }) => {
            dispatch(
              openWaveSnack({
                message: message,
                type: 'error',
              }),
            );
            setIsExternalLinkLoading(false);
          },
          onSuccess: ({ url }: { url: string }) => {
            setIsExternalLinkLoading(false);
            window.open(
              url,
              `${
                viewerPreference.value === 'self'
                  ? '_self'
                  : viewerPreference.value === 'window'
                  ? ''
                  : '_blank'
              }`,
              `${viewerPreference.value === 'window' ? 'popup' : ''}`,
            );
          },
          params: {
            age,
            file_id: fileID,
            jobid: jobId,
            src: jobType,
          },
        });
      })(source.token);
    }

    return () => {
      source.cancel();
    };
  }, [isExternalLinkLoading]);

  return {
    isExternalLinkLoading,
    setIsExternalLinkLoading,
    thumbnailContainerClass,
  };
}
