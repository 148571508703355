import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment, TextField, Unstable_Grid2 as Grid } from '@mui/material';

import {
  IdsOfExpandedSubJobs,
  SelectedRows,
} from 'src/features/JobsTable/utilities/hooks/useJobsTable';
import { ExpandAllButton } from '../../../../components/ExpandAllButton';
import { usePreference } from '../../../../utilities/hooks';
import { WaveIcon } from '../../../WaveIcon';
import { useGetJobsTableActionsQuery } from '../../JobsTable.service';
import { computeWaveIconCodePrefix } from '../../utilities/helperFunctions';
import { useIsSubJobs } from '../../utilities/hooks';
import { ActionsButton } from '../ActionsButton';
import { ColumnsButton } from '../ColumnsButton';
import { FiltersButton } from '../FiltersButton';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ListViewButton } from '../ListViewButton';
import { NewJobButton } from '../NewJobButton';

type ActionBarProps = {
  areActiveFiltersVisible: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  expandAllSubJobs?: boolean;
  idsOfExpandedSubJobs?: IdsOfExpandedSubJobs;
  jobIdsWithSubJobs?: number[];
  onChangePageSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onSetExpandAllSubJobs?: (arg: boolean) => void;
  searchTerm: string;
  selectedRows: SelectedRows;
  teammateID?: number;
  transferExpandAllSubJobs?: () => void;
  transferSetAreActiveFiltersVisible: (arg: boolean) => void;
  transferSetBulkEditType?: (arg: string) => void;
};
export function ActionBar({
  areActiveFiltersVisible,
  expandAllSubJobs,
  idsOfExpandedSubJobs = [],
  jobIdsWithSubJobs = [],
  onChangePageSearch,
  onSetExpandAllSubJobs,
  searchTerm,
  selectedRows,
  teammateID,
  transferExpandAllSubJobs,
  transferSetAreActiveFiltersVisible,
  transferSetBulkEditType,
}: ActionBarProps) {
  const { t } = useTranslation();
  const [clientIdPreference] = usePreference('sys.mid', '');
  const { age, isSubJobs, jobType, tab } = useIsSubJobs({
    forcedJobType: 'pro',
  });
  const { data: actions, refetch: getJobsTableActionBar } = useGetJobsTableActionsQuery({
    age,
    assign_job: tab === 'assign',
    src: jobType,
    sub_jobs: isSubJobs,
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getJobsTableActionBar();
    }

    return () => {
      isMounted = false;
    };

    // clientIdPreference is a dependency so that the actions are reloaded
    // on the dashboard when switching clients
  }, [age, clientIdPreference.value, jobType]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && age !== 'hom' && !isSubJobs && tab !== 'assign' && onSetExpandAllSubJobs) {
      if (idsOfExpandedSubJobs.length && !expandAllSubJobs) {
        onSetExpandAllSubJobs(idsOfExpandedSubJobs.length === jobIdsWithSubJobs.length);
      } else if (!idsOfExpandedSubJobs.length) onSetExpandAllSubJobs(false);
    }

    return () => {
      isMounted = false;
    };
  }, [idsOfExpandedSubJobs]);

  return (
    <Grid alignItems="center" container justifyContent="space-between">
      <Grid margin={1}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WaveIcon code="search" />
              </InputAdornment>
            ),
          }}
          label={t('page_search_label', 'Page Search')}
          onChange={onChangePageSearch}
          size="small"
          type="search"
          value={searchTerm}
        />
      </Grid>

      {actions?.[0].code === 'filters' ? (
        <Grid>
          <FiltersButton
            areActiveFiltersVisible={areActiveFiltersVisible}
            code={`${computeWaveIconCodePrefix({
              age,
              isSubJobs,
            })}-action-bar-${actions?.[0].code}`}
            onSetAreActiveFiltersVisible={transferSetAreActiveFiltersVisible}
            text="lib.filter"
          />
        </Grid>
      ) : null}

      <Grid container justifyContent={{ lg: 'end' }} lg>
        {actions?.map(({ code, sub_actions: subActions }) => (
          <Grid key={code} margin={1}>
            {/* {code === "list-view-options" && (
              <ListViewButton
                code={`jobs-action-bar-${code}`}
                text={text}
              />
            )} */}

            {code === 'expand-all' &&
            jobIdsWithSubJobs.length &&
            expandAllSubJobs &&
            transferExpandAllSubJobs ? (
              <ExpandAllButton
                collapsedIcon="jobs-action-bar-keyboard-arrow-down"
                expandedIcon="jobs-action-bar-keyboard-arrow-up"
                isExpanded={expandAllSubJobs}
                onExpandClick={transferExpandAllSubJobs}
              />
            ) : null}

            {code === 'new' && (
              <NewJobButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobs,
                })}-action-bar-${code}-job`}
                subActions={subActions}
                text="job.new"
              />
            )}

            {code === 'columns' && (
              <ColumnsButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobs,
                })}-action-bar-${code}`}
                text="lib.columns"
              />
            )}

            {code === 'actions' && (
              <ActionsButton
                code={`${computeWaveIconCodePrefix({
                  age,
                  isSubJobs,
                })}-action-bar-${code}`}
                onSetBulkEditType={transferSetBulkEditType}
                selectedRows={selectedRows}
                subActions={subActions}
                teammateID={teammateID}
                text="lib.actions"
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
