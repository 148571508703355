import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Checkbox } from 'src/components/RHF/Checkbox';
import { DateField } from 'src/components/RHF/DateField';
import { ProgressBar } from 'src/components/RHF/ProgressBar';
import { RichText } from 'src/components/RHF/RichText';
import { TextField } from 'src/components/RHF/TextField';
import { useJobForm } from 'src/features/JobForm/useJobForm';
import { UploadFiles } from 'src/features/UploadFiles';

export type FieldMapper2Props = {
  alias: string;
  description?: string;
  fieldData?: any;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onChangeSteeredField?: (fieldName: string) => void;
  onClickAssociatedCheckbox?: (isChecked: boolean) => void;
  isDisabled: boolean;
  isError?: boolean;
  isRequired?: boolean;
  isLabelledText?: boolean;
  isRawText?: boolean;
  name?: string;
  param?: Record<string, string>;
  type: string;
};

export const SELECTOR_FIELDS = [
  'ccomplete',
  'cselect',
  'gselect',
  'pick',
  'resselect',
  'select',
  'tselect',
  'uselect',
  'valselect',
];

export function FieldMapper2({
  alias,
  description = '',
  fieldData = [],
  isDisabled,
  isError = false,
  isLabelledText = false,
  isRawText = false,
  isRequired,
  name = '',
  onChangeLogicBuilderField,
  onChangeSteeredField,
  onClickAssociatedCheckbox,
  param,
  type,
}: FieldMapper2Props) {
  const { control } = useFormContext();
  const { isNewJob } = useJobForm();
  const { t } = useTranslation();

  const isNumber = type === 'bigint' || type === 'bigintnull' || type === 'int';
  const requiredRule = isRequired
    ? {
        required: {
          message: `${t('lib.required')}`,
          value: isRequired,
          // This value isn't working.
          // Even if you set it to false,
          // it still shows that the field is required.
          // For that reason, I used a ternary operator
          // and return an empty object.
        },
      }
    : {};

  return SELECTOR_FIELDS.includes(type) ? (
    <Autocomplete
      control={control}
      description={description}
      disabled={isDisabled}
      isError={isError}
      label={name}
      multiple={type === 'pick'}
      name={alias}
      onChangeLogicBuilderField={onChangeLogicBuilderField}
      onChangeSteeredField={onChangeSteeredField}
      options={fieldData}
      rules={requiredRule}
      shouldShowHelperText={false}
    />
  ) : type === 'boolean' ? (
    <Checkbox
      control={control}
      description={description}
      disabled={isDisabled}
      isError={isError}
      label={name}
      name={alias}
      onChangeLogicBuilderField={onChangeLogicBuilderField}
      onClickAssociatedCheckbox={onClickAssociatedCheckbox}
      rules={requiredRule}
      shouldShowHelperText={false}
    />
  ) : type === 'date' ? (
    <DateField
      componentsProps={{
        actionBar: {
          actions: ['clear'],
        },
      }}
      control={control}
      description={description}
      disabled={isDisabled}
      isError={isError}
      isRawText={isRawText}
      label={name}
      name={alias}
      onChangeLogicBuilderField={onChangeLogicBuilderField}
      rules={requiredRule}
      shouldShowHelperText={false}
    />
  ) : type === 'file' ? (
    <UploadFiles
      destination={param?.dest as string}
      isDisabled={isDisabled}
      isMultipleFiles={false}
      isNewJob={isNewJob}
      key={name}
      label={name}
      variant="jobForm"
    />
  ) : type === 'pbar' ? (
    <ProgressBar control={control} name={alias} />
  ) : type === 'ean' || type === 'memo' || type === 'string' || type === 'color' || isNumber ? (
    <TextField
      control={control}
      description={description}
      disabled={isDisabled}
      isError={isError}
      isLabelledText={isLabelledText}
      isRawText={isRawText}
      label={name}
      name={alias}
      onChangeLogicBuilderField={onChangeLogicBuilderField}
      rules={requiredRule}
      shouldShowHelperText={false}
      {...(type === 'ean' && { type: 'barcode' })}
      {...(type === 'memo' && { type: 'memo' })}
      {...(type === 'color' && { type: 'core_color' })}
      {...(isNumber && { type: 'number' })}
    />
  ) : type === 'rich' ? (
    <RichText control={control} name={alias} placeholder={name} />
  ) : null;
}
