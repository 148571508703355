import { createApi } from '@reduxjs/toolkit/query/react';

import { JobId } from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';
type BaseParams = {
  age?: ExpandedAges;
  id?: number;
  src?: string;
};
type deleteJobRequest = BaseParams & JobId;
type GetJobsTableActionBarRequest = {
  age?: ExpandedAges;
  assign_job?: boolean;
  sub_jobs?: boolean;
} & BaseParams;

type BaseProps = {
  code?: string;
  text?: string;
};

type Actions = {
  sub_actions: Array<{ code: string; text: string }>;
  subItems: BaseProps[];
} & BaseProps;

export const jobsTableApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    deleteJob: build.mutation<{ message: string }, deleteJobRequest>({
      query(params) {
        return {
          method: 'DELETE',
          params: params,
          url: '/jobs/delete',
        };
      },
    }),
    getJobsTableActions: build.query<Array<Actions>, GetJobsTableActionBarRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/jobs/actionbar',
        };
      },
      transformResponse(response: Array<Actions>): Array<Actions> {
        return [
          response.find((res) => res.code === 'filters'),
          ...response.filter((item) => item.code !== 'filters'),
        ] as Array<Actions>;
      },
    }),
  }),
  reducerPath: 'jobsTableApi',
});

export const { useDeleteJobMutation, useGetJobsTableActionsQuery } = jobsTableApi;
