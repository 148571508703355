import { useState, memo, useCallback } from 'react';

import { AutoComplete } from './components/AutoComplete';
import { Barcode } from './components/Barcode';
import { CheckboxField } from './components/CheckboxField';
import { DateField } from './components/DateField';
import { DateTimeField } from './components/DateTimeField';
import { Hidden } from './components/Hidden';
import { Memo } from './components/Memo';
import { MultipleSelect } from './components/MultipleSelect';
import { ProgressBar } from './components/ProgressBar';
import { Integer } from './components/Integer';
import { RadioField } from './components/RadioField';
import { RichText } from './components/RichText';
import { Selection } from './components/Selection';
import { StandardField } from './components/StandardField';
import { SwitchField } from './components/SwitchField';
import { UploadFiles } from 'src/features/UploadFiles';
import { WaveIcon } from '../WaveIcon';
import { WaveTooltip } from '../../components/WaveTooltip';

import { usePreference } from '../../utilities/hooks';
import { Box, IconButton } from '@mui/material';
import { TranslationDialog } from '../JobForm/components/TranslationDialog';

const FieldMapper = ({
  alias,
  checkRequiredFields,
  description,
  disabled,
  isMultipleFiles,
  label,
  loadingCascadingFields,
  indeterminate,
  isError,
  maxDate,
  minDate,
  newJob,
  onBlur,
  onClick,
  options,
  required,
  selectionMode,
  transferChangeField,
  getFiles,
  type,
  uploadFilesDestination,
  value,
}) => {
  const mapper = {
    ccomplete: AutoComplete,
    color: AutoComplete,
    ean: Barcode,
    boolean: CheckboxField,
    date: DateField,
    datetime: DateTimeField,
    file: UploadFiles,
    hidden: Hidden,
    bigint: Integer,
    int: Integer,
    mediumint: Integer,
    smallint: Integer,
    tinyint: Integer,
    memo: Memo,
    pick: MultipleSelect,
    pbar: ProgressBar,
    radio: RadioField,
    tradio: RadioField,
    rich: RichText,
    cselect: Selection,
    gselect: Selection,
    resselect: Selection, // Resource selection
    select: Selection,
    tselect: Selection, // Options come from help table
    uselect: Selection,
    valselect: Selection, // Options come from al_fie_choice
    image: StandardField, // TBD
    imgpick: StandardField, // TBD
    newpick: StandardField, // TBD
    pickselect: StandardField, // TBD
    string: StandardField,
    switch: SwitchField,
    uploadFiles: UploadFiles,
  };
  const Component = mapper[type ? type : 'string'];

  const [showTooltipsPreference] = usePreference('job.feldtips', 'Y');

  const [isOpen, setIsOpen] = useState(false);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  const memoizedTransferChangeField = useCallback(
    (data) => {
      transferChangeField(data);
    },
    [transferChangeField],
  );

  return (
    <div className="d-flex">
      <Component
        checkRequiredFields={checkRequiredFields}
        disabled={disabled}
        getFiles={getFiles}
        id={alias}
        indeterminate={indeterminate}
        isError={isError}
        label={label}
        loadingCascadingFields={loadingCascadingFields}
        maxDate={maxDate}
        minDate={minDate}
        isMultipleFiles={isMultipleFiles}
        newJob={newJob}
        onBlur={onBlur}
        onChange={memoizedTransferChangeField}
        onClick={onClick}
        options={options}
        required={required}
        selectionMode={selectionMode}
        uploadFilesDestination={uploadFilesDestination}
        value={value}
      />

      <Box display="flex" flexDirection="column" alignSelf="center">
        {description && showTooltipsPreference.value === 'Y' && (
          <Box alignSelf="center" display="flex" ml={1}>
            <WaveTooltip
              body={description}
              component={<WaveIcon code="input-field-information" />}
              placement="top"
              type="simple"
            />
          </Box>
        )}

        {type === 'memo' && import.meta.env.VITE_INSTALLATION === 'hubx' ? (
          <IconButton onClick={handleOpenDialog}>
            <WaveIcon code="job-form-language" color="primary" />
          </IconButton>
        ) : null}
      </Box>

      {isOpen && <TranslationDialog isOpen={isOpen} onSetIsOpen={setIsOpen} value={value} />}
    </div>
  );
};

export const MemoizedFieldMapper = memo(FieldMapper);
