import i18n from 'i18next';

export function changeArrayItemPosition({ array, newPosition, oldPosition }) {
  return array.splice(newPosition, 0, array.splice(oldPosition, 1)[0]);
}

export function evenlyDistributedFilter(arr, maxValue) {
  if (maxValue >= arr.length - 2) {
    return arr;
  } else {
    let newArray = [];
    const firstValue = arr.splice(0, 1).toString();
    const lastValue = arr.splice(-1, 1).toString();
    const interval = arr.length / maxValue;

    for (let i = 0; i < maxValue; i++) {
      // Always add half of interval, so 'picking area' is 'aligned' to the center
      // Eg evenlyDistributedFilter([0...100], 1); // [50] instead of [0]
      const evenIndex = Math.floor(i * interval + interval / 2);

      newArray.push(arr[evenIndex]);
    }

    newArray.unshift(firstValue);
    newArray.push(lastValue);

    return newArray;
  }
}

export function extractFieldValue(fieldValue) {
  if (fieldValue?.value) return fieldValue.value;

  // For Autocomplete when it has multiple values
  if (Array.isArray(fieldValue) && fieldValue[0]?.value) {
    return fieldValue.map(({ value }) => value);
  }

  return fieldValue;
}

export function filterFormData(dirtyFields, fields, formData) {
  const formDataKeys = Object.keys(formData);
  const _dirtyFields = {};
  const requiredFields = {};

  formDataKeys.forEach((alias) => {
    const fieldValue = extractFieldValue(formData[alias]);
    const isOptional = !!dirtyFields[alias];
    const isRequired = !!fields[alias]?.isRequired;

    if (isRequired) {
      requiredFields[alias] = fieldValue;
    }

    if (isOptional) {
      _dirtyFields[alias] = fieldValue;
    }
  });

  return { ..._dirtyFields, ...requiredFields };
}

export function formatDate(date, format = 'yyyy-mm-dd') {
  if (date) {
    let formattedDate;

    if (typeof date === 'number') date = new Date(date * 1000);

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    if (format === 'yyyy-mm-dd') {
      formattedDate = `${year}-${month}-${day}`;
    } else if (format === 'mm/dd/yyyy') {
      formattedDate = `${month}/${day}/${year}`;
    } else if (format === 'dd/mm/yyyy') {
      formattedDate = `${day}/${month}/${year}`;
    } else if (format === 'yyyy') {
      formattedDate = year;
    } else if (format === 'month yyyy') {
      formattedDate = `${date.toLocaleString(i18n.language, { month: 'short' })} ${year}`;
    } else if (format === 'dd month yyyy') {
      formattedDate = `${day} ${date.toLocaleString(i18n.language, { month: 'short' })} ${year}`;
    } else if (format === 'dd/mm/yyyy,hh:00') {
      formattedDate = `${day}/${month}/${year}, ${hour}:00`;
    } else if (format === 'dd.mm.yyyy hh:00') {
      formattedDate = `${day}.${month}.${year} ${hour}:00`;
    } else if (format === 'yyyy/mm/dd hh:mm:ss') {
      formattedDate = `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`;
    }

    return formattedDate;
  } else {
    return null;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function isObject(a) {
  return !!a && a.constructor === Object;
}

export function isEmailAddressValid(emailAddress) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    emailAddress,
  );
}

export function naturalSort({ nextValue, previousValue }) {
  return previousValue.localeCompare(nextValue, navigator.languages[0] || navigator.language, {
    ignorePunctuation: true,
    numeric: true,
  });
}

export function objectToOrderedArray(object) {
  return Object.entries(object).sort(([, prevOptionValue], [, nextOptionValue]) =>
    naturalSort({ nextValue: nextOptionValue, previousValue: prevOptionValue }),
  );
}

export function sortArrayOfObjectsByKey({ array, key }) {
  return array.slice().sort((firstEl, secondEl) => {
    if (
      (typeof firstEl[key] === 'string' &&
        typeof secondEl[key] === 'string' &&
        firstEl[key].toLowerCase() > secondEl[key].toLowerCase()) ||
      firstEl[key] > secondEl[key]
    ) {
      return 1;
    } else return -1;
  });
}

export function toBool(string) {
  if (typeof string === 'boolean') return string;
  else if (!string || string.toString().toLowerCase() === 'false') return false;
  else return true;
}

export function getOffsetTop(element) {
  let offsetTop = 0;

  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }

  return offsetTop;
}
