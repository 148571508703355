import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { useLazyGetDownloadUrlQuery } from 'src/features/JobFiles/JobFiles.service';
import { WaveDialogTitle } from '../../components/WaveDialogTitle';
import { openWaveSnack } from '../../store/waveSnackSlice';
import { useAppDispatch, usePreference } from '../../utilities/hooks';
import { WaveIcon } from '../WaveIcon';
import { SendEmailDialog } from './components/SendEmailDialog';
import { ShoppingCartItem } from './components/ShoppingCartItem';
import {
  useDeleteShoppingCartItemMutation,
  useGetShoppingCartItemsQuery,
} from './ShoppingCart.service';

export function ShoppingCart() {
  const dispatch = useAppDispatch();
  const { data: shoppingCartData = { items: [] }, refetch } = useGetShoppingCartItemsQuery();
  const [downloadFiles] = useLazyGetDownloadUrlQuery();
  const [deleteFiles] = useDeleteShoppingCartItemMutation();
  const [isSendEmailDialogOpen, setIsSendEmailDialogOpen] = useState(false);
  const [isShoppingCartDialogOpen, setShoppingCartDialogOpen] = useState(false);
  const [clientIdPreference] = usePreference('sys.mid', '');
  const { t } = useTranslation();

  function handleCloseSendEmailDialog() {
    setIsSendEmailDialogOpen(false);
  }

  function handleCloseShoppingCartDialog() {
    setShoppingCartDialogOpen(false);
  }

  function handleDeleteAllShoppingCartItems() {
    handleDeleteItems(shoppingCartData?.items.map((item) => item.id));
  }

  function handleDeleteItems(items: number[]) {
    deleteFiles({ cartid: items })
      .unwrap()
      .then(() => {
        const successMessage =
          (items.length > 1
            ? `The files with the following IDs: ${items} were `
            : `The file with the ID: ${items} was `) + 'successfully removed';

        dispatch(openWaveSnack({ message: successMessage, type: 'success' }));
      });
  }

  function handleDownloadShoppingCart() {
    downloadFiles({
      fileIds: shoppingCartData?.items.map((item) => item.file_id),
    })
      .unwrap()
      .then(({ url }) => {
        deleteFiles({ cartid: shoppingCartData?.items.map((item) => item.id) });
        window.open(`${import.meta.env.VITE_API}${url}`);
        setShoppingCartDialogOpen(false);
      });
  }

  function handleOpenSendEmailDialog() {
    setIsSendEmailDialogOpen(true);
  }

  function handleOpenShoppingCartDialog() {
    setShoppingCartDialogOpen(true);
  }

  useEffect(() => {
    refetch();
  }, [clientIdPreference.value]);

  return (
    <>
      {shoppingCartData?.items.length > 0 && (
        <IconButton color="secondary" onClick={handleOpenShoppingCartDialog} size="large">
          <Badge badgeContent={shoppingCartData?.items.length} color="info">
            <WaveIcon code="shopping-basket" />
          </Badge>
        </IconButton>
      )}

      {isShoppingCartDialogOpen && (
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleCloseShoppingCartDialog}
          open={isShoppingCartDialogOpen}
        >
          <WaveDialogTitle title={t('job-cart.dialogtitle')} />

          <DialogContent>
            <DialogContentText paddingY="10px">
              <strong>
                <Trans i18nKey="cart.download.msg.head">Please Choose a Download Option:</Trans>
              </strong>

              <br />

              <strong>
                <Trans i18nKey="shopping_cart_download_button">Download</Trans>:
              </strong>

              <Trans i18nKey="download_selected_files_completed">
                The download of the selected files starts after the compression progress has been
                completed.
              </Trans>

              <br />

              <strong>
                <Trans i18nKey="shopping_cart_send_email_button">Send Link via Email</Trans>:
              </strong>

              <Trans i18nKey="send_download_links">
                Send an email with the download links to any recipient.
              </Trans>
            </DialogContentText>

            <Grid
              alignItems="center"
              container
              justifyContent="flex-start"
              padding="5px"
              sx={{
                backgroundColor: 'var(--filler)',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
              }}
            >
              <Grid paddingX="20px" xs={2}>
                <Typography fontWeight="bold" mb={0} variant="caption">
                  {t('lib.jobid')}
                </Typography>
              </Grid>

              <Grid paddingX="20px" xs={4}>
                <Typography fontWeight="bold" mb={0} variant="caption">
                  {t('shopping_cart_keyword_header', 'Keyword')}
                </Typography>
              </Grid>

              <Grid xs={5}>
                <Typography fontWeight="bold" mb={0} variant="caption">
                  {t('lib.filename')}
                </Typography>
              </Grid>
            </Grid>

            {shoppingCartData?.items.length ? (
              shoppingCartData?.items.map((item) => (
                <ShoppingCartItem item={item} key={item.id} onRemoveClick={handleDeleteItems} />
              ))
            ) : (
              <DialogContentText paddingY="10px">Your shopping cart is empty</DialogContentText>
            )}
          </DialogContent>

          <DialogActions sx={{ pb: 2.5, pt: 0, px: 3 }}>
            <Stack direction="row" justifyContent="space-between" width={1}>
              <Button
                disabled={!shoppingCartData?.items.length}
                id="empty"
                onClick={handleDeleteAllShoppingCartItems}
              >
                <Trans i18nKey="shopping_cart_empty_button">Empty Shopping Cart</Trans>
              </Button>

              <Stack direction="row" spacing={1}>
                <Button
                  disabled={!shoppingCartData?.items.length}
                  onClick={handleOpenSendEmailDialog}
                >
                  {t('lib.send_link_email')}
                </Button>

                <Button
                  disabled={!shoppingCartData?.items.length}
                  onClick={handleDownloadShoppingCart}
                >
                  {t('lib.file.download')}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}

      {isSendEmailDialogOpen && (
        <SendEmailDialog
          isOpen={isSendEmailDialogOpen}
          onDialogClose={handleCloseSendEmailDialog}
          shouldShowLinkExpiration
        />
      )}
    </>
  );
}
