import { useEffect, useState } from 'react';

import { Slider as MUISlider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { getOffsetTop } from '../../../../utilities/helperFunctions';

import type { SliderValue } from '../../History.types';

export function Slider({ sliderValues }: { sliderValues: SliderValue[] }) {
  const [currentValue, setCurrentValue] = useState(sliderValues[sliderValues.length - 1].value);

  const main: HTMLElement = document.getElementsByTagName('main')[0] as HTMLElement;
  const navBarOffset = document.getElementsByTagName('header')[0].offsetHeight + 10;

  function handleSliderChange(e: Event, newValue: number | number[]) {
    if (typeof newValue === 'number') {
      const item = document.getElementsByClassName(
        sliderValues[newValue].label.split(' ').join('-'),
      )[0];
      const itemPosition = getOffsetTop(item) - navBarOffset;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      main.addEventListener('scroll', onAutoScroll(itemPosition, newValue));
      main.scrollTo({
        behavior: 'smooth',
        top: itemPosition,
      });
    }
  }

  function onAutoScroll(itemPosition: number, newValue: number) {
    const currentPosition = main.scrollTop;

    if (currentPosition === itemPosition) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      main.removeEventListener('scroll', onAutoScroll);
      setCurrentValue(newValue);
    }
  }

  main.onscroll = function () {
    // User scroll
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentPosition = this.scrollTop;
    const prevItemClass = sliderValues[currentValue - 1]?.label.split(' ').join('-');
    const prevItem = document.getElementsByClassName(prevItemClass)[0];
    const currentItemClass = sliderValues[currentValue]?.label.split(' ').join('-');
    const currentItem = document.getElementsByClassName(currentItemClass)[0];
    const nextItemClass = sliderValues[currentValue + 1]?.label.split(' ').join('-');
    const nextItem = document.getElementsByClassName(nextItemClass)[0];
    const currentItemPosition = getOffsetTop(currentItem) - navBarOffset;
    const prevItemPosition = prevItem ? getOffsetTop(prevItem) - navBarOffset : null;

    if (prevItem && prevItemPosition && currentPosition > prevItemPosition) {
      // When prevItemPosition moves above the viewport, move the slider down one position
      setCurrentValue(currentValue - 1);
    } else if (nextItem && currentPosition < currentItemPosition) {
      // When currentItemPosition is not at the top of the viewport, move the slider up one position
      setCurrentValue(currentValue + 1);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setCurrentValue(sliderValues[sliderValues.length - 1].value);
    }

    return () => {
      isMounted = false;
    };
  }, [sliderValues]);

  return (
    <Grid2
      height="50vh"
      padding={2}
      position="sticky"
      top={20}
      width={
        sliderValues[0].label.length > 11 ? 200 : sliderValues[0].label.length === 4 ? 100 : 150
      }
    >
      <MUISlider
        marks={sliderValues}
        max={sliderValues[sliderValues.length - 1].value}
        onChange={handleSliderChange}
        orientation="vertical"
        track={false}
        value={currentValue}
        valueLabelFormat={sliderValues[currentValue]?.label}
      />
    </Grid2>
  );
}
