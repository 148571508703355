import { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  Box,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { FileExplorer } from 'src/components/FileExplorer';
import { FilesTable } from 'src/components/FilesTable';
import { StyledAccordionSummary } from 'src/components/StyledAccordionSummary';
import { RawFiles } from 'src/features/JobFiles/JobFiles.service';
import {
  useGetFoldersQuery,
  useLazyGetFilesQuery,
} from 'src/pages/CompareFiles/CompareFiles.service';
import { useExpand, useRouteParams, useSelect } from 'src/utilities/hooks';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';
import { parseArrayToLogicalObject, parseRecordToLogicalObject } from 'src/utilities/parsers';

type ChooseFilesStepProps = {
  selectedItemsToCompare: Record<number, boolean>;
  onToggleFiles: (files: RawFiles, state?: boolean) => void;
};

export function ChooseFilesStep({ onToggleFiles, selectedItemsToCompare }: ChooseFilesStepProps) {
  const { age, jobId, jobType } = useRouteParams();
  const [selectedFolderPath, setSelectedFolderPath] = useState('');
  const { data: folders = [], isFetching: areFoldersFetching } = useGetFoldersQuery(
    { age, jobId, src: jobType },
    { refetchOnMountOrArgChange: true },
  );
  const [
    fetchFiles,
    {
      data: files = { categorizedFiles: {}, rawFiles: [] },
      error: filesLoadingError,
      isFetching: areFilesFetching,
      isSuccess: areFilesLoadedSuccessfully,
    },
  ] = useLazyGetFilesQuery();
  const { expandedItems, toggleExpandItem } = useExpand(
    parseRecordToLogicalObject(files.categorizedFiles),
  );
  const { selectedItems, toggleSelectItem, toggleSelectMultiple } = useSelect({
    ...parseArrayToLogicalObject(files.rawFiles, 'id'),
    ...selectedItemsToCompare,
  });

  const selectedFileIds = Object.keys(selectedItems)
    .filter((key) => selectedItems[key])
    .map((key) => parseInt(key));

  function handleSelectFileClick(fileId: number, selected: boolean) {
    const file = files.rawFiles.find((file) => file.id === fileId);

    onToggleFiles(file ? [file] : [], selected);
    toggleSelectItem(fileId);
  }

  function handleSelectFolder(code: string, pathName: string, age?: ExpandedAges, src?: string) {
    setSelectedFolderPath(pathName);
    fetchFiles({
      age,
      jobId: code,
      src,
    });
  }

  function handleSelectMultiple(itemIds: number[], selected: boolean) {
    const mappedFiles = itemIds.map((fileId) => files.rawFiles.find((file) => file.id === fileId));

    onToggleFiles(mappedFiles as RawFiles, selected);
    toggleSelectMultiple(itemIds, selected);
  }

  return (
    <Grid display="flex" p={2} xs>
      <FileExplorer
        areFilesLoading={areFilesFetching}
        areFoldersLoading={areFoldersFetching}
        folders={folders}
        onFolderSelected={handleSelectFolder}
        selectedFolder={selectedFolderPath}
        source="compare"
      >
        <Box px={1.5}>
          {!files.rawFiles.length && areFilesLoadedSuccessfully ? (
            <Box pt={3} textAlign="center">
              <Typography color="text.secondary" variant="button">
                <Trans i18nKey="files_nothing_to_show">Nothing to Show</Trans>
              </Typography>
            </Box>
          ) : null}

          {!files.rawFiles.length && !areFilesLoadedSuccessfully && !filesLoadingError ? (
            <Box pt={3} textAlign="center">
              <Typography color="text.secondary" variant="button">
                <Trans i18nKey="select_folder">Please Select a Folder</Trans>
              </Typography>
            </Box>
          ) : null}

          {Object.keys(files.categorizedFiles).map((category, index) => (
            <Accordion expanded={expandedItems[category] || false} key={index}>
              <StyledAccordionSummary onClick={() => toggleExpandItem(category)}>
                <Typography>{category}</Typography>
              </StyledAccordionSummary>

              <Box component={AccordionDetails} p={0}>
                <FilesTable
                  files={files.categorizedFiles[category]}
                  onSelectAllFilesToggle={handleSelectMultiple}
                  onSelectFileClick={handleSelectFileClick}
                  selectedFileIds={selectedFileIds.filter((fileId) =>
                    files.categorizedFiles[category].some(({ id }) => id === fileId),
                  )}
                />
              </Box>
            </Accordion>
          ))}
        </Box>
      </FileExplorer>
    </Grid>
  );
}
