import { TableBody, TableHead, TableRow } from '@mui/material';

import { CellSkeleton } from '../CellSkeleton';

export function LoadingHeaderAndBody({ totalColumn, totalRow }) {
  return (
    <>
      <TableHead>
        <TableRow>
          {[...Array(totalColumn)].map((column, index) => {
            return <CellSkeleton key={index} />;
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {[...Array(totalRow)].map((row, index) => (
          <TableRow key={index}>
            {[...Array(totalColumn)].map((column, index) => (
              <CellSkeleton key={index} />
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}
