import { Trans } from 'react-i18next';

import { Button as MUIButton, ButtonProps } from '@mui/material';

export function Button({ children, ...rest }: ButtonProps) {
  return (
    <MUIButton {...rest}>
      <Trans>{children}</Trans>
    </MUIButton>
  );
}
