import { useNavigate } from 'react-router-dom';

import { useJobContext } from 'src/pages/Job/JobContext';

export function useJobForm() {
  const navigate = useNavigate();

  const {
    areAllFieldsDisabled,
    fieldLayout,
    handleSubmit,
    hasEditRights,
    isNewJob,
    jobType,
    visibilityOfSections,
  } = useJobContext();

  function handleClickCancel() {
    navigate(`/jobs-job-${jobType}`);
  }

  return {
    areAllFieldsDisabled,
    fieldLayout,
    handleClickCancel,
    handleSubmit,
    hasEditRights,
    isNewJob,
    jobType,
    visibilityOfSections,
  };
}
