import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Button, Dialog, DialogContent, Grid, List } from '@mui/material';

import { ViewSearchList } from '../ViewSearchList';
import { ViewSearchNewForm } from '../ViewSearchNewForm';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { WaveIcon } from '../../../WaveIcon';

import { API } from '../../../../utilities/api';
import { useFirstRender, usePreference, usePreferencePrefix } from '../../../../utilities/hooks';
import { openWaveSnack } from '../../../../store/waveSnackSlice';

export function ViewSearchDialog({ onCloseDialog, dialogInformation }) {
  const { t } = useTranslation();
  const { age, isSubJobs, jobType, preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });

  const [activeFiltersPreference, setActiveFiltersPreference] = usePreference(
    `${preferencePrefix}.search`,
    [],
  );
  const [columnsPreference, setColumnsPreference] = usePreference(
    `${preferencePrefix}.columns`,
    '',
  );
  const [deleteItemID, setDeleteItemID] = useState(0);
  const dispatch = useDispatch();
  const [editItemID, setEditItemID] = useState(0);
  const firstRender = useFirstRender();
  const [listItems, setListItems] = useState([]);
  const [name, setName] = useState('');
  const [newFormOpen, setNewFormOpen] = useState(false);
  const [orderByPreference, setOrderByPreference] = usePreference(
    `${preferencePrefix}.order`,
    'jobid',
  );
  const [pagePreference, setPagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');
  const [rowsPerPagePreference, setRowsPerPagePreference] = usePreference(
    `${preferencePrefix}.rowsPerPage`,
    '25',
  );
  const [searchFiltersPreference, setSearchFiltersPreference] = usePreference(
    `${preferencePrefix}.filterFields`,
    '',
  );
  const userID = useSelector((state) => state.user.details.id);
  const [viewType, setViewType] = useState('userView');

  const newLabel =
    dialogInformation.code === 'saved-views'
      ? t('new_view_label', 'New View')
      : t('new_search_label', 'New Search');
  const savedViews = dialogInformation.code === 'saved-views' ? true : false;

  function buildBasicParams() {
    const params = {
      age,
      src: jobType,
    };

    if (isSubJobs) {
      params['sub_job'] = 'true';
    }

    if (!savedViews) {
      params['search_type'] = viewType === 'globalView' ? 'gru' : 'usr';
    }

    return params;
  }

  function buildCreateParams() {
    const params = buildBasicParams();

    if (savedViews) {
      params['is_global'] = viewType === 'globalView' ? 1 : 0;
    } else {
      params['search'] = activeFiltersPreference.value;
    }

    return {
      ...params,
      name,
    };
  }

  async function createListItem() {
    try {
      const URL = savedViews ? '/views/create' : '/search/create';
      await API.post(URL, buildCreateParams(), {
        onError: (error) => {
          dispatch(
            openWaveSnack({
              type: 'error',
              message: error.message,
            }),
          );
        },
        onSuccess: () => {
          onCloseDialog();

          dispatch(
            openWaveSnack({
              type: 'success',
              message: `${name} was successfully saved`,
            }),
          );
        },
      });
    } catch (error) {
      dispatch(
        openWaveSnack({
          type: 'error',
          message: error.response.data.message,
        }),
      );
    }
  }

  async function deleteListItem(listItemID) {
    const URL = savedViews ? '/views/delete' : '/search/delete';
    await API.delete(URL, {
      params: {
        search_id: listItemID,
        view_id: listItemID,
      },
      onError: (error) => {
        dispatch(
          openWaveSnack({
            type: 'error',
            message: error.message,
          }),
        );
      },
      onSuccess: (res) => {
        let _listItems = listItems.filter((item) => item.id !== listItemID);

        const totalUserViews = _listItems.reduce((previousValue, currentItem) => {
          return previousValue + (currentItem.src_id === userID);
        }, 0);

        const totalGlobalViews = _listItems.reduce((previousValue, currentItem) => {
          return previousValue + (currentItem.src_id === 0);
        }, 0);

        if (totalUserViews === 0)
          _listItems = _listItems.filter((item) => item.name !== 'User Views');
        if (totalGlobalViews === 0)
          _listItems = _listItems.filter((item) => item.name !== 'Global Views');

        setDeleteItemID(0);
        setListItems(_listItems);
      },
    });
  }

  function buildEditParams(listItemID) {
    const params = buildBasicParams();

    if (!savedViews) {
      params['search'] = activeFiltersPreference.value;
    }

    return {
      ...params,
      name,
      search_id: listItemID,
      view_id: listItemID,
    };
  }

  async function editListItem(listItemID) {
    const URL = savedViews ? '/views/update' : '/search/update';
    await API.put(URL, buildEditParams(listItemID), {
      onError: (error) => {
        dispatch(
          openWaveSnack({
            type: 'error',
            message: error.message,
          }),
        );
      },
      onSuccess: (res) => {
        onCloseDialog();

        dispatch(
          openWaveSnack({
            type: 'success',
            message: `${name} was successfully updated`,
          }),
        );
      },
    });
  }

  function handleApply(item) {
    if (savedViews) {
      setColumnsPreference({ ...columnsPreference, value: item.cols });
      setOrderByPreference({ ...orderByPreference, value: item.ord });
      setPagePreference({ ...pagePreference, value: '0' });
      setRowsPerPagePreference({
        ...rowsPerPagePreference,
        value: item.lpp.toString(),
      });
      setSearchFiltersPreference({
        ...searchFiltersPreference,
        value: item.sfie,
      });

      // TODO: ts (thumbnail size) preference??
    } else {
      setActiveFiltersPreference({
        ...activeFiltersPreference,
        value: item.search,
      });
    }
    dispatch(
      openWaveSnack({
        type: 'success',
        message: `${item.name} was successfully applied`,
      }),
    );
  }

  function handleClickCancel() {
    setDeleteItemID(0);
    setEditItemID(0);
  }

  function handleClickConfirm(itemID) {
    // Determine if we editing or deleting
    if (editItemID !== 0) editListItem(itemID);
    else deleteListItem(itemID);
  }

  function handleClickDelete(itemID) {
    setDeleteItemID(itemID);
  }

  function handleClickEdit(itemID, itemName) {
    setEditItemID(itemID);
    setName(itemName);
  }

  function handleCloseNewForm() {
    setName('');
    setNewFormOpen(false);
  }

  function handleCreate() {
    createListItem();
    onCloseDialog();
  }

  function handleOpenNewForm() {
    setName('');
    setNewFormOpen(true);
    setEditItemID(0);
    setDeleteItemID(0);
  }

  useEffect(() => {
    let isMounted = true;

    if (
      isMounted &&
      !firstRender &&
      !activeFiltersPreference.loading &&
      !columnsPreference.loading &&
      !orderByPreference.loading &&
      !rowsPerPagePreference.loading &&
      !searchFiltersPreference.loading
    ) {
      onCloseDialog();
    }

    return () => (isMounted = false);
  }, [
    activeFiltersPreference.loading,
    columnsPreference.loading,
    orderByPreference.loading,
    rowsPerPagePreference.loading,
    searchFiltersPreference.loading,
  ]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async (cancelToken) => {
      const URL = savedViews ? '/views/get' : '/search/get';

      await API.get(URL, {
        cancelToken: cancelToken,
        onError: (error) => {
          dispatch(
            openWaveSnack({
              type: 'error',
              message: error.message,
            }),
          );
        },
        onSuccess: (res) => {
          if (savedViews) {
            let views = [];
            if (Object.values(res.global_views).length > 0) {
              views.push({ name: 'Global Views' });
              res.global_views.forEach((globalView) => {
                views.push({ ...globalView, type: 'global view' });
              });
            }
            if (Object.values(res.user_views).length > 0) {
              views.push({ name: 'User Views' });
              res.user_views.forEach((userView) => {
                views.push({ ...userView, type: 'user view' });
              });
            }

            setListItems(views);
          } else {
            setListItems(res);
          }
        },
        params: buildBasicParams(),
      });
    })(source.token);

    return () => source.cancel();
  }, [savedViews]);

  return (
    <Dialog fullWidth={true} onClose={onCloseDialog} open>
      <WaveDialogTitle
        title={
          <Grid container justifyContent="space-between">
            <Grid item>{dialogInformation.text}</Grid>

            <Grid item>
              <Button
                className="primary-txt white-bg"
                onClick={handleOpenNewForm}
                startIcon={<WaveIcon code="add-box" />}
              >
                {newLabel}
              </Button>
            </Grid>
          </Grid>
        }
      />

      <DialogContent>
        <List disablePadding>
          {listItems.map((item) => {
            return (
              <ViewSearchList
                deleteItemID={deleteItemID}
                editItemID={editItemID}
                item={item}
                key={item.id || item.name}
                name={name}
                newFormOpen={newFormOpen}
                onApply={handleApply}
                onClickCancel={handleClickCancel}
                onClickConfirm={handleClickConfirm}
                onClickDelete={handleClickDelete}
                onClickEdit={handleClickEdit}
                onSetName={setName}
                savedViews={savedViews}
              />
            );
          })}
        </List>
      </DialogContent>

      {newFormOpen && (
        <ViewSearchNewForm
          name={name}
          newLabel={newLabel}
          onCloseNewForm={handleCloseNewForm}
          onCreate={handleCreate}
          onSetName={setName}
          onSetViewType={setViewType}
          savedViews={savedViews}
          viewType={viewType}
        />
      )}
    </Dialog>
  );
}
