import { useState } from 'react';
import { Container } from '@mui/material';

import { ActionBar } from '../../features/JobsTable/components/ActionBar';
import { BreadCrumbs } from '../../components/BreadCrumbs';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { Filters } from '../../features/JobsTable/components/Filters';
import { JobsTable } from '../../features/JobsTable';
import { Workflow } from 'src/features/Workflow';

import { useJobsTable } from '../../features/JobsTable/utilities/hooks';
import { useDeleteJobMutation } from 'src/features/JobsTable/JobsTable.service';
import { BulkEditJobFields } from 'src/features/BulkEditJobFields';
import { ProjectAssign } from 'src/components/ProjectAssign';

export function Jobs() {
  const {
    areActiveFiltersVisible,
    bulkEditType,
    columns,
    confirmationDialogOpen,
    deadlines,
    deleteJob,
    deleteJobID,
    expandAllSubJobs,
    handleBulkEditSuccess,
    handleCancelBulkEdit,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleCloseConfirmationDialog,
    handleExpandAllSubJobs,
    handleOpenConfirmationDialog,
    handleToggleSelectAllRows,
    idsOfExpandedSubJobs,
    jobIdsWithSubJobs,
    jobType,
    loadingJobs,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setBulkEditType,
    setExpandAllSubJobs,
    setIdsOfExpandedSubJobs,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
    getJobs,
  } = useJobsTable();

  const [deletingJob, { isLoading }] = useDeleteJobMutation({
    fixedCacheKey: 'shared-delete-job',
  });

  if (bulkEditType === 'fields') {
    return (
      <BulkEditJobFields
        onBulkEditSuccess={handleBulkEditSuccess}
        onCancelBulkEdit={handleCancelBulkEdit}
        selectedRows={selectedRows}
      />
    );
  }
  if (bulkEditType === 'project') return <ProjectAssign />;
  if (bulkEditType === 'flags' || bulkEditType === 'status') return <div>{bulkEditType}</div>;

  return (
    <>
      <BreadCrumbs />

      <Container maxWidth={false} sx={{ mb: 1, mt: 1.5 }}>
        {jobType !== 'all' && jobType !== 'search' && <Workflow rows={rows} />}

        <ActionBar
          areActiveFiltersVisible={areActiveFiltersVisible}
          expandAllSubJobs={expandAllSubJobs}
          idsOfExpandedSubJobs={idsOfExpandedSubJobs}
          jobIdsWithSubJobs={jobIdsWithSubJobs}
          onChangePageSearch={handleChangePageSearch}
          onSetExpandAllSubJobs={setExpandAllSubJobs}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
          transferExpandAllSubJobs={handleExpandAllSubJobs}
          transferSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
          transferSetBulkEditType={setBulkEditType}
        />

        <Filters
          areActiveFiltersVisible={areActiveFiltersVisible}
          onSetAreActiveFiltersVisible={setAreActiveFiltersVisible}
          getJobs={getJobs}
        />
      </Container>

      <JobsTable
        columns={columns}
        deadlines={deadlines}
        idsOfExpandedSubJobs={idsOfExpandedSubJobs}
        jobIdsWithSubJobs={jobIdsWithSubJobs}
        loadingJobs={loadingJobs}
        onSetPageHistory={setPageHistory}
        onSetSelectedRows={setSelectedRows}
        rows={rows}
        selectedRows={selectedRows}
        totalRowCount={totalRowCount}
        transferClickRowCheckbox={handleClickRowCheckbox}
        transferOpenConfirmationDialog={handleOpenConfirmationDialog}
        transferSetIDsOfExpandedSubJobs={setIdsOfExpandedSubJobs}
        transferToggleSelectAllRows={handleToggleSelectAllRows}
      />

      <ConfirmationDialog
        isDisabled={isLoading}
        isOpen={confirmationDialogOpen}
        message={`Are you sure you want to delete ${deleteJobID}?`}
        onDialogClose={handleCloseConfirmationDialog}
        onConfirmation={deleteJob}
      />
    </>
  );
}
