import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';

import { BarChart } from './components/BarChart';
import { FieldMapper } from '../FieldMapper';
import { PieChart } from './components/PieChart';

import { usePreference, usePreferencePrefix } from '../../utilities/hooks';
import { useTranslation } from 'react-i18next';

function calculateColumnSizes(...params) {
  const numberOfCharts = params.filter(Boolean).length;
  const barChartWeight = 1.5;
  const pieChartWeight = 0.75;
  const totalColumns = 12;

  function calculateSizeFromWeight(weight) {
    return (totalColumns / numberOfCharts) * (numberOfCharts === 3 ? weight : 1);
  }

  return {
    approvalTasksColumns: calculateSizeFromWeight(pieChartWeight),
    jobTasksColumns: calculateSizeFromWeight(barChartWeight),
    statusChangeTasksColumns: calculateSizeFromWeight(pieChartWeight),
  };
}

export function Tasks({
  approvalTasks,
  isLoadingUsers,
  jobTasks,
  loading,
  onSetTeammateID,
  statusChangeTasks,
  teammateID,
  users,
}) {
  const { t } = useTranslation();
  const { preferencePrefix } = usePreferencePrefix();
  const [approvalTaskPreference, setApprovalTaskPreference] = usePreference(
    `${preferencePrefix}.show-approval-tasks`,
    '1',
  );
  const [jobTaskPreference, setJobTaskPreference] = usePreference(
    `${preferencePrefix}.show-job-tasks`,
    '1',
  );
  const [statusChangeTasksPreference, setStatusChangeTasksPreference] = usePreference(
    `${preferencePrefix}.show-status-change-tasks`,
    '1',
  );

  const approvalTasksID = 'approvalTasks';
  const approvalTasksLabel = t('lib.approvals_task', 'Approval Tasks');
  const jobTasksID = 'jobTasks';
  const jobTasksLabel = t('tasks_job_label', 'Job Tasks');
  const statusChangeTasksID = 'statusChangeTasks';
  const statusChangeTasksLabel = t('tasks_status_change_label', 'Status Change Tasks');
  const showApprovalTasks = approvalTaskPreference.value === '1';
  const showJobTasks = jobTaskPreference.value === '1';
  const showStatusChangeTasks = statusChangeTasksPreference.value === '1';

  const { approvalTasksColumns, jobTasksColumns, statusChangeTasksColumns } = calculateColumnSizes(
    showApprovalTasks,
    showJobTasks,
    showStatusChangeTasks,
  );

  function handleSwitchChange({ target: { id: switchID, checked } }) {
    const value = checked === true ? '1' : '0';

    if (switchID === approvalTasksID)
      setApprovalTaskPreference({ ...approvalTaskPreference, value });
    else if (switchID === jobTasksID) setJobTaskPreference({ ...jobTaskPreference, value });
    else if (switchID === statusChangeTasksID) {
      setStatusChangeTasksPreference({ ...statusChangeTasksPreference, value });
    }
  }

  function handleUserChange({ target: { value } }) {
    onSetTeammateID(parseInt(value));
  }

  return (
    <Card>
      <CardHeader
        classes={{ content: 'mx-10' }}
        title={<Typography component="p">{t('lib.tasks', 'Tasks')}</Typography>}
        className="p-5 primary-bg white-txt"
      />

      <CardContent>
        <Grid alignItems="center" container margin={0} spacing={1}>
          <Grid item position="relative" xs={3}>
            <FieldMapper
              disabled={isLoadingUsers}
              label={t('tasks_teammates_field', "View Teammate's Tasks")}
              transferChangeField={handleUserChange}
              type="select"
              options={users}
              value={teammateID}
            />
            {isLoadingUsers && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>

          <Grid container item justifyContent="space-around" xs={9}>
            <Grid item position="relative">
              <FieldMapper
                alias={approvalTasksID}
                disabled={approvalTaskPreference.loading}
                label={approvalTasksLabel}
                transferChangeField={handleSwitchChange}
                type="switch"
                value={showApprovalTasks}
              />
              {approvalTaskPreference.loading && (
                <CircularProgress className="buttonProgress" size={24} />
              )}
            </Grid>

            <Grid item position="relative">
              <FieldMapper
                alias={jobTasksID}
                disabled={jobTaskPreference.loading}
                label={jobTasksLabel}
                transferChangeField={handleSwitchChange}
                type="switch"
                value={showJobTasks}
              />
              {jobTaskPreference.loading && (
                <CircularProgress className="buttonProgress" size={24} />
              )}
            </Grid>

            <Grid item position="relative">
              <FieldMapper
                alias={statusChangeTasksID}
                disabled={statusChangeTasksPreference.loading}
                label={statusChangeTasksLabel}
                transferChangeField={handleSwitchChange}
                type="switch"
                value={showStatusChangeTasks}
              />
              {statusChangeTasksPreference.loading && (
                <CircularProgress className="buttonProgress" size={24} />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container marginTop="10px">
          {showApprovalTasks && (
            <Grid container direction="column" item xs={approvalTasksColumns}>
              <PieChart loading={loading} pieces={approvalTasks} title={approvalTasksLabel} />
            </Grid>
          )}

          {showJobTasks && (
            <Grid container direction="column" item xs={jobTasksColumns}>
              <PieChart loading={loading} pieces={jobTasks} title={jobTasksLabel} />
            </Grid>
          )}

          {showStatusChangeTasks && (
            <Grid container direction="column" item xs={statusChangeTasksColumns}>
              <BarChart
                bars={statusChangeTasks}
                hoverBarLabel={t('total_tasks', 'Total Tasks')}
                loading={loading}
                title={statusChangeTasksLabel}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
