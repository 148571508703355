import { Box, Typography } from '@mui/material';

import { useExpand, useRouteParams } from '../../../../utilities/hooks';
import { parseArrayToLogicalObject } from '../../../../utilities/parsers';
import { SubApproval, useGetApprovalsQuery } from '../../Approvals.service';
import { ApprovalItem } from '../ApprovalItem';

export function ApprovalsList() {
  const { age, jobId, jobType } = useRouteParams();
  const { data: approvals = [] } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });

  const { expandedItems, toggleExpandItem } = useExpand({
    ...parseArrayToLogicalObject(approvals, 'id'),
    [approvals[0].id]: true,
  });

  return (
    <>
      {approvals.map((approval, index) =>
        index === 0 && !(approval.approval as SubApproval[]).length && approvals.length === 1 ? (
          <Box key={index} p={1} textAlign="center">
            <Typography color="text.secondary" variant="button">
              No APL data to show
            </Typography>
          </Box>
        ) : (
          <ApprovalItem
            approvalItem={approval}
            isExpanded={expandedItems[approval.id]}
            key={approval.id}
            onToggleExpand={toggleExpandItem}
          />
        ),
      )}
    </>
  );
}
