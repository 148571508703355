import { ChangeEvent, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { ColorDialog } from 'src/features/JobForm/components/ColorDialog';
import { TranslationDialog } from 'src/features/JobForm/components/TranslationDialog';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreference } from 'src/utilities/hooks';

type TextFieldOwnProps = {
  description?: string;
  fullHeight?: boolean;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  isFetching?: boolean;
  isError?: boolean;
  isRawText?: boolean;
  shouldShowHelperText?: boolean;
  isLabelledText?: boolean;
};
type TextFieldProps = Omit<MuiTextFieldProps, 'defaultValue' | 'error' | 'required'> &
  TextFieldOwnProps;

export function TextField<T extends FieldValues>({
  control,
  description,
  disabled,
  fullHeight = false,
  isError,
  isFetching = false,
  isLabelledText = false,
  isRawText = false,
  label,
  name,
  onBlur,
  onChange,
  onChangeLogicBuilderField,
  rules,
  shouldShowHelperText = true,
  size = 'small',
  sx,
  type = 'search',
  variant = 'outlined',
  ...rest
}: TextFieldProps & Omit<UseControllerProps<T>, 'defaultValue'>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isColorDialogOpen, setIsColorDialogOpen] = useState(false);
  const [showTooltipsPreference] = usePreference('job.feldtips', 'Y');

  const {
    field: { onBlur: onFieldBlur, onChange: onFieldChange, ref, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      ...rules,
      validate:
        type === 'barcode'
          ? (value) => {
              if (!value) return;

              const barcodeLengths = [8, 12, 13, 14, 17, 18];
              const barcodeString = value.toString();
              const length = barcodeString.length;
              const errorMessage = 'Invalid barcode';

              if (barcodeLengths.includes(length)) {
                let sum = 0;
                let multiplier = length % 2 === 0 ? 3 : 1;

                for (let i = 0; i <= length - 2; i++) {
                  const currentDigit = parseInt(barcodeString[i]);

                  sum += currentDigit * multiplier;
                  multiplier = multiplier === 3 ? 1 : 3;
                }

                const checkDigit = 10 - (sum % 10);
                const lastDigit = parseInt(barcodeString[length - 1]);

                return checkDigit === lastDigit || errorMessage;
              }

              return errorMessage;
            }
          : rules?.validate,
    },
  });

  const [waveIconCode, waveIconColor] = !value?.length
    ? ['input-field-empty', 'primary']
    : error
    ? ['input-field-invalid', 'error']
    : ['input-field-valid', 'success'];

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    // The RHF onChange must come first
    // so that the value is available when running other onChange functions
    onFieldChange(event);

    if (onChange) onChange(event);

    if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);
  }

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleOpenColorDialog() {
    setIsColorDialogOpen(true);
  }

  if (isRawText) {
    return <Typography>{type === 'number' && !value ? '0' : value}</Typography>;
  }

  if (isLabelledText) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>{type === 'number' && !value ? '0' : value}</Typography>
      </Box>
    );
  }

  return (
    <>
      <MuiTextField
        disabled={disabled || isFetching}
        error={!!error || isError}
        fullWidth
        inputRef={ref}
        label={label}
        multiline={type === 'memo'}
        onBlur={(event) => {
          onFieldBlur();

          if (onBlur) {
            onBlur(event);
          }
        }}
        onChange={handleChange}
        required={Boolean(rules?.required)}
        rows={type === 'memo' ? 5 : 0}
        size={size}
        style={{ height: fullHeight ? '100%' : undefined }}
        sx={{
          ...sx,
          '& .MuiInputBase-input': {
            height: fullHeight ? '100% !important' : undefined,
            overflow: fullHeight ? 'auto !important' : undefined,
          },
          '& .MuiInputBase-root': {
            alignItems: fullHeight ? 'start' : undefined,
            display: fullHeight ? 'flex' : undefined,
            height: fullHeight ? '100%' : undefined,
          },
        }}
        type={type === 'barcode' ? 'number' : type}
        value={value || ''}
        variant={variant === 'outlined' ? 'outlined' : label ? variant : 'filled'}
        {...(type === 'barcode' && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <WaveIcon code={waveIconCode} color={waveIconColor} fontSize="small" />
              </InputAdornment>
            ),
          },
        })}
        {...(type === 'core_color' && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  disabled={disabled || isFetching}
                  edge="end"
                  onClick={handleOpenColorDialog}
                >
                  <WaveIcon code="color-dropdown" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          },
        })}
        {...(isFetching && {
          InputProps: {
            endAdornment: (
              <Box display="flex">
                <CircularProgress size={24} />
              </Box>
            ),
          },
        })}
        {...(!label && { placeholder: name })}
        {...field}
        // https://mui.com/material-ui/react-text-field/#helper-text
        {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
        {...rest}
      />

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}

      {type === 'memo' && import.meta.env.VITE_INSTALLATION === 'hubx' ? (
        <IconButton onClick={handleOpenDialog}>
          <WaveIcon code="job-form-language" color="primary" />
        </IconButton>
      ) : null}

      {isOpen && <TranslationDialog isOpen={isOpen} onSetIsOpen={setIsOpen} value={value} />}

      {isColorDialogOpen ? (
        <ColorDialog
          isColorDialogOpen={isColorDialogOpen}
          onHandleChange={handleChange}
          onSetIsColorDialogOpen={setIsColorDialogOpen}
        />
      ) : null}
    </>
  );
}
