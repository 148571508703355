import { useEffect, useState } from 'react';

import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
} from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'src/utilities/hooks';

export function ViewSearchList({
  deleteItemID,
  editItemID,
  item,
  name,
  newFormOpen,
  onApply,
  onClickCancel,
  onClickConfirm,
  onClickDelete,
  onClickEdit,
  onSetName,
  savedViews,
}) {
  const [action, setAction] = useState('');
  const [deleteEdit, setDeleteEdit] = useState(false);
  const [previewDisabled, setPreviewDisabled] = useState(false);
  const editRights = useAppSelector((state) => state.user.rights.edit);
  const deleteRights = useAppSelector((state) => state.user.rights.delete);
  const hasEditandDeleteRights = editRights.includes('vie') && deleteRights.includes('vie');
  const shouldShowEditDeleteButton =
    (item.type === 'global view' && hasEditandDeleteRights) || item.type === 'user view';

  function handleChangeItemName({ target: { value } }) {
    onSetName(value);
  }

  function handleClickApply() {
    onApply(item);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setDeleteEdit(action === 'delete' || action === 'edit');

    return () => (isMounted = false);
  }, [action]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setAction(deleteItemID === item.id ? 'delete' : '');

    return () => (isMounted = false);
  }, [deleteItemID, item]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setPreviewDisabled(deleteEdit || newFormOpen);

    return () => (isMounted = false);
  }, [deleteEdit, newFormOpen]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setAction(editItemID === item.id ? 'edit' : '');

    return () => (isMounted = false);
  }, [editItemID, item]);

  return !item.id ? (
    <ListSubheader className="mb-neg20" disableSticky>
      {item.name}
    </ListSubheader>
  ) : (
    <ListItem disabled={newFormOpen}>
      <ListItemText
        className="mx-2 pl-10"
        inset={savedViews}
        primary={
          action === 'delete' ? (
            `Are you sure want to delete ${item.name}?`
          ) : action === 'edit' ? (
            <TextField
              autoFocus
              fullWidth
              onChange={handleChangeItemName}
              type="search"
              value={name}
            />
          ) : (
            item.name
          )
        }
      />

      <Button
        className="mx-2"
        color="primary"
        disabled={previewDisabled}
        onClick={handleClickApply}
        startIcon={<WaveIcon code="jobs-view-search-preview" />}
      >
        <Trans i18nKey="apply_button">Apply</Trans>
      </Button>

      {deleteEdit ? (
        <IconButton className="mx-2" onClick={onClickCancel}>
          <WaveIcon code="close" color="primary" fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          className="mx-2"
          disabled={newFormOpen}
          onClick={() => {
            onClickEdit(item.id, item.name);
          }}
          sx={{ visibility: shouldShowEditDeleteButton ? 'visible' : 'hidden' }}
        >
          <WaveIcon code="edit" color="primary" fontSize="small" />
        </IconButton>
      )}

      {deleteEdit ? (
        <IconButton className="mx-2" onClick={() => onClickConfirm(item.id)}>
          <WaveIcon code="check" color="primary" fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          className="mx-2"
          disabled={newFormOpen}
          onClick={() => onClickDelete(item.id)}
          sx={{ visibility: shouldShowEditDeleteButton ? 'visible' : 'hidden' }}
        >
          <WaveIcon code="delete" color="primary" fontSize="small" />
        </IconButton>
      )}
    </ListItem>
  );
}
