import { TableCell } from '@mui/material';

import { WaveTooltip } from '../../../../components/WaveTooltip';
import { WaveIcon } from '../../../WaveIcon';
import { StyledLink } from '../Row/styled';

export function FlagCell({ flags, isSubRow, link, paddingY }) {
  return (
    <TableCell
      className={`${isSubRow ? 'b-left-off-white b-right-off-white ' : ''}no-wrap`}
      sx={{ paddingY }}
    >
      <StyledLink href={link}>
        {flags.length > 0 &&
          flags.map((flag) => (
            <WaveTooltip
              body={flag.comment}
              component={
                <WaveIcon
                  className={
                    flag.code === 'onhold'
                      ? 'warning-txt'
                      : flag.code === 'resume' || flag.code === 'revive'
                      ? 'success-txt'
                      : 'error-txt material-icons-outlined'
                  }
                  code={`job-flags-${flag.code}`}
                />
              }
              header={flag.reason}
              key={flag.name}
              placement="top"
              type="simple"
            />
          ))}
      </StyledLink>
    </TableCell>
  );
}
