import AutoSizer from "react-virtualized-auto-sizer";

import { FixedSizeList } from "react-window";

import { List, Paper } from "@mui/material";

import { FieldSelectorField } from "../FieldSelectorField";

export function FieldSelectorFieldWell({
  checkedFields,
  fieldPreference,
  fields,
  transferSetCheckedFields,
  type,
}) {
  return (
    <Paper className="field-well" variant="outlined">
      <List component="nav" disablePadding>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={fields.length}
              itemData={{
                checkedFields,
                fieldPreference,
                fields,
                onSetCheckedFields: transferSetCheckedFields,
                type,
              }}
              itemSize={50}
              width={width}
            >
              {FieldSelectorField}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    </Paper>
  );
}
