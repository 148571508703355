import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from '@mui/material';

import { Button } from 'src/components/Button';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { CopyInProjectButton, CreateCopyButton } from '../../../JobForm/JobForm.service';

type CopyJobDialogProps = {
  areOptionsLoading: boolean;
  copyToJobOptions: CreateCopyButton[];
  jobId: number;
  jobType: string;
  onClose: () => void;
};

export function CopyJobDialog({
  areOptionsLoading,
  copyToJobOptions,
  jobId,
  jobType,
  onClose,
}: CopyJobDialogProps) {
  const { t } = useTranslation();
  const [copyMode, setCopyMode] = useState<{
    id: CreateCopyButton['code'];
    label: string;
  } | null>(null);
  const [copyToJobType, setCopyToJobType] = useState<string | null>(null);
  const navigate = useNavigate();
  const selectedSubItems = copyToJobOptions.find(
    (option) => option.code === copyMode?.id,
  )?.subItems;

  function handleConfirm() {
    const selectedData = copyToJobOptions.find((option) => option.code === copyMode?.id)?.data;
    const mode = copyMode?.id === 'copy-in-project' ? 'copy-in-project' : 'copy';
    let copyToProjectParams = '';

    if (mode === 'copy-in-project') {
      const copyToProjectId = (selectedData as CopyInProjectButton['data'])?.jobid;
      const copyToProjectType = (selectedData as CopyInProjectButton['data'])?.src;

      copyToProjectParams = `&copyToProject=${copyToProjectId}&copyToProjectType=${copyToProjectType}`;
    }

    const redirectUrl = `/jobs-job-${copyToJobType}/new/job?mode=${mode}&copyFromJobId=${jobId}&copyFromJobType=${jobType}${copyToProjectParams}`;

    navigate(redirectUrl);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setCopyToJobType((event.target as HTMLInputElement).value);
  }

  return (
    <Dialog fullWidth onClose={onClose} open={true}>
      <WaveDialogTitle title={t('copy_job_title', 'Copy Job')} />

      <DialogContent>
        <Box pt={3}>
          {areOptionsLoading ? (
            [...Array(4)].map((_, index) => (
              <Grid container key={index}>
                <Grid item margin="5px">
                  <Skeleton height={20} variant="circular" width={20} />
                </Grid>

                <Grid item margin="5px" xs>
                  <Skeleton height={20} variant="rectangular" />
                </Grid>
              </Grid>
            ))
          ) : (
            <>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, newValue) => {
                  setCopyMode(newValue);
                  setCopyToJobType(null);
                }}
                options={copyToJobOptions
                  .filter((option) => option.code !== 'new-job')
                  .map((item) => ({
                    id: item.code,
                    label: item.text,
                  }))}
                renderInput={(params) => <TextField {...params} label="Copy Options" />}
                size="small"
                sx={{ width: 300 }}
                value={copyMode}
              />

              {copyMode ? (
                <FormControl sx={{ pt: 3 }}>
                  <FormLabel>Job Type</FormLabel>

                  <RadioGroup
                    name="copy-to-job-type-list"
                    onChange={handleChange}
                    value={copyToJobType}
                  >
                    {Object.keys(selectedSubItems || {}).map((item) => (
                      <FormControlLabel
                        control={<Radio />}
                        key={item}
                        label={selectedSubItems?.[item]}
                        value={item}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : null}
            </>
          )}
        </Box>
      </DialogContent>

      <DialogActions className="space-between" sx={{ pb: 3, px: 3 }}>
        <Button color="warning" onClick={onClose}>
          lib.cancel
        </Button>

        <Button disabled={!copyToJobType || !copyMode} onClick={handleConfirm}>
          lib.confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
