import {
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ViewSearchNewForm({
  name,
  newLabel,
  onCloseNewForm,
  onCreate,
  onSetName,
  onSetViewType,
  savedViews,
  viewType,
}) {
  const { t } = useTranslation();
  function handleChangeName({ target: { value } }) {
    onSetName(value);
  }

  function handleChangeViewType({ target: { value } }) {
    onSetViewType(value);
  }

  return (
    <>
      <DialogActions>
        <TextField
          autoComplete="off"
          autoFocus
          className="flex-grow-1 mt-0"
          label={newLabel}
          margin="dense"
          onChange={handleChangeName}
          type="search"
          value={name}
        />

        {savedViews && (
          <FormControl className="mx-10" component="fieldset">
            <FormLabel component="legend">{t('view_type_label', 'View Type')}</FormLabel>

            <RadioGroup onChange={handleChangeViewType} row value={viewType}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label={t('global_view_label', 'Global View')}
                value="globalView"
              />

              <FormControlLabel
                control={<Radio color="primary" />}
                label={t('user_view_label', 'User View')}
                value="userView"
              />
            </RadioGroup>
          </FormControl>
        )}
      </DialogActions>

      <DialogActions className="space-between">
        <Button color="warning" onClick={onCloseNewForm}>
          {t('lib.cancel', 'Cancel')}
        </Button>

        <Button disabled={!name} onClick={onCreate}>
          {t('clip.save', 'Save')}
        </Button>
      </DialogActions>
    </>
  );
}
