import { initReactI18next } from 'react-i18next';

import { AxiosError } from 'axios';
import i18n from 'i18next';

import { API } from 'src/utilities/api';

type Translation = {
  [key: string]: string;
};

type ResponseTranslation = {
  code: string;
  value: string;
};

export function initializeLanguage() {
  const userLanguage = localStorage.getItem('userLanguage') || 'en';
  const isDebugMode = import.meta.env.VITE_I18N_DEBUG_MODE === true;

  const cacheKey = `translations-${userLanguage}-${isDebugMode ? 'development' : 'production'}`;

  i18n
    .use({
      read: function (
        language: string,
        namespace: string,
        callback: (error: AxiosError | null, data?: Translation) => void,
      ): void {
        const currentTime = Date.now();
        const expiryTime = 24 * 60 * 60 * 1000; // 24 hours

        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
          const { expiry, translations } = JSON.parse(cachedData);

          if (expiry > currentTime) {
            callback(null, translations);

            return;
          }
        }

        API.get('/language-files', {
          params: {
            code: [],
          },
        })
          .then((response) => {
            const translations: Translation = {};

            if (isDebugMode) {
              response.data.data.forEach((translation: ResponseTranslation) => {
                translations[translation.code] = 'xxxxxxx';
              });
            } else {
              response.data.data.forEach((translation: ResponseTranslation) => {
                translations[translation.code] = translation.value;
              });
            }

            const dataToCache = {
              expiry: currentTime + expiryTime,
              translations,
            };

            localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
            callback(null, translations);
          })
          .catch((err) => {
            callback(err);
          });
      },

      type: 'backend',
    })
    .use(initReactI18next)
    .init({
      debug: isDebugMode,
      interpolation: {
        escapeValue: false,
      },
      lng: userLanguage,
      missingKeyHandler: (language, ns, key, fallbackValue) => {
        if (isDebugMode) {
          const cachedData = localStorage.getItem(cacheKey);
          const translations = cachedData ? JSON.parse(cachedData).translations : {};

          translations[key] = 'xxxxxxx';

          const dataToCache = {
            expiry: Date.now() + 24 * 60 * 60 * 1000, // 24 hours
            translations,
          };

          localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
        }

        return isDebugMode ? 'xxxxxxx' : fallbackValue || key;
      },
      saveMissing: isDebugMode,
    });
}
