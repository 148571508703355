import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  deleteUserPreference,
  setUserPreferenceDefault,
  updateUserPreference,
} from 'src/store/userSlice';
import { useAppDispatch } from 'src/utilities/hooks/useAppDispatch';
import { useAppSelector } from 'src/utilities/hooks/useAppSelector';

type LegacyActiveFilters = Record<string, string>;
type ActiveFilters = { [index: string]: string; label: string; textValue: string }[];
type OtherPreferences = Record<string, boolean | number | string>;
type PreferenceValue = string | ActiveFilters | LegacyActiveFilters | OtherPreferences | string[];
type SavedPreferenceValue = PreferenceValue | undefined;
export type Preference = { loading: boolean; value: PreferenceValue };

export function isString(value: PreferenceValue): value is string {
  return typeof value === 'string';
}

export function usePreference(
  code: string,
  defaultValue: string | string[],
): [Preference, Dispatch<SetStateAction<Preference>>] {
  const dispatch = useAppDispatch();
  const cookie = useAppSelector((state) => state.user.cookie);
  const userID = useAppSelector((state) => state.user.details.id);
  const loggedIn = userID && cookie;

  const savedPreferenceValue = useAppSelector(
    (state) => state.user.preferences[code]?.value,
  ) as SavedPreferenceValue;
  const [preference, setPreference] = useState(() => computePreference(savedPreferenceValue));

  const clientValue = useMemo(() => preference.value, [preference.value]);
  const isClientPreferenceDifferent = useMemo(
    () => savedPreferenceValue !== clientValue,
    [clientValue, savedPreferenceValue],
  );

  function computePreference(savedPreferenceValue: SavedPreferenceValue) {
    const savedPreference: Preference = { loading: false, value: defaultValue };

    const doesCodeStartWithJob = code.slice(3) === 'job';
    const doesCodeEndWithSer = code.slice(-3) === 'search';
    const isPreferenceLegacyActiveFilters =
      doesCodeStartWithJob &&
      doesCodeEndWithSer &&
      !!savedPreferenceValue &&
      typeof savedPreferenceValue !== 'string' &&
      !Array.isArray(savedPreferenceValue);

    if (isPreferenceLegacyActiveFilters) {
      savedPreference.value = mutateLegacyActiveFilters(
        savedPreferenceValue as LegacyActiveFilters,
      );
    } else if (savedPreferenceValue !== undefined) savedPreference.value = savedPreferenceValue;

    return savedPreference;
  }

  function mutateLegacyActiveFilters(legacyActiveFilters: LegacyActiveFilters): ActiveFilters {
    // Mutate from object to array of objects
    return Object.keys(legacyActiveFilters).map((jobFieldAlias) => ({
      [jobFieldAlias]: legacyActiveFilters[jobFieldAlias],
      label: jobFieldAlias,
      textValue: jobFieldAlias,
    }));
  }

  useEffect(() => {
    if (loggedIn) {
      if (!isClientPreferenceDifferent) return;

      const isClientPreferenceDeleted =
        (Array.isArray(clientValue) && clientValue.length === 0) || clientValue === '';

      if (isClientPreferenceDeleted) {
        if (savedPreferenceValue) {
          // Delete savedPreferenceValue and use defaultValue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(deleteUserPreference({ code, defaultValue }));
        } else dispatch(setUserPreferenceDefault({ code, defaultValue }));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else dispatch(updateUserPreference({ code, value: clientValue }));
    }
  }, [clientValue]);

  useEffect(() => {
    if (savedPreferenceValue && isClientPreferenceDifferent) {
      setPreference({
        loading: false,
        value: savedPreferenceValue,
      });
    }
  }, [savedPreferenceValue]);

  return [preference, setPreference];
}
