import { MouseEvent, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/Button';
import { useRouteParams } from '../../../../utilities/hooks';
import { SubMenu, SubMenuItemType } from '../../../SubMenu';
import { WaveIcon } from '../../../WaveIcon';

type NewJobButtonProps = {
  code: string;
  subActions: Array<{ code: string; text: string }>;
  text: string;
};
export function NewJobButton({ code, subActions, text }: NewJobButtonProps) {
  const { jobId, jobType } = useRouteParams();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const subMenuActions = useMemo<SubMenuItemType[]>(() => {
    return subActions?.map(({ code, text }) => ({
      code,
      icon: (
        <WaveIcon
          className={`color-${code}`}
          code="sub-jobs-action-bar-new-job-sub-menu"
          fontSize="small"
        />
      ),
      onClick: () =>
        navigate(
          `/jobs-job-${code}/new/job?mode=sub&copyFromJobId=${jobId}&copyFromJobType=${jobType}`,
        ),
      text,
    }));
  }, [subActions?.length]);

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    subActions ? handleOpenSubMenu(event) : navigate(`/jobs-job-${jobType}/new/job`);
  }

  function handleOpenSubMenu(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setIsSubMenuOpen(true);
  }

  return (
    <>
      <Button
        onClick={handleOnClick}
        startIcon={<WaveIcon code={code} />}
        sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
      >
        {text}
      </Button>

      {isSubMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          anchorOriginHorizontalSubitem="left"
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={subMenuActions}
          onClose={handleCloseSubMenu}
          open
          transformOriginHorizontalSubitem="right"
          transformOriginVerticalSubitem="top"
        />
      )}
    </>
  );
}
