import { MouseEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { t } from 'i18next';

import { Avatar, Box, Button, Typography } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Option } from 'src/components/RHF/Dropdown/Dropdown';
import { useUpdateActiveClientMutation } from 'src/features/Frame/components/UserMenu/UserMenu.service';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { getUserDetails, logout } from 'src/store/userSlice';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions';
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
  usePreference,
} from 'src/utilities/hooks';

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const avatarUrl = useAppSelector((state) => state.user.details.avatar_url);
  const [clientIdPreference] = usePreference('sys.mid', '');
  const clients = useAppSelector((state) => state.user.clients);
  const [, setClipboardData] = useLocalStorage('clipboard', []);
  const department = useAppSelector((state) => state.user.details.department);
  const dispatch = useAppDispatch();
  const firstName = useAppSelector((state) => state.user.details.firstname);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const lastName = useAppSelector((state) => state.user.details.lastname);
  const navigate = useNavigate();
  const [updateActiveClient] = useUpdateActiveClientMutation();

  const {
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      activeClient: {
        label: clients[clientIdPreference.value as string],
        value: clientIdPreference.value,
      },
    },
  });

  const userItems = useMemo(() => {
    return [
      {
        code: 'profile',
        icon: <WaveIcon code="account-circle" color="primary" />,
        onClick: () => {
          handleCloseSubMenu();
          navigate('/user-profile', {
            state: { code: 'user_menu_profile_button', name: 'User Profile' },
          });
        },
        text: 'user_menu_profile_button',
      },
      {
        code: 'settings',
        icon: <WaveIcon code="settings" color="primary" />,
        onClick: () => {
          handleCloseSubMenu();
          navigate('/user-settings', {
            state: { code: 'user_menu_settings_button', name: 'User Settings' },
          });
        },
        text: 'user_menu_settings_button',
      },
      {
        code: 'switchClient',
        icon: <WaveIcon code="business" color="primary" />,
        onClick: () => {
          return;
        },
        text: (
          <Autocomplete
            control={control}
            disableClearable
            isFetching={isDirty}
            label={t('user_menu_active_client_button', 'Active Client')}
            name={'activeClient'}
            onChange={async (e, value) => {
              const clientId = (value as Option).value;

              await updateActiveClient({ clientId })
                .unwrap()
                .then(async () => {
                  await dispatch(getUserDetails());
                  dispatch(workflowApi.util.invalidateTags(['Workflow']));
                  handleCloseSubMenu();
                  navigate('/jobs-hom-dash');
                  reset({
                    activeClient: {
                      label: clients[clientId],
                      value: clientId,
                    },
                  });
                })
                .catch(() => {
                  reset();
                });
            }}
            options={sortArrayOfObjectsByKey({
              array: Object.entries(clients).map(([clientId, clientName]) => {
                return {
                  label: clientName,
                  value: clientId,
                };
              }),
              key: 'label',
            })}
            type="select"
          />
        ),
      },
      {
        code: 'logout',
        icon: <WaveIcon code="exit-app" color="primary" />,
        onClick: handleLogout,
        text: 'user_menu_logout_button',
      },
    ];
  }, [clientIdPreference.value, isDirty]);

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setSubMenuOpen(false);
  }

  function handleLogout() {
    setClipboardData([]);
    dispatch(logout(''));
  }

  function handleOpenSubMenu({ currentTarget }: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(currentTarget);
    setAnchorElWidth(currentTarget.offsetWidth);
    setSubMenuOpen(true);
  }

  return (
    <>
      <Button onClick={handleOpenSubMenu} sx={{ display: 'flex', gap: 1 }}>
        <Avatar
          alt="User Avatar"
          src={`${import.meta.env.VITE_API}${avatarUrl}`}
          sx={{ bgcolor: 'filler.main' }}
        >
          <Typography color="filler.contrastText" variant="inherit">
            {`${firstName.charAt(0)}${lastName.charAt(0)}`}
          </Typography>
        </Avatar>

        <Box
          sx={{
            color: 'primary.contrastText',
            lineHeight: 1,
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          <Typography fontWeight="bold">{`${firstName} ${lastName}`}</Typography>

          <Typography variant="caption">{department}</Typography>
        </Box>

        <WaveIcon
          code="expand-more"
          fontSize="small"
          sx={{
            color: 'primary.contrastText',
          }}
        />
      </Button>

      <SubMenu
        anchorEl={anchorEl}
        anchorElMinWidth={anchorElWidth}
        className="filler-bg user-popover"
        items={userItems}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
      />
    </>
  );
}
