import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { useFlagDialog } from 'src/features/JobForm/components/FlagDialog/useFlagDialog';
import { Flag } from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';

type FlagDialogProps = {
  flag: Flag;
  isOpen: boolean;
  onClose: (isSubmitting: boolean) => void;
};

export function FlagDialog({ flag: { code, domain, text }, isOpen, onClose }: FlagDialogProps) {
  const { t } = useTranslation();
  const { control, isSubmitting, onSubmit, options } = useFlagDialog({
    code,
    domain,
    isOpen,
    onClose,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={() => onClose(isSubmitting)}
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <WaveDialogTitle
        title={
          <>
            {text}

            <WaveIcon
              code={`job-form-action-bar-flags-flag-dialog-${code}`}
              sx={{
                color:
                  code === 'onhold'
                    ? 'warning.main'
                    : code === 'resume' || code === 'revive'
                    ? 'success.main'
                    : 'error.main',
                fontsize: 'small',
              }}
            />
          </>
        }
      />

      <Box component="form" onSubmit={onSubmit} sx={{ bgcolor: 'white' }}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {code === 'onhold' || code == 'cancel' ? (
            <Autocomplete
              control={control}
              disabled={isSubmitting}
              label={t('reason', 'Reason')}
              multiple
              name="reason"
              options={options}
            />
          ) : null}

          <TextField
            control={control}
            disabled={isSubmitting}
            label={t('lib.msg', 'Comment')}
            name="comment"
            type="memo"
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            <Trans i18nKey="lib.confirm">Cancel</Trans>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
