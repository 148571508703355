/* eslint-disable */
// @ts-nocheck

import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDeleteJobMutation } from 'src/features/JobsTable/JobsTable.service';
import { Approval } from 'src/features/Approvals/Approvals.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { API } from 'src/utilities/api';
import {
  useFirstRender,
  usePreference,
  usePreferencePrefix,
  useRouteParams,
} from 'src/utilities/hooks';
import { useLocation } from 'react-router-dom';

export type ClickRowCheckboxParameters = { e: ChangeEvent<HTMLInputElement>; rowId: number };
export type Columns = { field: string; title: string }[];
export type Deadline = Record<string, 'due' | 'late' | undefined>;
export type Deadlines = Record<string, Deadline>;
export type IdsOfExpandedSubJobs = number[];
export type jobIdsWithSubJobs = number[];
export type OpenConfirmationDialogParameters = { jobId: number; jobType: string };
export type RowTS = Record<string, string> & {
  apl: Approval;
  jobid: number;
  webstatus: number;
};
export type Rows = RowTS[];
export type SelectedRows = number[];
export type TotalRowCount = number;

export function useJobsTable() {
  const { age, isSubJobs, jobId, jobType, preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });
  const { jobType: assign_src } = useRouteParams();
  const { pathname } = useLocation();

  const [activeFiltersPreference] = usePreference(`${preferencePrefix}.search`, []);
  const [approvalTasks, setApprovalTasks] = useState([]);
  const [approvalTaskPreference] = usePreference(`${preferencePrefix}.show-approval-tasks`, '1');
  const [areActiveFiltersVisible, setAreActiveFiltersVisible] = useState(true);
  const [bulkEditType, setBulkEditType] = useState('');
  const [columns, setColumns] = useState<Columns>([]);
  const [columnsPreference] = usePreference(`${preferencePrefix}.columns`, '');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deadlines, setDeadlines] = useState<Deadlines>({});
  const [deleteJobID, setDeleteJobID] = useState(0);
  const [deleteJobType, setDeleteJobType] = useState('');
  const dispatch = useDispatch();
  const [expandAllSubJobs, setExpandAllSubJobs] = useState(false);
  const firstRender = useFirstRender();
  const [idsOfExpandedSubJobs, setIdsOfExpandedSubJobs] = useState<IdsOfExpandedSubJobs>([]);
  const [jobIdsWithSubJobs, setJobIdsWithSubJobs] = useState<jobIdsWithSubJobs>([]);
  const [jobTasks, setJobTasks] = useState([]);
  const [jobTasksPreference] = usePreference(`${preferencePrefix}.show-job-tasks`, '1');
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [myBackupUserActive, setMyBackupUserActive] = useState(false);
  const [orderByPreference] = usePreference(`${preferencePrefix}.order`, 'jobid');
  const [originalRowCount, setOriginalRowCount] = useState(0);
  const [originalRows, setOriginalRows] = useState([]);
  const [pagePreference, setPagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');
  const [pageHistory, setPageHistory] = useState(parseInt(pagePreference.value));
  const [rows, setRows] = useState<Rows>([]);
  const [rowsPerPagePreference] = usePreference(`${preferencePrefix}.rowsPerPage`, '25');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [statusChangeTasks, setStatusChangeTasks] = useState([]);
  const [statusChangeTasksPreference] = usePreference(
    `${preferencePrefix}.show-status-change-tasks`,
    '1',
  );
  const [teammateID, setTeammateID] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState<TotalRowCount>(0);
  const [userIBackupActive, setUserIBackupActive] = useState(false);

  const [deletingJob, { isSuccess: deleteJobSuccessful }] = useDeleteJobMutation({
    fixedCacheKey: 'shared-delete-job',
  });

  const isGlobalSearch = jobType === 'search';
  const isJobTypeDash = jobType === 'dash';

  async function deleteJob() {
    deletingJob({
      age,
      jobid: deleteJobID,
      src: deleteJobType,
    })
      .unwrap()
      .then((response) => {
        dispatch(
          openWaveSnack({
            message: response.message,
            type: 'success',
          }),
        );
        setConfirmationDialogOpen(false);
      });
  }

  async function getJobs() {
    setLoadingJobs(true);

    await API.get(computeGetJobsEndpoint(), {
      onError: ({ message }) => {
        dispatch(
          openWaveSnack({
            message: message,
            type: 'error',
          }),
        );

        setLoadingJobs(false);
      },
      onSuccess: ({
        columns,
        count: totalRowCount,
        deadlines,
        rows,
        sub_jobs: jobIdsWithSubJobs,
        totals,
      }) => {
        setApprovalTasks(totals?.approval_tasks || []);
        setColumns(columns);
        setDeadlines(deadlines);
        setJobIdsWithSubJobs(jobIdsWithSubJobs || []);
        setJobTasks(totals?.job_tasks || []);
        setLoadingJobs(false);
        setOriginalRowCount(totalRowCount);
        setOriginalRows(rows);
        setRows(rows);
        setStatusChangeTasks(totals?.status_change_tasks || []);
        setTotalRowCount(totalRowCount);
      },
      params: {
        age,
        archive: isSubJobs || null,
        dashboard_user_id: isJobTypeDash && teammateID ? teammateID : null,
        src: jobType,
        ...(pathname.includes('assign') && {
          assign_jobid: jobId,
          assign_src,
        }),
      },
    });
  }

  function computeGetJobsEndpoint() {
    if (isJobTypeDash) return '/dashboard';
    if (isGlobalSearch) return '/globalsearch';
    if (isSubJobs) return `jobs/${jobId}/subjobs`;

    return '/jobs/all';
  }

  function handleBulkEditSuccess() {
    getJobs();
    setBulkEditType('');
    setSelectedRows([]);
    dispatch(
      openWaveSnack({
        message: 'All jobs were updated successfully.',
        type: 'success',
      }),
    );
  }

  function handleCancelBulkEdit() {
    setBulkEditType('');
    setSelectedRows([]);
  }

  function handleChangePageSearch(e: ChangeEvent<HTMLInputElement>) {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm === '') {
      setRows(originalRows);
      setTotalRowCount(originalRowCount);
    } else {
      const rows = originalRows.filter((row) =>
        Object.values(row).some((column) =>
          column?.toString().toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );

      setRows(rows);
      setTotalRowCount(rows.length);
    }
  }

  function handleClickRowCheckbox({ e, rowId }: ClickRowCheckboxParameters) {
    e.stopPropagation();

    setSelectedRows((previousSelectedRows) =>
      previousSelectedRows.includes(rowId)
        ? previousSelectedRows.filter((row) => row !== rowId)
        : [...previousSelectedRows, rowId],
    );
  }

  function handleClickRowRadio(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    setSelectedRows([parseInt(e.target.value)]);
  }

  function handleCloseConfirmationDialog() {
    setConfirmationDialogOpen(false);
    setDeleteJobID(0);
    setDeleteJobType('');
  }

  function handleExpandAllSubJobs() {
    setExpandAllSubJobs(!expandAllSubJobs);

    if (expandAllSubJobs) setIdsOfExpandedSubJobs([]);
    else setIdsOfExpandedSubJobs(jobIdsWithSubJobs);
  }

  function handleToggleSelectAllRows({ target: { checked } }) {
    setSelectedRows(checked ? rows.map((row) => row.jobid) : []);
  }

  function handleOpenConfirmationDialog({ jobId, jobType }: OpenConfirmationDialogParameters) {
    setConfirmationDialogOpen(true);
    setDeleteJobID(jobId);
    setDeleteJobType(jobType);
  }

  useEffect(() => {
    if (activeFiltersPreference.value) {
      // Clear searchTerm and selectedRows because when activeFiltersPreference changes you will have a new page of results
      setSearchTerm('');
      setSelectedRows([]);
    }
  }, [activeFiltersPreference.value]);

  useEffect(() => {
    getJobs();
  }, [
    activeFiltersPreference.value,
    age,
    approvalTaskPreference.value,
    columnsPreference.value,
    jobTasksPreference.value,
    jobType,
    myBackupUserActive,
    orderByPreference.value,
    pagePreference.value,
    rowsPerPagePreference.value,
    statusChangeTasksPreference.value,
    teammateID,
    userIBackupActive,
  ]);

  useEffect(() => {
    if (!firstRender) {
      setPagePreference({ ...pagePreference, value: '0' });
      setSelectedRows([]);
    }
  }, [
    approvalTaskPreference.value,
    jobTasksPreference.value,
    myBackupUserActive,
    statusChangeTasksPreference.value,
    userIBackupActive,
  ]);

  useEffect(() => {
    if (deleteJobSuccessful) getJobs();
  }, [deleteJobSuccessful]);

  useEffect(() => {
    if (!firstRender) {
      setSelectedRows([]);

      if (teammateID !== 0) {
        setPagePreference({ ...pagePreference, value: '0' });
        if (!activeFiltersPreference.value.length) {
          setPageHistory(parseInt(pagePreference.value));
        }
      } else {
        setPagePreference({ ...pagePreference, value: pageHistory.toString() });
      }
    }
  }, [teammateID]);

  return {
    approvalTasks,
    areActiveFiltersVisible,
    bulkEditType,
    columns,
    confirmationDialogOpen,
    deadlines,
    deleteJob,
    deleteJobID,
    expandAllSubJobs,
    handleBulkEditSuccess,
    handleCancelBulkEdit,
    handleChangePageSearch,
    handleClickRowCheckbox,
    handleClickRowRadio,
    handleCloseConfirmationDialog,
    handleExpandAllSubJobs,
    handleOpenConfirmationDialog,
    handleToggleSelectAllRows,
    idsOfExpandedSubJobs,
    jobIdsWithSubJobs,
    jobTasks,
    jobType,
    loadingJobs,
    myBackupUserActive,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setBulkEditType,
    setExpandAllSubJobs,
    setIdsOfExpandedSubJobs,
    setMyBackupUserActive,
    setPageHistory,
    setSelectedRows,
    setTeammateID,
    setUserIBackupActive,
    statusChangeTasks,
    teammateID,
    totalRowCount,
    userIBackupActive,
    getJobs,
  };
}
