import { ChangeEvent, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Button } from 'src/components/Button';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { currentDate } from '../../../../utilities/constants';
import { useLazyGetDownloadLinkQuery } from '../../ShoppingCart.service';
import { Recipient } from '../Recipient';

type SendEmailDialogProps = {
  shouldShowLinkExpiration?: boolean;
  onDialogClose: () => void;
  isOpen: boolean;
};

export function SendEmailDialog({
  isOpen,
  onDialogClose,
  shouldShowLinkExpiration,
}: SendEmailDialogProps) {
  const [getDownloadLink] = useLazyGetDownloadLinkQuery();
  const [expirationLabel, setExpirationLabel] = useState('Link Expiration Date');
  const [expirationDate, setExpirationDate] = useState<Date | null>(currentDate);
  const [maxDate, setMaxDate] = useState(currentDate);
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState(['']);
  const [subject, setSubject] = useState('');

  function handleAddRecipient() {
    setRecipients([...recipients, '']);
  }

  function handleLinkExpirationChange(value: Date | null) {
    setExpirationDate(value);
  }

  function handleMessageChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setMessage(e.target.value);
  }

  function handleRemoveRecipient() {
    setRecipients(recipients.slice(0, -1));
  }

  function handleSubjectChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setSubject(e.target.value);
  }

  useEffect(() => {
    getDownloadLink()
      .unwrap()
      .then((res) => {
        if (res.expiration_label) {
          setExpirationLabel(res.expiration_label);
        }

        if (res.expiration_date) {
          setExpirationDate(new Date(res.expiration_date));
          setMaxDate(new Date(res.expiration_date));
        }

        setSubject(res.email.subject);
        setMessage(res.email.message);
      });
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" onClose={onDialogClose} open={isOpen}>
      <WaveDialogTitle title={<Trans>lib.send_link_email</Trans>} />

      <DialogContent>
        <Stack py="10px" spacing={2}>
          {shouldShowLinkExpiration && (
            <Grid container>
              <Grid xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={expirationLabel}
                    maxDate={maxDate}
                    minDate={currentDate}
                    onChange={handleLinkExpirationChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    )}
                    value={expirationDate}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}

          {recipients.map((recipient, index) => (
            <Recipient
              index={index}
              key={index}
              onAddRecipient={handleAddRecipient}
              onRemoveRecipient={handleRemoveRecipient}
            />
          ))}

          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label={<Trans>lib.sbj</Trans>}
            onChange={handleSubjectChange}
            size="small"
            value={subject}
            variant="outlined"
          />

          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label={<Trans>sys.message</Trans>}
            multiline
            onChange={handleMessageChange}
            rows={11}
            size="small"
            value={message}
            variant="outlined"
          />
        </Stack>
      </DialogContent>

      <DialogActions className="space-between">
        <Button color="warning" onClick={onDialogClose}>
          lib.cancel
        </Button>

        <Button onClick={onDialogClose}>lib.send</Button>
      </DialogActions>
    </Dialog>
  );
}
