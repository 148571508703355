import { Trans } from 'react-i18next';

import { t } from 'i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { WaveDialogTitle } from 'src/components/WaveDialogTitle';

type CompareReplaceDeleteTemplateDialogProps = {
  isOpen: boolean;
  onDeleteTemplate: () => void;
  onCloseDeleteTemplateDialog: () => void;
  templateName: string;
  isDeleteTemplateLoading: boolean;
};

export function CompareReplaceDeleteTemplateDialog({
  isDeleteTemplateLoading,
  isOpen,
  onCloseDeleteTemplateDialog,
  onDeleteTemplate,
  templateName,
}: CompareReplaceDeleteTemplateDialogProps) {
  return (
    <Dialog fullWidth open={isOpen}>
      <WaveDialogTitle
        onClose={onCloseDeleteTemplateDialog}
        title={t('confirmation_dialog_title', 'Confirmation')}
      />

      <Box component={DialogContent} marginTop={3}>
        {`${t(
          'delete_template_confirmation',
          'Are you sure you want to delete the template',
        )} "${templateName}"?`}
      </Box>

      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingBottom={2}
          paddingLeft={2}
          paddingRight={2}
        >
          <Box marginRight={2}>
            <Button color="warning" onClick={onCloseDeleteTemplateDialog}>
              <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
            </Button>
          </Box>

          <LoadingButton loading={isDeleteTemplateLoading} onClick={onDeleteTemplate}>
            <Trans i18nKey="lib.confirm">Cancel</Trans>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
