import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import {
  AddRevisorPayload,
  AddRevisorToNewApprovalPayload,
} from 'src/components/AddRevisorForm/AddRevisorForm';
import { determineRevisorId } from 'src/features/JobForm/helperFunctions';
import { useJobStatus } from 'src/features/JobForm/hooks';
import {
  ApprovalSection,
  ChangeStatusRequest,
  ExternalUserRevisor,
  GroupRevisor,
  StartApprovalData,
  StepTransformed,
  useChangeStatusMutation,
  useGetCloseTasksQuery,
  useGetEmailNotificationsQuery,
  useGetOptionsQuery,
  useGetStartApprovalDataQuery,
  useGetStartTasksDataQuery,
  UserRevisor,
} from 'src/features/JobForm/JobForm.service';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { FieldValues, jobApi, Options } from 'src/pages/Job/Job.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

type AddRevisorData = {
  isOpen: boolean;
  templateEventId: number;
};
type UseStepDialogProps = Pick<StepTransformed, 'id' | 'stepFlags'> & {
  onClose: () => void;
};

export function useStepDialog({ id, onClose, stepFlags }: UseStepDialogProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { age, jobId, jobType } = useRouteParams();
  const jobStatus = useJobStatus();
  const jobStatusDisplayValue = jobStatus?.displayValue;
  const jobStatusValue = jobStatus?.value;

  const [addRevisorData, setAddRevisorData] = useState<AddRevisorData>();
  const [startApprovalData, setStartApprovalData] = useState<StartApprovalData>();

  const [changeStatus] = useChangeStatusMutation();

  const {
    control,
    formState: { isSubmitting },
    getValues,
    handleSubmit: reactHookFormHandleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onSubmit',
  });

  const hasEmailNotifications = stepFlags.includes('email_notifications');
  const isCommentOptional = stepFlags.includes('skip_comments');
  const shouldAmendmentRequest = stepFlags.includes('amendment_request');
  const shouldArchive = stepFlags.includes('archive');
  const shouldCloseTask = stepFlags.includes('close_task');
  const shouldStartApproval = stepFlags.includes('start_approval');
  const shouldStartTask = stepFlags.includes('start_task');

  const { data: closeTasks = [], isFetching: areCloseTasksFetching } = useGetCloseTasksQuery(
    { age, jobType, stepId: id },
    { skip: !shouldCloseTask },
  );
  const { data: emailNotifications = [], isFetching: areEmailNotificationsFetching } =
    useGetEmailNotificationsQuery({ jobId, jobType, stepId: id }, { skip: !hasEmailNotifications });
  const {
    data: rtkQueryStartApprovalData = { sections: [] as ApprovalSection[] } as StartApprovalData,
    isFetching: isRtkQueryStartApprovalDataFetching,
  } = useGetStartApprovalDataQuery(
    { age, jobId, jobType, stepId: id },
    { skip: !shouldStartApproval },
  );
  const { data: startTasksData = [], isFetching: isStartTasksDataFetching } =
    useGetStartTasksDataQuery({ jobId, jobType, stepId: id }, { skip: !shouldStartTask });
  const { data: options = [], isFetching: isOptionsFetching } = useGetOptionsQuery(
    { domain: 'cu1' },
    { skip: !shouldAmendmentRequest },
  );

  const isLoading =
    areCloseTasksFetching ||
    areEmailNotificationsFetching ||
    isRtkQueryStartApprovalDataFetching ||
    isStartTasksDataFetching ||
    isOptionsFetching;

  function handleAddRevisor(parameters: AddRevisorPayload) {
    const { externalUsers, groups, users } = parameters as AddRevisorToNewApprovalPayload;

    const newRevisors = [...(externalUsers || []), ...(groups || []), ...(users || [])] as (
      | ExternalUserRevisor
      | GroupRevisor
      | UserRevisor
    )[];

    newRevisors.forEach((revisor) => {
      setValue(`approvalRevisor${determineRevisorId(revisor)}`, true);
    });

    setStartApprovalData((previousStartApprovalData) => {
      if (!!previousStartApprovalData && !!addRevisorData) {
        const _startApprovalData = { ...previousStartApprovalData };

        _startApprovalData.sections = _startApprovalData.sections.map((section) => ({
          ...section,
          templates: section.templates.map((template) =>
            template.event_id === addRevisorData.templateEventId
              ? {
                  ...template,
                  revisors: sortArrayOfObjectsByKey({
                    array: [
                      ...template.revisors,
                      ...newRevisors.map((revisor) => ({
                        ...revisor,
                        email_template_id: template.event_id.toString(),
                      })),
                    ],
                    key: 'position',
                  }) as (ExternalUserRevisor | GroupRevisor | UserRevisor)[],
                }
              : template,
          ),
        }));

        return _startApprovalData;
      }
    });
    handleClickBack();
  }

  function handleClickAddRevisor(templateEventId: number) {
    setAddRevisorData({ isOpen: true, templateEventId });
  }

  function handleClickBack() {
    setAddRevisorData(undefined);
  }

  async function handleSubmit(formData: any) {
    if (jobStatusValue) {
      const excludedActions: number[] = [];
      const payload: ChangeStatusRequest = {
        age,
        closeTasks: closeTasks.map(({ code }) => code),
        jobId,
        jobType,
        status: jobStatusValue,
        stepId: id,
      };
      const _startApprovalData = {
        code: startApprovalData?.code,
        sections: startApprovalData?.sections.map((section) => ({ ...section })),
        type: startApprovalData?.type,
      } as StartApprovalData;
      const _startTasksData = startTasksData.map((task) => {
        return { ...task, revisors: [...task.revisors] };
      });

      Object.entries(formData).map((input) => {
        const inputKey = input[0];
        const inputValue = input[1] as boolean | Options | string;

        if (
          inputKey === 'emailNotificationsComment' &&
          inputValue &&
          typeof inputValue === 'string'
        ) {
          payload.comment = inputValue;
        } else if (
          inputKey.includes('emailNotification') &&
          inputKey !== 'emailNotificationsComment' &&
          !inputValue
        ) {
          excludedActions.push(parseInt(inputKey.replace('emailNotification', '')));
        } else if (
          inputKey.includes('startApprovalComment') &&
          inputValue &&
          typeof inputValue === 'string'
        ) {
          _startApprovalData.sections = _startApprovalData.sections.map((section) => {
            const sectionCommentName = inputKey.replace('startApprovalComment', '');

            return sectionCommentName === section.name
              ? { ...section, comment: inputValue }
              : section;
          });
        } else if (inputKey.includes('approvalTemplate') && !inputValue) {
          const templateEventId = parseInt(inputKey.replace('approvalTemplate', ''));

          _startApprovalData.sections = _startApprovalData.sections.map((section) => ({
            ...section,
            templates: section.templates.filter(
              ({ event_id: eventId }) => eventId !== templateEventId,
            ),
          }));
        } else if (inputKey.includes('approvalRevisor') && !inputValue) {
          const revisorId = inputKey.replace('approvalRevisor', '');

          _startApprovalData.sections = _startApprovalData.sections.map((section) => ({
            ...section,
            templates: section.templates.map((template) => ({
              ...template,
              revisors: template.revisors.filter(
                (revisor) => determineRevisorId(revisor) !== revisorId,
              ),
            })),
          }));
        }

        // TODO: handle multiple tasks at once instead of only _startTasksData[0]
        else if (
          inputKey.includes('startTaskComment') &&
          inputValue &&
          typeof inputValue === 'string'
        ) {
          _startTasksData[0].comment = inputValue;
        } else if (inputKey.includes('startTaskRevisor') && !inputValue) {
          _startTasksData[0].revisors.forEach((revisor, index) => {
            if (determineRevisorId(revisor) === inputKey.replace('startTaskRevisor', '')) {
              _startTasksData[0].revisors.splice(index);
            }
          });
        } else if (inputKey === 'comment' && inputValue && typeof inputValue === 'string') {
          payload.comment = inputValue;
        } else if (
          inputKey === 'amendmentReasons' &&
          typeof inputValue !== 'boolean' &&
          typeof inputValue !== 'string'
        ) {
          payload.amendmentReasons = inputValue.map(({ value }) => value.toString());
        }
      });

      if (excludedActions.length) {
        payload.excludedActions = excludedActions;
      }

      if (_startApprovalData.sections.length) {
        payload.approval = _startApprovalData;
      }

      if (_startTasksData.length) {
        payload.tasks = _startTasksData;
      }

      await changeStatus(payload)
        .unwrap()
        .then(({ message }) => {
          dispatch(jobApi.util.invalidateTags(['Job']));
          dispatch(workflowApi.util.invalidateTags(['Deadline', 'Workflow']));
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
          onClose();

          if (shouldArchive) {
            navigate(`/jobs-arc-${jobType}/${jobId}/job`);
          }
        })
        .catch(({ message }) =>
          dispatch(
            openWaveSnack({
              message,
              type: 'error',
            }),
          ),
        );
    }
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.stopPropagation();

    return reactHookFormHandleSubmit(handleSubmit)(e);
  }

  useEffect(() => {
    if (
      !areEmailNotificationsFetching &&
      !isRtkQueryStartApprovalDataFetching &&
      !isStartTasksDataFetching
    ) {
      const defaultValues: FieldValues = {};

      if (emailNotifications.length) {
        defaultValues.emailNotificationsComment = '';

        emailNotifications.forEach(({ checkbox: { checked }, eventActionId }) => {
          defaultValues[`emailNotification${eventActionId}`] = checked;
        });
      }

      if (rtkQueryStartApprovalData.sections.length) {
        defaultValues['selectAll'] = true;
        rtkQueryStartApprovalData.sections.forEach(({ name, templates }) => {
          defaultValues[`startApprovalComment${name}`] = '';

          templates.forEach(({ event_id: eventId, revisors }, index) => {
            defaultValues[`approvalTemplate${eventId}`] = index === 0;

            revisors.forEach((revisor) => {
              const {
                checkbox: { checked: isChecked },
              } = revisor;

              defaultValues[`approvalRevisor${determineRevisorId(revisor)}`] = isChecked;
            });
          });
        });
      }

      if (startTasksData.length) {
        defaultValues['selectAll'] = true;
        startTasksData.forEach(({ event_id: eventId, revisors }) => {
          defaultValues[`startTaskComment${eventId}`] = '';

          revisors.forEach((revisor) => {
            const {
              checkbox: { checked: isChecked },
            } = revisor;

            defaultValues[`startTaskRevisor${determineRevisorId(revisor)}`] = isChecked;
          });
        });
      }

      if (
        !emailNotifications.length ||
        !rtkQueryStartApprovalData.sections.length ||
        !startTasksData.length
      ) {
        defaultValues.comment = '';
      }

      reset(defaultValues);
    }
  }, [
    areEmailNotificationsFetching,
    isRtkQueryStartApprovalDataFetching,
    isStartTasksDataFetching,
  ]);

  useEffect(() => {
    if (!isRtkQueryStartApprovalDataFetching) {
      setStartApprovalData(rtkQueryStartApprovalData);
    }
  }, [isRtkQueryStartApprovalDataFetching]);

  useEffect(() => {
    const isSelectAll = getValues('selectAll');

    if (rtkQueryStartApprovalData.sections.length) {
      rtkQueryStartApprovalData.sections.forEach(({ templates }) => {
        templates.forEach(({ revisors }) => {
          revisors.forEach((revisor) => {
            setValue(`approvalRevisor${determineRevisorId(revisor)}`, isSelectAll);
          });
        });
      });
    }

    if (startTasksData.length) {
      startTasksData.forEach(({ revisors }) => {
        revisors.forEach((revisor) => {
          setValue(`startTaskRevisor${determineRevisorId(revisor)}`, isSelectAll);
        });
      });
    }
  }, [watch().selectAll]);

  return {
    addRevisorData,
    closeTasks,
    control,
    emailNotifications,
    handleAddRevisor,
    handleClickAddRevisor,
    handleClickBack,
    isCommentOptional,
    isLoading,
    isSubmitting,
    jobStatusDisplayValue,
    jobType,
    onSubmit,
    options,
    setValue,
    shouldAmendmentRequest,
    startApprovalData,
    startTasksData,
  };
}
