import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { openWaveSnack } from '../../../../store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from '../../../../utilities/hooks';
import { useAddCommentMutation } from '../../History.service';

type NewCommentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

type NewCommentForm = {
  message: string;
  subject: string;
};

const newCommentFormRules = {
  message: {
    required: { message: 'Required', value: true },
  },
  subject: {
    required: { message: 'Required', value: true },
  },
};

export function NewCommentDialog({ isOpen, onClose }: NewCommentDialogProps) {
  const { t } = useTranslation();
  const { age, jobId, jobType } = useRouteParams();
  const [addComment, { isLoading }] = useAddCommentMutation();
  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm<NewCommentForm>({
    mode: 'onChange',
  });

  async function onSubmit({ message, subject }: NewCommentForm) {
    addComment({
      age,
      jobid: jobId,
      message,
      src: jobType,
      subject,
    })
      .unwrap()
      .then(() => {
        dispatch(
          openWaveSnack({
            message: t('new_comment_success', 'New comment added successfully'),
            type: 'success',
          }),
        );

        onClose();
      });
  }

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={onClose} open={isOpen}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <WaveDialogTitle title={t('Add New Comment', 'Add New Comment')} />

        <DialogContent sx={{ px: 2, py: 1 }}>
          <Stack gap={2} pt={1.5}>
            <TextField
              control={control}
              disabled={isLoading}
              label={t('lib.sbj', 'Subject')}
              name="subject"
              rules={newCommentFormRules.subject}
            />

            <TextField
              control={control}
              disabled={isLoading}
              label={t('sys.message', 'Message')}
              minRows={4}
              multiline
              name="message"
              rules={newCommentFormRules.message}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between', pb: 2, px: 2 }}>
          <Button color="warning" onClick={onClose}>
            <Trans i18nKey="cancel_button">Cancel</Trans>
          </Button>

          <LoadingButton loading={isLoading} type="submit" variant="contained">
            <Trans i18nKey="confirm_button">Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
