import { useEffect } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Idle } from 'src/components/Idle';
import { ProjectAssign } from 'src/components/ProjectAssign';
import { Frame } from 'src/features/Frame';
import { AssignToProject } from 'src/pages/AssignToProject';
import { CompareFiles } from 'src/pages/CompareFiles';
import { Dashboard } from 'src/pages/Dashboard';
import { FileSearch } from 'src/pages/FileSearch';
import { GeneralFiles } from 'src/pages/GeneralFiles';
import { GeneralHelp } from 'src/pages/GeneralHelp';
import { GeneralSettings } from 'src/pages/GeneralSettings';
import { GenericIFrame } from 'src/pages/GenericIFrame';
import { Job } from 'src/pages/Job';
import { Jobs } from 'src/pages/Jobs';
import { NotFound } from 'src/pages/NotFound';
import { Reporting } from 'src/pages/Reporting';
import { UserProfile } from 'src/pages/UserProfile';
import { UserSettings } from 'src/pages/UserSettings';
import { ViewerCallback } from 'src/pages/ViewerCallback';
import { useAppSelector, usePreference } from 'src/utilities/hooks';

export function PrivateRoute() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientColors } = useTheme();
  const path = useLocation().pathname.split('/');

  const details = useAppSelector((state) => state.user.details);
  const rights = useAppSelector((state) => state.user.rights);

  const [redirectPreference] = usePreference('log.url', 'hom-dash');

  const [, age, jobType] = path[1].split('-');
  const jobId = path[2];
  const jobTab = path[3];
  let redirectPage = '';
  let missingRights = '';

  function checkAgeRights() {
    if (age === 'hom' || age === 'job') return true;

    if (age === 'global') {
      if (rights.read.includes('ser')) return true;
      else {
        missingRights = 'ser';

        return false;
      }
    }

    if (rights.read.includes(age)) return true;

    redirectPage = '/jobs-hom-dash';
    missingRights = age;

    return false;
  }

  function checkJobTabRights() {
    if (checkJobTypeRights()) {
      const jobTabRight =
        jobTab === 'assign' ? 'pro-assign' : jobTab === 'sub-jobs' ? `${jobType}-sub` : jobTab;

      if (
        jobTab === 'job' ||
        jobTab === 'det' ||
        rights.read.includes(`job-${jobTabRight}`) ||
        rights.read.includes(`job-${jobType}-${jobTabRight}`)
      ) {
        return true;
      } else {
        redirectPage = `/jobs-${age}-${jobType}/${jobId}/job`;
        missingRights = `job-${jobTabRight}`;

        return false;
      }
    }
  }

  function checkJobTypeRights() {
    if (checkAgeRights()) {
      if (jobType === 'dash' || jobType === 'search' || rights.read.includes(`job-${jobType}`))
        return true;
      else {
        redirectPage = '/jobs-hom-dash';
        missingRights = `job-${jobType}`;

        return false;
      }
    }
  }

  useEffect(() => {
    Sentry.setUser({ username: details.user });
  }, []);

  return (
    clientColors && (
      <>
        <Idle />

        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <Frame>
            <Box
              component="main"
              sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%' }}
            >
              <Routes>
                <Route element={<Navigate replace to="/jobs-hom-dash" />} path="/" />

                <Route
                  element={<Navigate replace to={`/jobs-${redirectPreference.value}`} />}
                  path="/login"
                />

                <Route
                  element={
                    checkJobTypeRights() ? (
                      age === 'hom' ? (
                        <Dashboard />
                      ) : (
                        <Jobs />
                      )
                    ) : (
                      <Navigate
                        replace
                        state={{ missingRights: missingRights }}
                        to={redirectPage}
                      />
                    )
                  }
                  path="/jobs-:age-:jobType"
                />

                <Route
                  element={<Navigate replace to={`/jobs-${age}-${jobType}/${jobId}/job`} />}
                  path="/jobs-:age-:jobType/:jobId"
                />

                <Route
                  element={
                    jobTab === 'compare' ? (
                      <CompareFiles />
                    ) : checkJobTabRights() ? (
                      jobTab === 'assign' && jobId !== 'projectBulkEdit' ? (
                        <AssignToProject />
                      ) : jobId === 'projectBulkEdit' ? (
                        <ProjectAssign />
                      ) : (
                        <Job />
                      )
                    ) : (
                      <Navigate
                        replace
                        state={{ missingRights: missingRights }}
                        to={redirectPage}
                      />
                    )
                  }
                  path="/jobs-:age-:jobType/:jobId/:tab"
                />

                <Route
                  element={
                    checkJobTabRights() ? (
                      <Job />
                    ) : (
                      <Navigate
                        replace
                        state={{ missingRights: missingRights }}
                        to={redirectPage}
                      />
                    )
                  }
                  path="/jobs-:age-:jobType/:jobId/:tab/new"
                />

                <Route
                  element={
                    rights.read.includes(`menu-${age}`) ? (
                      <GenericIFrame />
                    ) : (
                      <Navigate
                        replace
                        state={{ missingRights: `menu-${age}` }}
                        to="/jobs-hom-dash"
                      />
                    )
                  }
                  path="/additional-:tabCode"
                />

                <Route element={<Reporting />} path="/reporting" />

                <Route element={<GeneralHelp />} path="/general-help" />

                <Route element={<GeneralFiles />} path="/general-files" />

                <Route element={<GeneralSettings />} path="/general-settings" />

                <Route element={<UserProfile />} path="/user-profile" />

                <Route element={<UserSettings />} path="/user-settings" />

                <Route element={<FileSearch />} path="/file-search" />

                <Route element={<NotFound />} path="/*" />

                <Route element={<ViewerCallback />} path="/close-viewer" />
              </Routes>
            </Box>
          </Frame>
        </Box>
      </>
    )
  );
}
