import { Collapse, TableRow, TableCell, Table, TableBody } from '@mui/material';

import { Header } from '../Header';
import { LoadingHeaderAndBody } from '../LoadingHeaderAndBody';
import { Row } from '../Row';

export function SubTable({
  areJobsLoading,
  columnsLength,
  deadlines,
  open,
  rowJobType,
  rows,
  subColumns,
}) {
  return (
    <TableRow className={`status ${rowJobType}`}>
      <TableCell className="max-w-0" colSpan={columnsLength + 5}>
        {/* colSpan is + 5 for the rowId, expand/collapse, checkbox, copy and delete   */}
        <Collapse className="overflow-auto" in={open} timeout="auto" unmountOnExit>
          <Table className="sub-table" padding="normal" size="small">
            {areJobsLoading ? (
              <LoadingHeaderAndBody totalColumn={8} totalRow={10} />
            ) : (
              <>
                <Header columns={subColumns} subHeader={true} />

                <TableBody>
                  {rows.map((row, index) => (
                    <Row
                      columns={subColumns}
                      deadline={deadlines[row.jobid]}
                      key={row.jobid}
                      row={row}
                      rowId={index}
                      isSubRow={true}
                    />
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
