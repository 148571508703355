import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';

import { StyledAccordionSummary } from 'src/components/StyledAccordionSummary';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useRouteParams } from '../../../../utilities/hooks';
import {
  Approval,
  SubApprovalDetail,
  useDeleteRevisorMutation,
  useGetApprovalsQuery,
  useGetSubApprovalsQuery,
} from '../../Approvals.service';
import { DetailsTable } from '../DetailsTable';
import { MasterContent } from '../MasterContent';

type ApprovalItemProps = {
  approvalItem: Approval;
  isExpanded: boolean;
  onToggleExpand: (arg: number) => void;
};

export function ApprovalItem({ approvalItem, isExpanded, onToggleExpand }: ApprovalItemProps) {
  const { age, jobId, jobType } = useRouteParams();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [deletePosition, setDeletePosition] = useState<SubApprovalDetail>();
  const [isMasterContentOpen, setIsMasterContentOpen] = useState(false);
  const [deleteRevisor] = useDeleteRevisorMutation();
  const { isFetching: areApprovalsFetching } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });
  const { data: subApprovals, isFetching: areSubApprovalsFetching } = useGetSubApprovalsQuery(
    {
      age,
      loop_id: approvalItem.id,
    },
    { refetchOnMountOrArgChange: true, skip: !isExpanded || approvalItem.status === 'open' },
  );

  const isPhraseApproval = approvalItem.type.includes('apl-ph');
  const { t } = useTranslation();

  function handleDeleteRevisor() {
    if (deletePosition) {
      const isGroupRevisor = deletePosition.gru_id !== 0;

      deleteRevisor({
        age,
        gid: isGroupRevisor ? deletePosition.gru_id : null,
        jobid: jobId,
        loop_id: deletePosition.loop_id,
        loop_user_id: isGroupRevisor ? null : deletePosition.user_id,
        src: jobType,
        states_id: deletePosition.id,
      })
        .unwrap()
        .then(() => {
          setIsConfirmationDialogOpen(false);
        });
    }
  }

  function handleOpenDeleteConfirmationDialog(position: SubApprovalDetail) {
    setDeletePosition(position);
    setIsConfirmationDialogOpen(true);
  }

  return (
    <Accordion expanded={isExpanded || false} key={approvalItem.id}>
      <StyledAccordionSummary onClick={() => onToggleExpand(approvalItem.id)}>
        <Typography fontWeight={500}>
          {`${t('job-apl.menu', 'Approval Workflow')}: ${approvalItem.title}`}&nbsp;
        </Typography>

        <Typography>
          {`| ${t('lib.created', 'Created ')}: ${approvalItem.creation}`}&nbsp;
        </Typography>

        {approvalItem.closed ? (
          <Typography>{`| ${t('lib.close', 'Closed')}: ${approvalItem.closed}`}&nbsp;</Typography>
        ) : null}

        {approvalItem.deadline ? (
          <Typography>{`| ${t('lib.ddl', 'Deadline')}: ${approvalItem.deadline}`}&nbsp;</Typography>
        ) : null}
      </StyledAccordionSummary>

      <Box component={AccordionDetails} p={0}>
        <DetailsTable
          approvals={(approvalItem.status === 'open' ? approvalItem.approval : subApprovals) || []}
          isFetching={
            approvalItem.status === 'open' ? areApprovalsFetching : areSubApprovalsFetching
          }
          isPhraseApproval={isPhraseApproval}
          onHandleOpenMasterContent={() => setIsMasterContentOpen(true)}
          onSubApprovalDelete={handleOpenDeleteConfirmationDialog}
        />
      </Box>

      {isPhraseApproval && (
        <MasterContent
          isMasterContentOpen={isMasterContentOpen}
          onClose={() => setIsMasterContentOpen(false)}
        />
      )}

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        message={t('remove_user_group_from_approval', {
          defaultValue: 'Are you sure you want to remove {{ userGroup }} from the approval loop?',
          userGroup: deletePosition?.user || deletePosition?.group,
        })}
        onConfirmation={handleDeleteRevisor}
        onDialogClose={() => setIsConfirmationDialogOpen(false)}
      />
    </Accordion>
  );
}
