import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@mui/material';

import { WaveIcon } from '../../features/WaveIcon';

type ExpandAllButtonProps = {
  isExpanded: boolean;
  onExpandClick: () => void;
  size?: ButtonProps['size'];
  expandedIcon: string;
  collapsedIcon: string;
};

// TODO: Not sure that we need separate component for such functionality
// (single component wrapper without custom logic)
export function ExpandAllButton({
  collapsedIcon,
  expandedIcon,
  isExpanded,
  onExpandClick,
  size = 'medium',
}: ExpandAllButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onExpandClick}
      size={size}
      startIcon={<WaveIcon code={isExpanded ? expandedIcon : collapsedIcon} />}
    >
      {isExpanded ? t('lib.collapseall', 'Collapse All') : t('expand_all_button', 'Expand All')}
    </Button>
  );
}
