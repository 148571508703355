import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import axios from 'axios';

import { Button } from 'src/components/Button';
import { SubMenu } from '../../../SubMenu';
import { ViewSearchDialog } from '../ViewSearchDialog';
import { WaveIcon } from '../../../WaveIcon';

import { API } from '../../../../utilities/api';
import { computeWaveIconCodePrefix } from '../../utilities/helperFunctions';
import { useIsSubJobs } from '../../utilities/hooks';
import { openWaveSnack } from '../../../../store/waveSnackSlice';
import { useNavigate } from 'react-router-dom';

export function ActionsButton({
  code,
  onSetBulkEditType,
  selectedRows,
  subActions,
  teammateID,
  text,
}) {
  const { age, isSubJobs, jobId, jobType } = useIsSubJobs({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogInformation, setDialogInformation] = useState({});
  const dispatch = useDispatch();
  const [exportOptions, setExportOptions] = useState({});
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [viewSearchDialogOpen, setViewSearchDialogOpen] = useState(false);

  const multipleJobsSelected = selectedRows?.length > 1;
  const subMenuActions = subActions.map(({ code, sub_actions: subActions, text }) => {
    const bulkEdit = code === 'bulk';

    return {
      code,
      disabled: bulkEdit && !multipleJobsSelected,
      icon: (
        <WaveIcon
          code={`${computeWaveIconCodePrefix({
            age,
            isSubJobs,
          })}-action-bar-${code}`}
          color="primary"
          fontSize="small"
        />
      ),
      navigateIcon: <WaveIcon code="navigate-next" color="primary" fontSize="small" />,
      onClick: () => {
        handleSubActions(code.split('-'), text);
      },
      subItems: subActions?.map(({ code, text }) => ({
        code,
        text,
        onClick: () => {
          handleSubActions(code.split('-'));
        },
      })),
      text,
      tooltipBody: bulkEdit && !multipleJobsSelected && 'At least two jobs need to be selected.',
    };
  });

  function handleClickExports(codes) {
    const sendByEmail = codes[2] === 'email' ? 1 : 0;
    const type = codes[1] === 'excel' ? 'xlsx' : codes[1];

    setExportOptions({ sendByEmail, type });
  }

  function handleCloseViewSearchDialog() {
    setViewSearchDialogOpen(false);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setSubMenuOpen(false);
  }

  function handleOpenSubMenu({ currentTarget }) {
    setAnchorEl(currentTarget);
    setSubMenuOpen(true);
  }

  function handleOpenViewSearchDialog(code, text) {
    setDialogInformation({ code, text });
    setViewSearchDialogOpen(true);
  }

  function handleSubActions(codes, text) {
    if (codes[0] === 'export') handleClickExports(codes);
    if (codes[0] === 'bulk') {
      onSetBulkEditType(codes[1]);

      if (codes[1] === 'project') {
        navigate(`/jobs-job-${jobType}/projectBulkEdit/assign`, {
          state: { selectedJobIds: selectedRows, isProjectBulkEdit: codes[1] === 'project' },
        });
      }
    }
    if (codes[0] === 'saved') handleOpenViewSearchDialog(`${codes[0]}-${codes[1]}`, text);

    handleCloseSubMenu();
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (Object.keys(exportOptions).length > 0) {
      const { sendByEmail, type } = exportOptions;

      (async (cancelToken) => {
        await API.get('/jobs/download', {
          cancelToken: cancelToken,
          onError: (error) => {
            dispatch(
              openWaveSnack({
                type: 'error',
                message: error.message,
              }),
            );
          },
          onSuccess: ({ url }) => {
            if (sendByEmail === 0) {
              window.open(`${import.meta.env.VITE_API}${url}`);
            } else {
              dispatch(
                openWaveSnack({
                  type: 'success',
                  message: 'Request added to email queue',
                }),
              );
            }
          },
          params: {
            age,
            dashboard_user_id: teammateID || null,
            jobid: jobId,
            send_by_email: sendByEmail,
            src: jobType,
            type: type,
          },
        });
      })(source.token);
    }

    return () => source.cancel();
  }, [exportOptions]);

  return (
    <>
      <Button
        color="secondary"
        onClick={handleOpenSubMenu}
        startIcon={<WaveIcon code={code} />}
        sx={{ bgcolor: `${subMenuOpen ? 'secondary.dark' : ''}` }}
      >
        {text}
      </Button>

      {subMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          anchorOriginHorizontalSubitem="left"
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={subMenuActions}
          onClose={handleCloseSubMenu}
          open={subMenuOpen}
          transformOriginHorizontalSubitem="right"
          transformOriginVerticalSubitem="top"
        />
      )}

      {viewSearchDialogOpen && (
        <ViewSearchDialog
          onCloseDialog={handleCloseViewSearchDialog}
          dialogInformation={dialogInformation}
        />
      )}
    </>
  );
}
