import { useMemo, useRef, useState } from 'react';
import { useFormState } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import {
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
  useGetActionsQuery,
} from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { isOtherJobValues } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { getBookmarks } from 'src/store/bookmarksSlice';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';

export function useActions() {
  const { age, jobId } = useRouteParams();
  const { isJobFetching, jobType, values } = useJobContext();
  const { isDirty } = useFormState();

  const actionsButton = useRef<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [addBookmark] = useAddBookmarkMutation();

  const [deleteBookmark] = useDeleteBookmarkMutation();

  const { data: actions, isFetching } = useGetActionsQuery(
    { age, jobId, jobType },
    { skip: jobId === 'new' },
  );

  const actionButtons = useMemo(
    () =>
      actions?.map(({ code, text }) => ({
        code,
        icon: (
          <WaveIcon code={`job-form-action-bar-actions-${code}`} sx={{ color: 'primary.main' }} />
        ),
        onClick: () => handleFormActions(code),
        text,
      })) ?? [],
    [actions, values],
  );

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  async function handleFormActions(code: string) {
    handleCloseSubMenu();

    const main = document.getElementsByTagName('main')[0] as HTMLScriptElement;

    if (code === 'print-job') {
      main.style.setProperty('overflow', 'visible');
      window.print();
    } else if (code === 'add-bookmark') {
      setIsPerformingAction(true);

      if (isOtherJobValues(values)) {
        const keyword = values.stichw;

        await addBookmark({ age, jobId, jobType, keyword })
          .unwrap()
          .then((message) => {
            dispatch(getBookmarks());
            dispatch(
              openWaveSnack({
                message,
                type: 'success',
              }),
            );
          })
          .catch((error) => console.log(error));
      } else {
        console.warn('No keyword so unable to add bookmark');
      }

      setIsPerformingAction(false);
    } else if (code === 'remove-bookmark') {
      setIsPerformingAction(true);
      await deleteBookmark({
        jobId,
      })
        .unwrap()
        .then((message) => {
          dispatch(getBookmarks());
          dispatch(
            openWaveSnack({
              message,
              type: 'success',
            }),
          );
        })
        .catch((error) => console.log(error));
      setIsPerformingAction(false);
    } else if (code === 'reassign-project' || code === 'assign-to-project') {
      navigate(`/jobs-job-${jobType}/${jobId}/assign`);
    }

    main.style.setProperty('overflow', 'auto');
  }

  return {
    actionButtons,
    actionsButton,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isFetching: isFetching || isJobFetching,
    isPerformingAction,
    isSubMenuOpen,
  };
}
