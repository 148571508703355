import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { API } from '../../../../utilities/api';
import { sortArrayOfObjectsByKey } from '../../../../utilities/helperFunctions';
import { useIsSubJobs } from './index';
import { openWaveSnack } from '../../../../store/waveSnackSlice';

export function useFieldSelectorFields({ fieldPreference, filter }) {
  const dispatch = useDispatch();
  const { isSubJobs, jobType } = useIsSubJobs({
    forcedJobType: 'pro',
  });
  const [savedAvailableFields, setSavedAvailableFields] = useState([]);
  const [savedSelectedFields, setSavedSelectedFields] = useState([]);
  const userID = useSelector((state) => state.user.details.id);

  async function getFieldSelectorFields(cancelToken) {
    await API.get('/fields/all', {
      cancelToken: cancelToken,
      onError: (error) => {
        dispatch(
          openWaveSnack({
            type: 'error',
            message: error.message,
          }),
        );
      },
      onSuccess: (response) => {
        // Get savedSelectedFields as ID's from fieldPreference and split into an array
        const IDsOfSavedSelectedFields = fieldPreference.value
          ? fieldPreference.value.split(',')
          : [];

        // Get savedSelectedFields from the response based on the IDsOfSavedSelectedFields and remove from response
        let savedSelectedFields = [];

        IDsOfSavedSelectedFields.forEach((fieldID) => {
          if (response[fieldID]) {
            savedSelectedFields.push(response[fieldID]);
            delete response[fieldID];
          }
        });

        // Convert the response to an array of objects and sort by name
        const savedAvailableFields = sortArrayOfObjectsByKey({
          array: Object.keys(response).map((key) => response[key]),
          key: 'name',
        });

        setSavedAvailableFields(savedAvailableFields);
        setSavedSelectedFields(savedSelectedFields);
      },
      params: {
        filter,
        load_data: filter === 'search' ? 1 : 0,
        sub_jobs: isSubJobs || null,
        src: jobType,
      },
    });
  }

  return [
    savedAvailableFields,
    setSavedAvailableFields,
    savedSelectedFields,
    setSavedSelectedFields,
    getFieldSelectorFields,
  ];
}
