import { Dayjs } from 'dayjs';

export function isDayjs(date: any): date is Dayjs {
  return (
    typeof date === 'object' &&
    date !== null &&
    'toDate' in date &&
    typeof date.toDate === 'function'
  );
}

export function getDateFormat(date: Dayjs | Date, languageCode: string): string {
  const normalizedDate = isDayjs(date) ? date.toDate() : date;

  return new Intl.DateTimeFormat(languageCode).format(normalizedDate);
}

export function getIsDayInRange(
  day: Dayjs,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
): boolean {
  const isDayInRange =
    day.isSame(startDate, 'day') ||
    day.isSame(endDate, 'day') ||
    (day.isAfter(startDate, 'day') && day.isBefore(endDate, 'day'));

  return isDayInRange;
}

export function getFormattedTextFieldValue(
  isRange: boolean,
  date: Dayjs | null,
  dateRange: [Dayjs | null, Dayjs | null],
  languageCode: string,
): string {
  let textFieldValue = '';

  if (isRange) {
    if (dateRange[0] && dateRange[1]) {
      const startDate = getDateFormat(dateRange[0], languageCode);
      const endDate = getDateFormat(dateRange[1], languageCode);

      textFieldValue = `${startDate} - ${endDate}`;
    } else if (dateRange[0]) {
      textFieldValue = getDateFormat(dateRange[0], languageCode);
    }
  } else {
    if (date) {
      textFieldValue = getDateFormat(date, languageCode);
    }
  }

  return textFieldValue;
}
