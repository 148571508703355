export function computeWaveIconCodePrefix({ age, isSubJobs }) {
  return age === 'hom' ? 'dashboard' : isSubJobs ? 'sub-jobs' : 'jobs';
}

export function convertPagePreferenceToPage({ pagePreferenceValue, rowsPerPage, totalRowCount }) {
  return totalRowCount > 0 &&
    pagePreferenceValue &&
    totalRowCount > rowsPerPage * parseInt(pagePreferenceValue)
    ? parseInt(pagePreferenceValue)
    : 0;
}
