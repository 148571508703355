import { MouseEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button } from 'src/components/Button';
import { ActiveFilters } from '../../../ActiveFilters';
import { SubMenu, SubMenuItemType } from '../../../SubMenu';
import { WaveIcon } from '../../../WaveIcon';
import { NewCommentDialog } from '../NewCommentDialog';

import { Filter, HistoryItemType } from '../../History.types';

type ActionBarProps = {
  activeHistoryFilters: Filter[];
  expandedHistoryItemIds: string[];
  history: HistoryItemType[];
  historyFilters: Filter[];
  onClickFilter: (activeFilters: Filter[]) => void;
  onToggleExpandAll: (state: boolean) => void;
};

export function ActionBar({
  activeHistoryFilters,
  expandedHistoryItemIds,
  history,
  historyFilters,
  onClickFilter,
  onToggleExpandAll,
}: ActionBarProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const [areAllHistoryItemsExpanded, setAreAllHistoryItemsExpanded] = useState(true);
  const [isSubMenuOpen, setISubMenuOpen] = useState(false);
  const [items, setItems] = useState<SubMenuItemType[]>([]);
  const [isNewCommentDialogOpen, setNewCommentDialogOpen] = useState(false);

  function handleClickExpandCollapseAll() {
    onToggleExpandAll(areAllHistoryItemsExpanded);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setAnchorElWidth(0);
    setISubMenuOpen(false);
  }

  function handleDeleteAllHistoryFilters() {
    onClickFilter([]);
  }

  function handleDeleteHistoryFilter(deleteFilter: Record<string, string>) {
    onClickFilter(activeHistoryFilters.filter((filter) => filter !== deleteFilter));
  }

  function handleOpenNewCommentDialog() {
    setNewCommentDialogOpen(true);
  }

  function handleOpenSubMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
    setItems(
      historyFilters.map(({ code, text }) => {
        return {
          checkbox: true,
          code,
          onClick: (checkedItems: Filter[]) => {
            onClickFilter(checkedItems);
          },
          text,
        };
      }),
    );
    setISubMenuOpen(true);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && history) {
      if (areAllHistoryItemsExpanded && expandedHistoryItemIds.length === history.length) {
        setAreAllHistoryItemsExpanded(false);
      } else if (!areAllHistoryItemsExpanded && expandedHistoryItemIds.length === 0) {
        setAreAllHistoryItemsExpanded(true);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [expandedHistoryItemIds, history]);

  return (
    <>
      <Box display="flex" gap={1} justifyContent="end" marginY={1}>
        <Button
          onClick={handleOpenSubMenu}
          startIcon={<WaveIcon code="filter-list" />}
          sx={{ bgcolor: `${isSubMenuOpen ? 'primary.light' : ''}` }}
        >
          <Trans i18nKey="filter_button">Filter</Trans>
        </Button>

        <Button
          onClick={handleClickExpandCollapseAll}
          startIcon={
            <WaveIcon
              code={`job-history-action-bar-${
                areAllHistoryItemsExpanded ? 'expand-more' : 'expand-less'
              }`}
            />
          }
        >
          {areAllHistoryItemsExpanded
            ? t('lib.expandall', 'Expand All')
            : t('lib.collapseall', 'Collapse All')}
        </Button>

        <Button
          onClick={handleOpenNewCommentDialog}
          startIcon={<WaveIcon code="job-history-action-bar-comment" />}
        >
          <Trans i18nKey="lib.msg.new">New Comment</Trans>
        </Button>
      </Box>

      {isSubMenuOpen ? (
        <SubMenu
          anchorEl={anchorEl}
          anchorElMinWidth={anchorElWidth}
          anchorOriginHorizontalSubitem="right"
          anchorOriginVerticalSubitem="top"
          checkedItems={activeHistoryFilters}
          fontSize="caption"
          items={items}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginHorizontalSubitem="left"
          transformOriginVerticalSubitem="top"
        />
      ) : null}

      {activeHistoryFilters.length ? (
        <ActiveFilters
          activeFilters={activeHistoryFilters}
          onDeleteAllActiveFilters={handleDeleteAllHistoryFilters}
          transferDeleteActiveFilter={handleDeleteHistoryFilter}
        />
      ) : null}

      <NewCommentDialog
        isOpen={isNewCommentDialogOpen}
        onClose={() => setNewCommentDialogOpen(false)}
      />
    </>
  );
}
