import { Chip } from '@mui/material';

type ActiveFilterProps = {
  filter: Record<string, string>;
  label: string;
  onDeleteActiveFilter: (filter: Record<string, string>) => void;
};

export function ActiveFilter({ filter, label, onDeleteActiveFilter }: ActiveFilterProps) {
  function handleDelete() {
    onDeleteActiveFilter(filter);
  }

  return <Chip className="m-5" label={label} onDelete={handleDelete} />;
}
