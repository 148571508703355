import { Box, CircularProgress, TableCell, Theme, Typography } from '@mui/material';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { StyledLink } from 'src/features/JobsTable/components/Row/styled';
import { useStatusCell } from 'src/features/JobsTable/components/StatusCell/useStatusCell';

export type StatusCellProps = {
  paddingY: number;
  rowJobType: string;
  isSubRow: boolean;
  link: string;
  value: number;
};

function buildBorderValue(theme: Theme) {
  return `solid ${theme.palette.grey[300]} 1px`;
}

export function StatusCell({ isSubRow, link, paddingY, rowJobType, value }: StatusCellProps) {
  const { isWorkflowFetching, workflow } = useStatusCell({ rowJobType, value });

  return (
    <TableCell
      sx={{
        paddingY,
        whiteSpace: 'nowrap',
        ...(isSubRow && {
          borderLeft: buildBorderValue,
          borderRight: buildBorderValue,
        }),
      }}
    >
      <StyledLink href={link} sx={{ position: 'relative' }}>
        {isWorkflowFetching ? (
          <CircularProgress
            size={24}
            sx={{
              left: '50%',
              marginLeft: -1.5,
              marginTop: -1.5,
              position: 'absolute',
              top: '50%',
            }}
          />
        ) : !workflow?.[value] ? (
          <Typography color="error" textAlign="center" variant="body2">
            Unknown Status
          </Typography>
        ) : (
          <>
            {paddingY ? <Typography variant="body2">{workflow[value]?.name_en}</Typography> : null}

            <Box display="flex" gap={0.5}>
              {Object.values(workflow).map((status) => {
                const statusValue = status?.value;
                const isActive = statusValue?.toString() === value.toString();

                return (
                  <StatusIndicator
                    hasElevation={false}
                    isActive={isActive}
                    isLighter
                    jobType={rowJobType}
                    key={statusValue}
                    label={status?.displayValue.toString()}
                    size="extraSmall"
                  />
                );
              })}
            </Box>
          </>
        )}
      </StyledLink>
    </TableCell>
  );
}
