import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import axios from 'axios';

import { Button, Divider, Grid, Paper } from '@mui/material';

import { ActiveFilters } from '../../../ActiveFilters';
import { FieldMapper } from '../../../FieldMapper';
import { FieldSelector } from '../FieldSelector';
import { WaveIcon } from '../../../../features/WaveIcon';
import { useAppSelector } from 'src/utilities/hooks/useAppSelector';
import { useAppDispatch } from 'src/utilities/hooks/useAppDispatch';

import { useFieldSelectorFields } from '../../utilities/hooks';
import { usePreference, usePreferencePrefix } from '../../../../utilities/hooks';
import { getHaveFiltersChanged } from 'src/features/JobsTable/components/Filters/utils';
import { deleteUserPreference, updateUserPreference } from 'src/store/userSlice';
import { getDateFormat } from 'src/components/RHF/DateField/utils';
import { isString } from 'src/utilities/hooks/usePreference';

export function Filters({ areActiveFiltersVisible, onSetAreActiveFiltersVisible, getJobs }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { jobType, preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });
  const activeFiltersPreferenceCode = `${preferencePrefix}.search`;
  const activeFilters =
    useAppSelector((state) => state.user.preferences[activeFiltersPreferenceCode]?.value) ?? [];
  const normalisedActiveFilters = Array.isArray(activeFilters) ? activeFilters : [];

  const [filterFields, setFilterFields] = useState({});
  const [formFilters, setFormFilters] = useState([]);
  const [isFieldSelectorOpen, setIsFieldSelectorOpen] = useState(false);
  const [searchFiltersPreference, setSearchFiltersPreference] = usePreference(
    `${preferencePrefix}.filterFields`,
    '',
  );
  const [languageCodePreference] = usePreference('sys.lang', 'uk');
  const languageCode = isString(languageCodePreference.value) ? languageCodePreference.value : 'uk';
  const [
    savedAvailableFields,
    setSavedAvailableFields,
    savedSelectedFields,
    setSavedSelectedFields,
    onGetFieldSelectorFields,
  ] = useFieldSelectorFields({
    fieldPreference: searchFiltersPreference,
    filter: 'search',
  });

  const [pagePreference, setPagePreference] = usePreference(`${preferencePrefix}.listPage`, '0');

  const haveFiltersChanged = getHaveFiltersChanged(normalisedActiveFilters, formFilters);

  function addFilterField(alias, value) {
    setFilterFields(Object.assign({}, filterFields, { [alias]: value }));
  }

  function addNewFormFilter({ alias, label, textValue, value }) {
    // If there are no formFilters, add the new one
    if (formFilters.length === 0) {
      // previousFormFilters and ... needed for adding multiple new formFilters at once separated by comma
      setFormFilters((previousFormFilters) => [
        ...previousFormFilters,
        { [alias]: value, label, textValue: textValue || value },
      ]);
    } else {
      // Check to see if new filter key value pair already exists in formFilters state
      const doesNewFilterExist = formFilters.some((filter) => {
        const filterKeyValuePair = Object.entries(filter);

        return filterKeyValuePair[0][0] === alias && filterKeyValuePair[0][1] === value;
      });

      // If new filter does not exist add it to formFilters
      if (!doesNewFilterExist) {
        setFormFilters((previousFormFilters) => [
          ...previousFormFilters,
          { [alias]: value, label, textValue: textValue || value },
        ]);
      }
    }
  }

  function getFieldChangeFunction(type) {
    switch (type) {
      case 'boolean':
        return handleChangeCheckbox;
      case 'date':
      case 'datetime':
        return handleChangeDateField;
      case 'pick':
        return handleChangeMultipleSelect;
      case 'tradio':
        return handleChangeRadioField;
      default:
        return handleChangeTextField;
    }
  }

  function handleApplyFieldSelector(selectedFields) {
    // Extract all of the savedSelectedFields field ID's and convert it to a string to be passed to setSearchFiltersPreference

    setSearchFiltersPreference({
      ...searchFiltersPreference,
      value: selectedFields.map((field) => field.id).join(),
    });
  }

  function handleBlur({ target: { id: alias, name: label, value } }) {
    const jobId = filterFields[alias];

    if (value !== '') {
      if (alias === 'jobid') {
        // A job ID will have quotes if it is multiple job ID's e.g., "123, 591, 4428"
        const hasQuotes = jobId.includes('"', 0) && jobId.includes('"', jobId.length - 1);

        if (hasQuotes) {
          const quotelessJobID = jobId.replace(new RegExp(/["']/g), '');

          addNewFormFilter({ alias, label, value: quotelessJobID });
        } else {
          const splitValue = value.split(',');

          splitValue.forEach((value) => {
            addNewFormFilter({ alias, label, value });
          });
        }
      } else {
        addNewFormFilter({ alias, label, value });
      }
    }

    addFilterField(alias, '');
  }

  function handleChangeCheckbox({ target: { id: alias, name: label, value } }) {
    value = value ? 'X' : null;

    if (filterFields[alias]) {
      handleDeleteFilterField({ [alias]: filterFields[alias] });
    } else {
      addFilterField(alias, value);
    }
    if (formFilters.some((formFilter) => formFilter[alias])) {
      setFormFilters(formFilters.filter((formFilter) => Object.keys(formFilter)[0] !== alias));
    } else {
      setFormFilters([
        ...formFilters,
        {
          [alias]: value,
          label,
          textValue: value,
        },
      ]);
    }
  }

  function handleChangeDateField({ calendarOverlay, target: { id: alias, name: label, value } }) {
    // Do nothing unless date is selected by mouse click
    // If date range is selected
    if (value !== null) {
      // Two dates have been selected
      if (value[1]) {
        // If both dates are equal, set the second date to null
        if (value[0].toString() === value[1].toString()) {
          value[1] = null;
        }
        handleFinishCalendar({ alias, calendarOverlay, label, value });
        addFilterField(alias, '');
      } else {
        addFilterField(alias, value);
      }
    }
  }

  function handleChangeMultipleSelect({
    target: { arrayValue, id: alias, name: label, textValue },
  }) {
    // Filter out all formFilters for this field to start fresh
    const _formFilters = formFilters.filter((formFilter) => {
      const formFilterAlias = Object.keys(formFilter)[0];

      return alias !== formFilterAlias;
    });

    // Create the formFilters for this field
    const multipleSelectFormFilters = arrayValue.sort().map((value, index) => {
      return {
        [alias]: value,
        label,
        textValue: textValue[index],
      };
    });

    setFilterFields({ ...filterFields, [alias]: arrayValue });
    setFormFilters([..._formFilters, ...multipleSelectFormFilters]);
  }

  function handleChangeRadioField({ target: { id: alias, labels, value } }) {
    const fieldLabel = labels[0].parentElement.previousSibling.innerText;
    const valueLabel = labels[0].innerText;

    addFilterField(alias, value);
    setFormFilters([
      ...formFilters.filter(({ label }) => label !== fieldLabel),
      { [alias]: value, label: fieldLabel, textValue: valueLabel },
    ]);
  }

  function handleChangeTextField({ target: { id: alias, value } }) {
    addFilterField(alias, value);
  }

  async function handleDeleteActiveFilter(deleteFilter) {
    handleDeleteFilterField(deleteFilter);
    handleDeleteFormFilter(deleteFilter);

    const updatedActiveFilters = normalisedActiveFilters.filter((activeFilter) => {
      return !isEqual(activeFilter, deleteFilter);
    });

    if (updatedActiveFilters.length > 0) {
      dispatch(
        updateUserPreference({ code: activeFiltersPreferenceCode, value: updatedActiveFilters }),
      );
    } else {
      await dispatch(
        deleteUserPreference({ code: activeFiltersPreferenceCode, value: updatedActiveFilters }),
      ).unwrap();
      getJobs();
    }
  }

  async function handleDeleteAllActiveFilters() {
    await dispatch(deleteUserPreference({ code: activeFiltersPreferenceCode, value: [] })).unwrap();
    setFilterFields({});
    setFormFilters([]);
    getJobs();
  }

  function handleDeleteAllFormFilters() {
    setFilterFields({});
    setFormFilters([]);
  }

  function handleDeleteFilterField(deleteFilter) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const { [deleteFilterKey]: deleteFilterValue, ..._filterFields } = filterFields;

    // If the deleteFilter has an array of values, delete the single value rather than the entire property
    if (Array.isArray(deleteFilterValue) && deleteFilterValue.length > 1) {
      const deleteValue = deleteFilter[deleteFilterKey];

      setFilterFields({
        [deleteFilterKey]: deleteFilterValue.filter((value) => value !== deleteValue),
        ..._filterFields,
      });
    } else {
      setFilterFields(_filterFields);
    }
  }

  function handleDeleteFormFilter(deleteFilter) {
    const deleteFilterKey = Object.keys(deleteFilter)[0];
    const uniqueDeleteFilter = deleteFilterKey + deleteFilter[deleteFilterKey];

    // Return the formFilters who's unique key value pair doesn't match the key value pair of parameter "deleteFilter"
    const _formFilters = formFilters.filter((filter) => {
      const filterKey = Object.keys(filter)[0];
      const uniqueFormFilter = filterKey + filter[filterKey];

      return uniqueFormFilter !== uniqueDeleteFilter;
    });

    handleDeleteFilterField({
      [deleteFilterKey]: deleteFilter[deleteFilterKey],
    });
    setFormFilters(_formFilters);
  }

  function handleFinishCalendar({ alias, calendarOverlay, label, value }) {
    const selectedDate = value.toString().split(',');
    const firstDate = getDateFormat(new Date(selectedDate[0]), languageCode);
    const secondDate = getDateFormat(new Date(selectedDate[1]), languageCode);

    // Determine if one or two dates are selected
    const dateValue = selectedDate[1] ? `${firstDate} - ${secondDate}` : firstDate;

    addNewFormFilter({ alias, label, value: dateValue });

    // After a date is selected hide the date picker
    calendarOverlay.panel.style.display = 'none';
  }

  function handleOpenFieldSelector() {
    setIsFieldSelectorOpen(true);
  }

  async function handleSubmitForm() {
    if (formFilters.length > 0) {
      dispatch(updateUserPreference({ code: activeFiltersPreferenceCode, value: formFilters }));
    } else {
      await dispatch(
        deleteUserPreference({ code: activeFiltersPreferenceCode, value: formFilters }),
      ).unwrap();
      getJobs();
    }
    setPagePreference({ ...pagePreference, value: '0' });
  }

  useEffect(() => {
    if (normalisedActiveFilters?.length > 0) {
      // When activeFiltersPreference changes copy activeFiltersPreference so that filterFields display the correct value and formFilters always match

      setFormFilters([...normalisedActiveFilters]);

      if (savedSelectedFields.length > 0) {
        const filterFields = {};

        normalisedActiveFilters.forEach((activeFilter) => {
          const [activeFilterAlias, activeFilterValue] = Object.entries(activeFilter)[0];
          const isActiveFilterADateField = savedSelectedFields.some(
            ({ alias, typ: type }) => alias === activeFilterAlias && type === 'date',
          );

          if (!isActiveFilterADateField) {
            // Only add activeFilter to filterFields if it isn't a DateField since a DateField cannot accept multiple values

            if (filterFields[activeFilterAlias]) {
              // If the activeFilter already has a value for it in filterFields it must be a multipleSelect and a multipleSelect can have multiple values in form of an array
              if (Array.isArray(filterFields[activeFilterAlias])) {
                filterFields[activeFilterAlias] = [
                  ...filterFields[activeFilterAlias],
                  activeFilterValue,
                ];
              } else {
                filterFields[activeFilterAlias] = [
                  filterFields[activeFilterAlias],
                  activeFilterValue,
                ];
              }
            } else {
              filterFields[activeFilterAlias] = activeFilterValue;
            }
          }
        });

        setFilterFields(filterFields);
      }
    }
  }, [normalisedActiveFilters, savedSelectedFields]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!areActiveFiltersVisible && !savedAvailableFields.length > 0) {
      onGetFieldSelectorFields(source.token);
    }

    return () => source.cancel();
  }, [areActiveFiltersVisible]);

  useEffect(() => {
    onSetAreActiveFiltersVisible(true);
    setFormFilters([]);
    setSavedAvailableFields([]);
    setSavedSelectedFields([]);
  }, [jobType]);

  return !areActiveFiltersVisible ? (
    <Paper elevation={0}>
      <Grid alignItems="center" className="p-10" container justifyContent="flex-end">
        <Grid item>
          <Button
            disabled={!savedAvailableFields.length > 0}
            onClick={handleOpenFieldSelector}
            startIcon={<WaveIcon code="visibility" />}
            variant="text"
          >
            <Trans i18nKey="select_filter_fields_button">Select Filter Fields</Trans>
          </Button>

          <Button
            disabled={!haveFiltersChanged}
            onClick={handleSubmitForm}
            startIcon={<WaveIcon code="done" />}
            variant="text"
          >
            <Trans i18nKey="apply_filters_button">Apply Filters</Trans>
          </Button>
        </Grid>
      </Grid>

      <Divider />

      {formFilters.length ? (
        <ActiveFilters
          activeFilters={formFilters}
          onDeleteAllActiveFilters={handleDeleteAllFormFilters}
          transferDeleteActiveFilter={handleDeleteFormFilter}
        />
      ) : null}

      <Grid alignItems="center" container justifyContent="flex-start">
        {savedSelectedFields.map(({ alias, description, field_data, id: ID, name, typ: type }) => {
          const fieldValue = filterFields[alias]
            ? filterFields[alias]
            : type === 'ccomplete'
            ? null
            : '';

          return (
            <Grid className="p-10" item key={`filter-${ID}`}>
              <FieldMapper
                alias={alias}
                description={description}
                label={name}
                onBlur={handleBlur}
                options={field_data}
                selectionMode="range"
                transferChangeField={(e) => {
                  getFieldChangeFunction(type)(e);
                }}
                type={type}
                value={fieldValue}
              />
            </Grid>
          );
        })}
      </Grid>

      {isFieldSelectorOpen ? (
        <FieldSelector
          fieldPreference={searchFiltersPreference}
          onApplyFieldSelector={handleApplyFieldSelector}
          onSetIsFieldSelectorOpen={setIsFieldSelectorOpen}
          onSetSavedAvailableFields={setSavedAvailableFields}
          onSetSavedSelectedFields={setSavedSelectedFields}
          savedAvailableFields={savedAvailableFields}
          savedSelectedFields={savedSelectedFields}
          type={t('filter_button', 'Filter')}
        />
      ) : null}
    </Paper>
  ) : normalisedActiveFilters?.length ? (
    <ActiveFilters
      activeFilters={normalisedActiveFilters}
      onDeleteAllActiveFilters={handleDeleteAllActiveFilters}
      transferDeleteActiveFilter={handleDeleteActiveFilter}
    />
  ) : null;
}
