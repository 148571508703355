import { Filters } from 'src/features/JobsTable/components/Filters/types';

export function getHaveFiltersChanged(activeFilters: Filters, formFilters: Filters): boolean {
  if (activeFilters.length !== formFilters.length) {
    return true;
  }

  const doesActiveFiltersMatch = activeFilters.every((activeFilter) =>
    formFilters.some((formFilter) => formFilter.textValue === activeFilter.textValue),
  );

  const doesFormFiltersMatch = formFilters.every((formFilter) =>
    activeFilters.some((activeFilter) => activeFilter.textValue === formFilter.textValue),
  );

  return !(doesActiveFiltersMatch && doesFormFiltersMatch);
}
