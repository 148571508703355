import { StyledStatusIndicator } from 'src/components/StatusIndicator/styled';
import { Typography } from 'src/components/Typography';

type StyledIndicatorProps = {
  label?: string;
  jobType?: string;
  size?: StatusIndicatorSizeType;
  isActive?: boolean;
  isLighter?: boolean;
  hasElevation?: boolean;
};

export type StatusIndicatorSizeType = 'extraSmall' | 'small' | 'medium' | 'large';

export function StatusIndicator({
  hasElevation = true,
  isActive,
  isLighter,
  jobType,
  label,
  size = 'medium',
}: StyledIndicatorProps) {
  const typographyVariant = ['small', 'extraSmall'].includes(size) ? 'caption' : 'inherit';

  return (
    <StyledStatusIndicator
      hasElevation={hasElevation}
      isActive={isActive}
      isLighter={isLighter}
      jobType={jobType}
      size={size}
    >
      {label && (
        <Typography
          variant={typographyVariant}
          {...(typographyVariant === 'caption' && {
            lineHeight: (theme) => theme.typography[typographyVariant].fontSize,
          })}
        >
          {label}
        </Typography>
      )}
    </StyledStatusIndicator>
  );
}
