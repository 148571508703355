import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { useTask } from 'src/features/JobForm/components/Task/useTask';
import { TaskTransformed } from 'src/features/JobForm/JobForm.service';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

type TaskProps = { isDirty: boolean; task: TaskTransformed };

export function Task({ isDirty, task: { code, taskActions, text } }: TaskProps) {
  const {
    control,
    determineIconColor,
    handleCloseSubMenu,
    handleCloseTaskDialog,
    handleToggleSubMenu,
    isSubMenuOpen,
    isSubmitting,
    isTaskDialogOpen,
    onSubmit,
    taskAction,
    taskActionButtons,
    taskButton,
  } = useTask({ code, taskActions });

  const { t } = useTranslation();

  return (
    <>
      <WaveTooltip
        component={
          <Button
            disabled={isDirty}
            onClick={handleToggleSubMenu}
            ref={taskButton}
            size="small"
            startIcon={<WaveIcon code="job-form-action-bar-task" />}
            variant="outlined"
          >
            {text}
          </Button>
        }
        key={code}
        placement="top"
        type="simple"
        {...(isDirty && {
          body: t(
            'form_save_other_actions',
            'Save the job to apply your changes or refresh the page to undo them. Only then, can you perform other job actions.',
          ) as string,
        })}
        {...(isDirty && { header: t('form_changes_made', 'Changes Have Been Made') as string })}
      />

      <SubMenu
        anchorEl={taskButton.current}
        anchorElMinWidth={taskButton.current?.offsetWidth}
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={taskActionButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isTaskDialogOpen}
        {...(!isSubmitting && { onClose: handleCloseTaskDialog })}
      >
        {taskAction ? (
          <>
            <WaveDialogTitle
              title={
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    gap: 2,
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>{text}</Typography>

                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{ color: determineIconColor(taskAction.code) }}
                      variant="subtitle1"
                    >
                      {taskAction.text}
                    </Typography>

                    <WaveIcon
                      code={`job-form-action-bar-task-dialog-${taskAction.code}`}
                      fontSize="small"
                      sx={{ color: determineIconColor(taskAction.code) }}
                    />
                  </Box>
                </Box>
              }
            />

            <form onSubmit={onSubmit}>
              <DialogContent>
                <TextField
                  control={control}
                  disabled={isSubmitting}
                  label={t('lib.msg', 'Comment')}
                  name="comment"
                  type="memo"
                  {...(taskAction.isFlagComment && {
                    rules: { required: { message: `${t('lib.required')}`, value: true } },
                  })}
                />
              </DialogContent>

              <DialogActions>
                <LoadingButton
                  loading={isSubmitting}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  <Trans i18nKey="lib.confirm">Cancel</Trans>
                </LoadingButton>
              </DialogActions>
            </form>
          </>
        ) : null}
      </Dialog>
    </>
  );
}
