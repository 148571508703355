import { useEffect } from 'react';

import {
  Accordion,
  AccordionDetails,
  Box,
  Paper,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { StyledAccordionSummary } from 'src/components/StyledAccordionSummary';
import { useGetApprovalsQuery, useGetFilesQuery } from 'src/features/Approvals/Approvals.service';
import { ActionBar } from 'src/features/Approvals/components/ActionBar';
import { ApprovalFile } from 'src/features/Approvals/components/ApprovalFile';
import { ApprovalsList } from 'src/features/Approvals/components/ApprovalsList';
import { useExpand, useRouteParams, useSelect } from 'src/utilities/hooks';
import { parseArrayToLogicalObject, parseRecordToLogicalObject } from 'src/utilities/parsers';

export function Approvals() {
  const { age, jobId, jobType } = useRouteParams();

  const {
    data: files = { categorizedFiles: {}, rawFiles: [] },
    isSuccess: areFilesLoadedSuccessfully,
  } = useGetFilesQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: approvals = [],
    isLoading,
    isSuccess: areApprovalsLoadedSuccessfully,
  } = useGetApprovalsQuery(
    {
      age,
      jobid: jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );
  const { expandedItems: expandedCategories, toggleExpandItem: toggleExpandCategory } = useExpand(
    areFilesLoadedSuccessfully
      ? {
          ...parseRecordToLogicalObject(files.categorizedFiles),
          [Object.keys(files.categorizedFiles)[0]]: true,
        }
      : {},
  );
  const { expandedItems: expandedFiles, toggleExpandItem: toggleExpandFile } = useExpand({
    ...parseArrayToLogicalObject(files.rawFiles, 'id'),
    [files?.rawFiles[0]?.id]: true,
  });
  const { selectedItemIds, selectedItems, toggleSelectItem } = useSelect(
    parseArrayToLogicalObject(files.rawFiles, 'id'),
  );

  useEffect(() => {
    const scrollContainer = document.getElementById('files-list');

    if (
      scrollContainer &&
      areApprovalsLoadedSuccessfully &&
      (approvals.length || files.rawFiles.length)
    ) {
      scrollContainer.style.maxHeight = `${scrollContainer?.clientHeight.toString()}px` || '0';
    }
  }, [areApprovalsLoadedSuccessfully, approvals.length, files.rawFiles.length]);

  return (
    <Box display="flex" flexDirection="column" height="100%" p={2} role="tabpanel">
      {areApprovalsLoadedSuccessfully && !approvals.length && !isLoading ? null : (
        <ActionBar selectedFileIds={selectedItemIds as number[]} />
      )}

      {(areApprovalsLoadedSuccessfully && approvals.length) || files.rawFiles.length ? (
        <Box component={Paper} display="flex" elevation={3} flexDirection="column" flexGrow={1}>
          <Grid container flexGrow={1}>
            <Grid
              borderRight="1px solid rgba(0, 0, 0, 0.12)"
              id="files-list"
              overflow="auto"
              px={2}
              xs={2.5}
            >
              {Object.keys(files.categorizedFiles).map((category, index) => (
                <Accordion expanded={expandedCategories[category] || false} key={index}>
                  <StyledAccordionSummary onClick={() => toggleExpandCategory(category)}>
                    <Typography fontWeight={500}>{category}</Typography>
                  </StyledAccordionSummary>

                  <Box component={AccordionDetails} px={1} py={0}>
                    {files.categorizedFiles[category].map((file) => (
                      <ApprovalFile
                        file={file}
                        isExpanded={expandedFiles[file.id]}
                        isSelected={selectedItems[file.id]}
                        key={file.id}
                        onToggleExpand={toggleExpandFile}
                        onToggleSelect={toggleSelectItem}
                      />
                    ))}
                  </Box>
                </Accordion>
              ))}

              {areFilesLoadedSuccessfully && !files.rawFiles.length ? (
                <Box p={2} textAlign="center">
                  <Typography color="text.secondary" variant="button">
                    Nothing to show
                  </Typography>
                </Box>
              ) : null}
            </Grid>

            <Grid px={1.5} xs>
              {approvals.length ? (
                <ApprovalsList />
              ) : (
                <Box p={2} textAlign="center">
                  <Typography color="text.secondary" variant="button">
                    No APL data to show
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : !isLoading ? (
        <Box p={2} textAlign="center">
          <Typography color="text.secondary" variant="button">
            No APL data to show
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}
