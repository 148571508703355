import React, { useMemo } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  Checkbox,
  Chip,
  CircularProgress,
  createFilterOptions,
  FilterOptionsState,
  TextField,
} from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { Option } from 'src/pages/Job/Job.service';
import { usePreference } from 'src/utilities/hooks';
import { ListboxComponent } from './VirtualList';

import { AutocompleteProps } from './types';

export function MultipleAutocomplete<
  T extends string | Option,
  P extends FieldValues,
  Multiple extends boolean,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  control,
  description,
  disabled,
  freeSolo,
  getChipColor,
  isError = false,
  isFetching = false,
  label,
  name,
  onChange,
  onChangeLogicBuilderField,
  onChangeSteeredField,
  options,
  rules,
  shouldShowHelperText,
  shouldVirtualize,
  ...rest
}: Omit<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'defaultValue' | 'renderInput'
> &
  Omit<UseControllerProps<P>, 'defaultValue'> &
  AutocompleteProps<T>) {
  const [showTooltipsPreference] = usePreference('job.feldtips', 'Y');

  const {
    field: { onChange: onFieldChange, ref, value: reactHookFormValue = null, ...field },
    fieldState: { error },
  } = useController<P>({
    control,
    name,
    rules,
  });

  const memoizedOptions = !freeSolo
    ? useMemo(() => {
        let resultingOptions = [...options];

        if (Array.isArray(reactHookFormValue) && !(reactHookFormValue as Option[]).length) {
          return resultingOptions;
        }

        if (reactHookFormValue !== null && reactHookFormValue !== '') {
          const values = (reactHookFormValue as unknown as number).toString().split(',');

          values.forEach((value) => {
            if (!options.some((option) => isOption(option) && option.value === value)) {
              resultingOptions = [{ label: value, value } as T, ...resultingOptions];
            }
          });
        }

        return resultingOptions as T[];
      }, [options])
    : options;

  const filter = createFilterOptions<T>();

  function filterOptions(options: T[], state: FilterOptionsState<T>) {
    const { inputValue: filterInputValue } = state;
    const filteredOptions = filter(options, state);
    const isValueAnExistingOption = options.some((option) =>
      typeof option === 'string' ? filterInputValue === option : filterInputValue === option.value,
    );

    if (freeSolo && filterInputValue !== '' && !isValueAnExistingOption) {
      return suggestCreationOfNewOption(filteredOptions, filterInputValue);
    }

    return filteredOptions;
  }

  function suggestCreationOfNewOption<Option>(filteredOptions: Option[], filterInputValue: string) {
    const newOption = {
      label: `Add "${filterInputValue}"`,
      value: filterInputValue,
    } as Option;

    return [...filteredOptions, newOption];
  }

  const value = getValueMapper(reactHookFormValue);

  function getValueMapper(value: string | number | Option | string[] | null) {
    if (
      value === null ||
      value === undefined ||
      value === 0 ||
      value === '' ||
      (Array.isArray(value) && !value.length)
    ) {
      return [];
    }

    if (Array.isArray(value) && value.length) {
      return value.map((valueItem) => {
        if (isOption(valueItem)) {
          return { label: `${valueItem.label} `, value: valueItem.value };
        } else {
          return { label: valueItem, value: valueItem };
        }
      });
    }

    if (isOption(value)) {
      return [value];
    }

    return value
      .toString()
      .split(',')
      .map((value) => ({
        label: getValueLabel(value.toString())?.label || value.toString(),
        value: value.toString(),
      }));
  }

  function getValueLabel(value: string): Option | undefined {
    return memoizedOptions.find(
      (option) => value.toString() === (option as Option).value.toString(),
    ) as Option | undefined;
  }

  function isOption(param: unknown): param is Option {
    return Boolean((param as Option).value !== undefined && (param as Option).label !== undefined);
  }

  function getOptionLabel(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.label : option.toString();
  }

  function getOptionValue(option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) {
    return isOption(option) ? option.value.toString() : option.toString();
  }

  return (
    <>
      <MuiAutocomplete
        disableCloseOnSelect
        disabled={disabled || isFetching}
        disableListWrap
        filterOptions={filterOptions}
        freeSolo={freeSolo as FreeSolo}
        fullWidth
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => getOptionValue(option) === getOptionValue(value)}
        multiple={true as Multiple}
        onChange={(e, value, reason, details) => {
          const result: string[] = [];

          if (Array.isArray(value) && value.length > 0) {
            value.forEach((item) => {
              result.push(isOption(item) ? item.value.toString() : item);
            });
          }

          onFieldChange(result.length > 0 ? (freeSolo ? result : result.join(',')) : null);

          if (onChange) {
            onChange(e, value, reason, details);
          }

          if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);

          if (onChangeSteeredField) onChangeSteeredField(name);
        }}
        options={memoizedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error || isError}
            inputProps={{
              ...params.inputProps,
              required: !!rules?.required && (value as [])?.length === 0,
            }}
            inputRef={ref}
            label={label}
            required={!!rules?.required}
          />
        )}
        {...(!shouldVirtualize &&
          options.length && {
            renderOption: (props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} {...field} />

                {getOptionLabel(option)}
              </li>
            ),
          })}
        {...(shouldVirtualize && {
          ListboxComponent: ListboxComponent,
          renderOption: (props, option, state) => [props, option, state.index] as React.ReactNode,
        })}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={getOptionLabel(option)}
              size="small"
              variant="outlined"
              {...getTagProps({ index })}
              key={getOptionValue(option)}
              {...(getChipColor && { color: getChipColor(option) })}
            />
          ))
        }
        size="small"
        value={
          value as unknown as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | undefined
        }
        // https://mui.com/material-ui/react-text-field/#helper-text
        {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
        {...(isFetching && { popupIcon: <CircularProgress size={24} /> })}
        {...field}
        {...rest}
      />

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}
    </>
  );
}
