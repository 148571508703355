import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { RadioGroup as MUIRadioGroup } from '@mui/material';

type RadioGroupProps = {
  children: ReactNode;
  name: string;
  control: any;
  onChange?: (value: any) => void;
  defaultValue?: any;
  className?: string;
};

export function RadioGroup({
  children,
  className,
  control,
  defaultValue,
  name,
  onChange,
}: RadioGroupProps) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange: fieldOnChange, value } }) => {
        return (
          <MUIRadioGroup
            className={className}
            onChange={(event) => {
              fieldOnChange(event);
              if (onChange) onChange(event.target.value);
            }}
            value={value}
          >
            {children}
          </MUIRadioGroup>
        );
      }}
    />
  );
}
