import { ChangeEvent, useEffect, useState } from 'react';
import { Control, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionActions, AccordionDetails, Button } from '@mui/material';

import { Checkbox } from 'src/components/RHF/Checkbox';
import { TextField } from 'src/components/RHF/TextField';
import { StyledAccordionSummary } from 'src/components/StyledAccordionSummary';
import { Revisor } from 'src/features/JobForm/components/Revisor';
import {
  ApprovalSection,
  ApprovalTemplate,
  ApprovalTemplateActions,
} from 'src/features/JobForm/JobForm.service';

type StartApprovalProps = {
  actions: ApprovalTemplateActions;
  control: Control<FieldValues, any>;
  handleClickAddRevisor: (templateEventId: number) => void;
  isCommentOptional: boolean;
  isSingle: boolean;
  isSubmitting: boolean;
  name: string;
  setValue: UseFormSetValue<FieldValues>;
  templates: ApprovalTemplate[];
} & Pick<ApprovalSection, 'code'>;

export function StartApproval({
  actions,
  code,
  control,
  handleClickAddRevisor,
  isCommentOptional,
  isSingle,
  isSubmitting,
  name,
  setValue,
  templates,
}: StartApprovalProps) {
  const [expandedTemplates, setExpandedTemplates] = useState<Record<string, boolean>>({});
  const { t } = useTranslation();

  function handleChangeTemplateCheckbox(e: ChangeEvent<HTMLInputElement>) {
    Object.keys(expandedTemplates).forEach((templateId) => {
      if (e.target.name !== templateId) {
        setValue(templateId, false);
      }
    });
  }

  function renderTemplates() {
    return (
      <AccordionDetails>
        {templates.map(({ event_id: eventId, name, revisors }) => {
          const templateId = `approvalTemplate${eventId}`;
          const isTemplateExpanded = useWatch({ control, name: templateId }) ?? false;

          useEffect(() => {
            setExpandedTemplates((previousExpandedTemplates) => ({
              ...previousExpandedTemplates,
              [templateId]: isTemplateExpanded,
            }));
          }, [isTemplateExpanded]);

          return (
            <Accordion
              disabled={isSubmitting}
              expanded={expandedTemplates[templateId] ?? false}
              key={eventId}
            >
              <StyledAccordionSummary
                iconColor="filler.contrastText"
                shouldRenderIcon={templates.length !== 1}
                sx={{
                  bgcolor: 'filler.main',
                  color: 'filler.contrastText',
                }}
              >
                <Checkbox
                  control={control}
                  disabled={isSubmitting}
                  label={name}
                  name={templateId}
                  onChange={handleChangeTemplateCheckbox}
                  shouldShowHelperText={false}
                />
              </StyledAccordionSummary>

              <AccordionDetails>
                <Revisor
                  control={control}
                  revisors={revisors}
                  setValue={setValue}
                  variant="approvalRevisor"
                />
              </AccordionDetails>

              <AccordionActions sx={{ justifyContent: 'space-between' }}>
                {actions.map(({ code, text }) => (
                  <Button
                    color={code === 'reset' ? 'warning' : 'primary'}
                    key={code}
                    {...(code === 'add' && {
                      onClick: () => handleClickAddRevisor(eventId),
                    })}
                  >
                    {text}
                  </Button>
                ))}
              </AccordionActions>
            </Accordion>
          );
        })}

        <TextField
          control={control}
          disabled={isSubmitting}
          label={t('lib.msg', 'Comment')}
          name={`startApprovalComment${name}`}
          sx={{ mt: 2 }}
          type="memo"
          {...(!isCommentOptional && {
            rules: { required: { message: `${t('lib.required')}`, value: true } },
          })}
        />
      </AccordionDetails>
    );
  }

  return isSingle && !code ? (
    renderTemplates()
  ) : (
    <Accordion defaultExpanded disabled={isSubmitting}>
      <StyledAccordionSummary
        iconColor="filler.contrastText"
        shouldRenderIcon
        sx={{
          bgcolor: 'filler.main',
          color: 'filler.contrastText',
        }}
      >
        {name}
      </StyledAccordionSummary>

      {renderTemplates()}
    </Accordion>
  );
}
