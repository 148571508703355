import { Box, Drawer } from '@mui/material';

import { Footer } from 'src/features/Frame/components/Footer';
import { MainMenu } from 'src/features/Frame/components/MainMenu';
import { SupportMenu } from 'src/features/Frame/components/SupportMenu';

type NavSideBarProps = { isOpen: boolean; onToggleDrawer: () => void };

export function NavSideBar({ isOpen, onToggleDrawer }: NavSideBarProps) {
  return (
    <Drawer
      sx={{
        ['& .MuiDrawer-paper']: {
          bgcolor: 'primary.main',
          border: 0,
          boxShadow: '4px 0 5px -2px #888',
          justifyContent: 'space-between',
          overflowX: 'hidden',
          position: 'static',
        },
        position: 'relative',
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        width: isOpen ? 240 : 64,
        zIndex: 1,
      }}
      variant="permanent"
    >
      <MainMenu isOpen={isOpen} onToggleDrawer={onToggleDrawer} />

      <Box>
        <SupportMenu isOpen={isOpen} />

        {isOpen ? <Footer /> : null}
      </Box>
    </Drawer>
  );
}
