import { useParams } from 'react-router-dom';

export type Age = { age?: ExpandedAges };
export type ExpandedAges = BasicAge | 'global' | 'hom';
export type BasicAge = 'arc' | 'job';
export type JobIdString = { jobId?: string };
export type JobType = { jobType?: string };

export function useRouteParams(parameters?: {
  defaultJobId?: number | string;
  forcedAge?: BasicAge;
  forcedJobType?: string;
}) {
  const params: {
    secretKey?: string;
    tab?: string;
    tabCode?: string;
  } & Age &
    JobIdString &
    JobType = useParams();
  const { age, jobId = parameters?.defaultJobId, jobType, secretKey, tab, tabCode } = params;

  return {
    age: parameters?.forcedAge || age,
    jobId: typeof jobId === 'string' && jobId !== 'new' ? parseInt(jobId) : jobId,
    jobType:
      (parameters?.forcedJobType === 'pro' && tab === 'assign') ||
      (parameters?.forcedJobType && parameters.forcedJobType !== 'pro')
        ? parameters.forcedJobType
        : jobType,
    secretKey,
    tab,
    tabCode,
  };
}
