import { Fragment, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Divider,
  ListItem,
  Paper,
  TableCell,
  Unstable_Grid2 as Grid,
  useTheme,
} from '@mui/material';

import { Typography } from 'src/components/Typography';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { Approval, SubApproval, SubApprovalDetail } from 'src/features/Approvals/Approvals.service';
import { ICON_COLORS } from 'src/features/Approvals/components/DetailsTable/DetailsTable';
import { StyledLink } from 'src/features/JobsTable/components/Row/styled';
import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';
import { WaveIcon } from 'src/features/WaveIcon';
import { usePreferencePrefix } from 'src/utilities/hooks';

type APLCellProps = {
  isSubRow: boolean;
  link: string;
  rowJobId: number;
  rowJobType: string;
  rowWebStatus: number;
  value: Approval;
} & Pick<StatusCellProps, 'paddingY'>;
export function APLCell({
  isSubRow,
  link,
  paddingY,
  rowJobId,
  rowJobType,
  rowWebStatus,
  value,
}: APLCellProps) {
  const { age } = usePreferencePrefix({
    forcedAge: rowWebStatus === 200 ? 'arc' : 'job',
    forcedJobType: 'pro',
  });
  const navigate = useNavigate();
  const { palette } = useTheme();

  const subApproval: Array<SubApprovalDetail> = [];

  Array.isArray(value.approval)
    ? value.approval.map(
        (approvalDetail) =>
          approvalDetail && approvalDetail?.details.map((detail) => subApproval.push(detail)),
      )
    : null;

  function handleClickAPL(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/jobs-${age}-${rowJobType}/${rowJobId}/apl`);
  }

  function renderAPLCell() {
    const approval = Array.isArray(value.approval) ? subApproval : value.approval.details;

    return (
      <Grid alignItems="center" container wrap="nowrap">
        <Grid height="20px" margin="5px" maxWidth="20px" width="20px" xs>
          {value?.num}
        </Grid>

        {approval?.map((detail) => {
          return (
            <Grid
              height="20px"
              key={detail.id}
              margin={0.3}
              maxWidth="8px"
              sx={{
                backgroundColor: `${ICON_COLORS[detail.status] || 'primary'}`,
                borderRadius: '20px',
              }}
              width="8px"
              xs
            />
          );
        })}
      </Grid>
    );
  }

  function renderTooltip(details: Array<SubApprovalDetail>) {
    return details.map((detail) => {
      return (
        <ListItem key={detail.id}>
          <Box
            code={`job-approvals-revisor-status-${detail.status}`}
            color={ICON_COLORS[detail.status]}
            component={WaveIcon}
            marginRight={1}
            sx={{ verticalAlign: 'middle' }}
          />

          <Typography
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color={palette.oddRow.contrastText}
            fontWeight="bold"
            variant="caption"
          >
            {`${detail.position}  ${detail.user}`}
          </Typography>
        </ListItem>
      );
    });
  }

  return (
    <TableCell
      onClick={(e) => handleClickAPL(e)}
      sx={{
        borderLeft: `${isSubRow ? '1px solid rgba(224, 224, 224, 1)' : ''}`,
        borderRight: `${isSubRow ? '1px solid rgba(224, 224, 224, 1)' : ''}`,
        margin: '0px',
        paddingY,
      }}
    >
      <StyledLink href={link}>
        {value?.approval ? (
          <WaveTooltip
            body={
              <Box bgcolor="white" component={Paper} elevation={3} maxHeight="25vh" overflow="auto">
                {Array.isArray(value.approval) ? (
                  <>
                    {value.approval.map((subApproval, index) => (
                      <Fragment key={index}>
                        {subApproval.prefix ? (
                          <ListItem>
                            <Typography
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              color={palette.oddRow.contrastText}
                              fontWeight="bold"
                              variant="caption"
                            >
                              {subApproval.prefix}
                            </Typography>
                          </ListItem>
                        ) : null}

                        {renderTooltip(subApproval.details)}

                        {index !== (value.approval as Array<SubApproval>).length - 1 ? (
                          <Divider />
                        ) : null}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  renderTooltip(value.approval.details)
                )}
              </Box>
            }
            component={
              <Grid alignItems="center" container wrap="nowrap">
                {renderAPLCell()}
              </Grid>
            }
            header={value?.title}
            key={value?.id}
            type="custom"
          />
        ) : null}
      </StyledLink>
    </TableCell>
  );
}
