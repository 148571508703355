import { TableCell, TableSortLabel } from '@mui/material';

import { usePreference, usePreferencePrefix } from '../../../../utilities/hooks';

export function HeaderCell({ alias, subHeader, title }) {
  const orderByPreferenceDefault = 'jobid';

  const { preferencePrefix } = usePreferencePrefix({
    forcedJobType: 'pro',
  });

  const [orderByPreference, setOrderByPreference] = usePreference(
    `${preferencePrefix}.order`,
    orderByPreferenceDefault,
  );

  const descending = orderByPreference.value?.charAt(0) === '-';
  const orderBy = descending
    ? orderByPreference.value.slice(1)
    : orderByPreference.value || orderByPreferenceDefault;
  const orderByDirection = descending ? 'desc' : 'asc';
  const active = alias === orderBy;

  function handleClickSort() {
    setOrderByPreference({
      ...orderByPreference,
      value: orderByDirection === 'desc' ? alias : `-${alias}`,
    });
  }

  return (
    <TableCell
      align="center"
      className={`b no-wrap${
        subHeader ? ' b-left-off-white b-right-off-white primary-bg white-txt' : ''
      }`}
      sortDirection={active ? orderByDirection : false}
    >
      {!subHeader ? (
        <TableSortLabel active={active} direction={orderByDirection} onClick={handleClickSort}>
          {title}
        </TableSortLabel>
      ) : (
        title
      )}
    </TableCell>
  );
}
