import { useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useGetCompareReplaceOptionsQuery } from 'src/features/CompareReplaceDialog/CompareReplaceDialog.service';
import {
  ClipboardData,
  ClipboardSection,
} from 'src/features/JobForm/components/CopyCompareButton/type';
import {
  addOrUpdateSection,
  createCopiedSectionData,
  updatedJobValue,
} from 'src/features/JobForm/components/CopyCompareButton/utils';
import { WaveIcon } from 'src/features/WaveIcon';
import { JobValues } from 'src/pages/Job/Job.service';
import { Section, useJobContext } from 'src/pages/Job/JobContext';
import { useLocalStorage } from 'src/utilities/hooks';

const StyledButton = styled(Button)`
  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.filler.dark};
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  .MuiButtonGroup-grouped:not(:last-child) {
    border-right-color: ${({ theme }) => theme.palette.common.white};
    &.Mui-disabled {
      border-right-color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

type CopyCompareButtonProps = {
  onOpenCompareDialog: (sectionId: string) => void;
  section: Section;
};

export function CopyCompareButton({ onOpenCompareDialog, section }: CopyCompareButtonProps) {
  const { data: { values: options = {} } = {} } = useGetCompareReplaceOptionsQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );
  const [isClipboardOpen, setIsClipboardOpen] = useState(false);
  const [clipboardData, setClipboardData] = useLocalStorage<ClipboardData>('clipboard', []);
  const numberOfCopiedSections = clipboardData?.length;

  const shouldEnableAnySection = options?.['clip.opt.anyblock']?.value;
  const hasClipboardSection = clipboardData?.some(
    (clipboardSection) => clipboardSection.sectionId === section.code,
  );
  const isDisabled = numberOfCopiedSections === 0;
  const isCompareDisabled = isDisabled || (shouldEnableAnySection ? false : !hasClipboardSection);

  const { fields, values } = useJobContext();
  const { dirtyFields } = useFormState();
  const { getValues, setValue } = useFormContext();

  const jobData = updatedJobValue(dirtyFields, getValues, values as JobValues);

  function handleAddSection() {
    const copiedSection = createCopiedSectionData(fields, section, jobData);
    const updatedCopiedSections = addOrUpdateSection(
      clipboardData,
      copiedSection[0] as ClipboardSection,
    );

    setClipboardData(updatedCopiedSections);
  }

  function handleClearCopiedData() {
    section.fields
      ?.filter((field) => !fields?.[field.alias].isDisabled)
      .map((field) => {
        setValue(field.alias, fields?.[field.alias].type === 'boolean' ? false : '', {
          shouldDirty: true,
        });
      });
  }

  function handleClickCompare() {
    onOpenCompareDialog(section.code);
  }

  function handleCopySection() {
    const copiedSection = createCopiedSectionData(fields, section, jobData);

    setClipboardData(copiedSection as ClipboardData);
  }

  function handlePasteSection() {
    clipboardData.forEach((clipboard) => {
      if (clipboard.sectionId === section.code) {
        clipboard.fields.forEach((field) => {
          setValue(field.fieldId, field.value, { shouldDirty: true });
        });
      }
    });
  }

  function handleToggleClipboard() {
    setIsClipboardOpen(!isClipboardOpen);
  }

  return (
    <>
      {!isClipboardOpen ? (
        <Box>
          <IconButton onClick={handleToggleClipboard}>
            <WaveIcon code="job-form-clipboard" color="primary" />
          </IconButton>
        </Box>
      ) : (
        <StyledButtonGroup variant="contained">
          <StyledButton onClick={handleCopySection} size="small">
            <Trans i18nKey="lib.copy">Copy</Trans>
          </StyledButton>

          <StyledButton disabled={isDisabled} onClick={handleAddSection} size="small">
            <Trans i18nKey="lib.add">Add</Trans>
          </StyledButton>

          <StyledButton disabled={isDisabled} onClick={handlePasteSection} size="small">
            <Trans i18nKey="clip.paste">Paste</Trans>
          </StyledButton>

          <StyledButton disabled={isCompareDisabled} onClick={handleClickCompare} size="small">
            <Trans i18nKey="lib.compare">Compare</Trans>
          </StyledButton>

          <StyledButton onClick={handleClearCopiedData} size="small">
            <Trans i18nKey="clip.clear">Clear</Trans>
          </StyledButton>

          <StyledButton onClick={handleToggleClipboard} size="small">
            <WaveIcon code="close" />
          </StyledButton>
        </StyledButtonGroup>
      )}
    </>
  );
}
