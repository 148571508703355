import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { EmailList } from 'src/features/History/components/EmailList';
import { GetEmailListResponse } from 'src/features/History/History.service';
import { useRouteParams } from '../../../../utilities/hooks';
import { WaveIcon } from '../../../WaveIcon';

import type { HistoryItemId, HistoryItemType } from '../../History.types';

type HistoryItemProps = {
  expandedHistoryItemIds: HistoryItemId[];
  historyItem: HistoryItemType;
  index: number;
  onToggleExpand: (arg0: HistoryItemId) => void;
  hasEmailList: boolean;
  emailListData?: Array<GetEmailListResponse>;
};

export function HistoryItem({
  emailListData = [],
  expandedHistoryItemIds,
  hasEmailList,
  historyItem,
  index,
  onToggleExpand,
}: HistoryItemProps) {
  const { jobType } = useRouteParams();

  const { palette } = useTheme();
  const { t } = useTranslation();

  const iconColorMap = {
    'approval-amendment': 'error',
    'approval-approve': 'success',
    'approval-conditional': 'warning',
    'approval-user-added': 'success',
    'approval-user-deleted': 'error',
    'checklist-ok': 'success',
    'checklist-reject': 'error',
    comment: 'warning',
    email: 'warning',
    'flag-activate': 'success',
    'job-edit': 'warning',
    'phrase-edit': 'warning',
    'question-answered': 'warning',
  };

  function handleChangeAccordion() {
    onToggleExpand(historyItem.id);
  }

  return (
    <Box
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bgcolor={index % 2 !== 0 ? palette.oddRow.main : ''}
      className={`history ${historyItem.sliderValue?.split(' ').join('-')}`}
      component={Accordion}
      expanded={expandedHistoryItemIds.includes(historyItem.id)}
      onChange={handleChangeAccordion}
    >
      <AccordionSummary expandIcon={<WaveIcon code="job-history-item-expand-more" />}>
        <Grid2 alignItems="center" container flexGrow={1} margin={0} spacing={2}>
          <Grid2 alignItems="center" container xs={2}>
            {isNaN(parseInt(historyItem.code)) ? (
              <WaveIcon
                code={`job-history-${historyItem.code}`}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                color={iconColorMap[historyItem.code] || 'primary'}
                fontSize="small"
              />
            ) : (
              <>
                <StatusIndicator
                  jobType={jobType}
                  label={historyItem.from_status.toString()}
                  size="small"
                />

                <WaveIcon code="arrow-right-alt" fontSize="small" />

                <StatusIndicator
                  jobType={jobType}
                  label={historyItem.to_status.toString()}
                  size="small"
                />
              </>
            )}
          </Grid2>

          <Grid2 xs>
            <Typography variant="caption">
              {t('history_action_type_header', 'Action Type')}
            </Typography>

            <Typography variant="subtitle2">{historyItem.type.text}</Typography>
          </Grid2>

          <Grid2 xs>
            <Typography variant="caption">{t('lib.datetime')}</Typography>

            <Typography variant="subtitle2">{historyItem.datum}</Typography>
          </Grid2>

          <Grid2 xs>
            <Typography variant="caption">{t('lib.user')}</Typography>

            <Typography variant="subtitle2">{historyItem.user}</Typography>
          </Grid2>

          <Grid2 xs>
            <Typography variant="caption">{t('history_details_header')}</Typography>

            <Typography variant="subtitle2">{historyItem.snippet}</Typography>
          </Grid2>
        </Grid2>
      </AccordionSummary>

      <AccordionDetails>
        {Array.isArray(historyItem.message) ? (
          historyItem.message.map((msg, index) => (
            <Typography key={index} variant="body2">
              {`- ${t('his.job.changes.format', { 1: msg.field_name, 2: msg.old, 3: msg.new })}`}
            </Typography>
          ))
        ) : (
          <Typography variant="subtitle2">{historyItem.message}</Typography>
        )}

        {hasEmailList ? <EmailList emails={emailListData} /> : null}
      </AccordionDetails>
    </Box>
  );
}
