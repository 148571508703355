import type { MouseEvent } from 'react';

import { IconButton, TableCell } from '@mui/material';

import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';
import { WaveIcon } from 'src/features/WaveIcon';

type ButtonCellProps = {
  code: string;
  colSpan?: number;
  deadlineStyle?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  waveIconClassName?: string;
} & Pick<StatusCellProps, 'paddingY'>;

export function ButtonCell({
  code,
  colSpan = 1,
  onClick,
  paddingY,
  waveIconClassName,
}: ButtonCellProps) {
  return (
    <TableCell align="left" className={'no-wrap w-40'} colSpan={colSpan} sx={{ paddingY }}>
      <IconButton onClick={onClick}>
        <WaveIcon className={waveIconClassName} code={code} fontSize="medium" />
      </IconButton>
    </TableCell>
  );
}
