import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';
import { useIsSubJobs } from 'src/features/JobsTable/utilities/hooks';
import { useGetWorkflowQuery } from 'src/features/Workflow/Workflow.service';
import { BasicAge } from 'src/utilities/hooks/useRouteParams';

type UseStatusCellProps = Pick<StatusCellProps, 'rowJobType' | 'value'>;

export function useStatusCell({ rowJobType, value }: UseStatusCellProps) {
  const { age, isSubJobs, jobType } = useIsSubJobs({ ...(value !== 200 && { forcedAge: 'job' }) });

  const shouldWorkflowHaveArchiveStatus = isSubJobs || jobType === 'search';
  const isAgeBasic = age === 'arc' || age === 'job';
  const ageForWorkflow = shouldWorkflowHaveArchiveStatus ? 'arc' : (age as BasicAge);

  const { data: workflow, isFetching: isWorkflowFetching } = useGetWorkflowQuery(
    {
      age: ageForWorkflow,
      jobType: rowJobType,
    },
    { skip: !isAgeBasic },
  );

  return { isWorkflowFetching, workflow };
}
