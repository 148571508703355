import dayjs from 'dayjs';

import { TableCell, Typography } from '@mui/material';

import { getDateFormat } from 'src/components/RHF/DateField/utils';
import { StatusCellProps } from 'src/features/JobsTable/components/StatusCell/StatusCell';

type DateCellProps = { value: string; languageCode: string } & Pick<StatusCellProps, 'paddingY'>;

export function DateCell({ languageCode, paddingY, value }: DateCellProps) {
  const dayjsDate = dayjs(value);
  const formattedDate = getDateFormat(dayjsDate, languageCode);

  return (
    <TableCell sx={{ paddingY }}>
      <Typography padding={1} variant="body2">
        {formattedDate}
      </Typography>
    </TableCell>
  );
}
