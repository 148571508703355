import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';
import {
  calculateHistoryDifference,
  calculateHistorySliderValue,
  mutateHistorySliderValues,
} from './utilities/helperFunctions';

import { Filter, HistoryItemType, SliderValue } from './History.types';

type BaseParams = {
  age?: ExpandedAges;
  jobid?: string | number;
  src?: string;
};

type AddCommentRequest = BaseParams & {
  message: string;
  subject: string;
};

type GetEmailListRequest = BaseParams & {
  id?: number;
};

type GetHistoryResponse = {
  filters: Filter[];
  history: HistoryItemType[];
  sliderValues: SliderValue[];
};

export type GetEmailListResponse = {
  apl_states: number;
  backup_usr_id: number;
  from_mail: string;
  from_name: string;
  his_id: string;
  id: number;
  jobid: number;
  mail_active: boolean;
  mail_body: string;
  mail_date: string;
  mail_entry: string;
  mail_errmsg: string;
  mail_errno: number;
  mail_header: string;
  mail_state: number;
  mail_status: number;
  mail_subject: string;
  mail_type: number;
  mand: number;
  receiver_id: number;
  response: number;
  sender_id: number;
  src: string;
  to_mail: string;
  to_name: string;
};

export const historyApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addComment: build.mutation<void, AddCommentRequest>({
      invalidatesTags: ['History'],
      query(data) {
        return {
          data,
          method: 'POST',
          url: '/jobs/history/comment',
        };
      },
    }),
    getEmailList: build.query<Array<GetEmailListResponse>, GetEmailListRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/system-mails',
        };
      },
    }),
    getHistory: build.query<GetHistoryResponse, BaseParams>({
      providesTags: ['History'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/jobs/history/get',
        };
      },
      transformResponse(response: HistoryItemType[]) {
        const historyFilters: Filter[] = [];
        const sliderValues: string[] = [];
        const difference = calculateHistoryDifference(response);

        response.forEach((historyItem) => {
          // Check historyFilters to see if historyItem.type.code already exists
          const filterIndex = historyFilters.findIndex(
            (filter) => filter.code === historyItem.type.code,
          );

          // If historyFilters doesn't contain the type, add it
          if (filterIndex === -1) {
            historyFilters.push(historyItem.type);
          }

          const sliderValue = calculateHistorySliderValue({
            difference,
            historyItemDate: historyItem.datum,
          });

          sliderValues.push(sliderValue);
          historyItem.sliderValue = sliderValue;
        });

        historyFilters.sort((prevItem, nextItem) =>
          prevItem.text > nextItem.text ? 1 : nextItem.text > prevItem.text ? -1 : 0,
        );

        return {
          filters: historyFilters,
          history: response,
          sliderValues: mutateHistorySliderValues(sliderValues),
        };
      },
    }),
  }),
  reducerPath: 'historyApi',
  tagTypes: ['History'],
});

export const { useAddCommentMutation, useGetEmailListQuery, useGetHistoryQuery } = historyApi;
